import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgForOf} from '@angular/common';

@Component({
  selector: 'sf-why-use-us-section',
  templateUrl: './why-use-us-section.component.html',
  styleUrls: ['./why-use-us-section.component.scss'],
  imports: [
    NgForOf
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhyUseUsSectionComponent {
  public readonly whyUseUsItems = [
    {icon: 'sf-icon-premium', text: `<strong>Freemium</strong>`},
    {icon: 'sf-icon-freemium', text: `Professional <strong>Templates</strong>`},
    {icon: 'sf-icon-pricing', text: `<strong>NO Hidden</strong> Fees`},
    {icon: 'sf-icon-ATS-friendly', text: `<strong>ATS-</strong>Friendly`},
    {icon: 'sf-icon-live-content', text: `<strong>Matching</strong> Cover Letter`},
    {icon: 'sf-icon-editing', text: `<strong>Real Time</strong> Editing`},
  ];
}
