import {filter} from "rxjs/operators";
import {CountryPrice} from "@app/shared/constants";
import {NzModalService} from "ng-zorro-antd/modal";
import {PaymentComponent} from "@app/shared/ui-kits";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NavigationStart, Router} from "@angular/router";
import {NzSkeletonComponent} from "ng-zorro-antd/skeleton";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {AnalyticsService, AppStateService, AuthService} from "@app/shared/services";

@Component({
  selector: 'sf-premium-plans',
  standalone: true,
  imports: [
    NzSkeletonComponent
  ],
  templateUrl: './premium-plans.component.html',
  styleUrl: './premium-plans.component.scss'
})
export class PremiumPlansComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly nzModalService = inject(NzModalService);
  private readonly nzDrawerService = inject(NzDrawerService);
  private readonly analyticsService = inject(AnalyticsService);
  protected readonly appStateService = inject(AppStateService);

  @Input() public parentClassname = '';


  protected countryPlan = this.appStateService.getCurrentCountyPlan()!;

  constructor() {
  }

  ngOnInit() {
    this.appStateService.userLocationInit$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.countryPlan = this.appStateService.getCurrentCountyPlan()!;
      });

    this.analyticsService.track("Pricing page viewed");
  }

  protected onUpgradePlan(billing: CountryPrice) {
    if (!this.authService.isAuthenticated) {
      this.router.navigate(['auth'], {queryParams: {returnUrl: 'pricing'}});
      this.analyticsService.track("Upgrade Click Not Logged in");
      return;
    }

    if (!this.appStateService.user?.emailVerified) {
      this.nzModalService.info({
        nzTitle: 'First you need to verify your email.',
        // nzContent: '<p>some messages...some messages...</p><p>some messages...some messages...</p>',
      });
      this.analyticsService.track("Upgrade Click Not Verified email", {user: this.appStateService.user});
      return;
    }

    const drawerRef = this.nzDrawerService.create({
      nzClosable: false,
      nzWidth: window.innerWidth > 768 ? 760 : 378,
      nzBodyStyle: {
        padding: 0
      },
      nzContent: PaymentComponent,
      nzWrapClassName: 'nz-payment-sidebar',
      nzContentParams: {
        billing
      }
    });

    drawerRef.afterOpen
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        drawerRef.getContentComponent()?.close$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            drawerRef.close();
          });
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        drawerRef.close();
      });

    this.analyticsService.track("Upgrade Form Opened", billing);
  }

  /*protected onSelectOneMonthFree() {
    // this.openConfirmModal();
  }*/

  /*protected openConfirmModal() {
    this.nzModalService.confirm({
      nzTitle: 'Are you sure activate one month free?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzIconType: '',
      nzFooter: null,
      nzOnOk: () => this.getReferralBonus(),
      nzCancelText: 'Cancel'
    });
  }*/

  /*private getReferralBonus() {
    this.paymentService.getReferralBonus()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('referralBonus', res);
        this.nzMessageService.success('Congratulations you are using one month premium.');
      });
  }*/

}
