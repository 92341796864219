import {HttpClient} from "@angular/common/http";
import {Feedback} from "@app/shared/interfaces";
import {inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private readonly httpClient = inject(HttpClient);

  public sendFeedback(body: Feedback) {
    return this.httpClient.post<{ success: boolean }>('feedback', this.createFormData(body));
  }

  private createFormData(body: Feedback) {
    const formData = new FormData();
    body.files.forEach((file) => formData.append('files', file));
    formData.append('stars', body.stars);
    formData.append('feedback', body.feedback);
    formData.append('email', body.email);
    return formData;
  }

}
