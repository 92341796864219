import {NgForOf} from '@angular/common';
import {RouterLink} from "@angular/router";
import {Component, inject} from '@angular/core';
import {AppStateService} from "@app/shared/services";
import {
  FAQSectionComponent,
  MoreThenSectionComponent,
  ResumeExamplesSectionComponent,
  WhyUseUsSectionComponent
} from '../sections';

@Component({
  selector: 'sf-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    MoreThenSectionComponent,
    ResumeExamplesSectionComponent,
    WhyUseUsSectionComponent,
    FAQSectionComponent,
    RouterLink,
  ]
})
export class HomePageComponent {
  protected appStateService = inject(AppStateService);

  public readonly resumeBuildSteps = [
    {
      title: '01 Choose a template',
      desc: 'Choose from a wide variety of professional templates to get started easily.',
      icon: 'sf-icon-pick-template'
    },
    {
      title: '02 Customize the look and feel',
      desc: 'Adjust the template’s layout to match your visual preferences.',
      icon: 'sf-icon-layout-customize'
    },
    {
      title: '03 Add your info',
      desc: 'Fill in your details to make the template your own.',
      icon: 'sf-icon-fill-blanks'
    },
    {
      title: '04 Download!',
      desc: 'That’s it. Now, you can download your CV and start sending it to your potential employers.',
      icon: 'sf-icon-download'
    },
  ];

}
