import {FAQGroup} from "@app/shared/interfaces";

export const FAQ_OBJ: FAQGroup = {
  homePage: {
    title: '<strong>Frequently</strong> asked questions about selfcv',
    items: [
      {
        title: 'Is this resume builder completely free?',
        info: 'Selfcv has a free plan which provides you the key features for creating awesome resumes,' +
          ' and you can stay free forever. Nevertheless, as soon as you feel the need to go to the next' +
          ' level and produce even more professional documents, there’s always an option to upgrade to a' +
          ' Premium plan.'
      },
      {
        title: 'Are the templates compatible with Application Tracking Systems (ATS)?',
        info: 'Our resume templates are designed to seamlessly integrate with the most widely used applicant tracking systems, minimizing the risk of automatic rejection by any ATS.'
      },
      {
        title: 'Is the payment for Selfcv builder app a one-time fee or subscription?',
        info: 'When you upgrade to our premium plan, you make a one-time payment, ensuring that you won\'t be automatically charged at the end of the period.'
      },
      {
        title: 'What is the primary format for downloading from this platform?',
        info: 'The primary format for downloading resumes is PDF. However, you also have the ability to export them as an image.'
      },
      {
        title: 'Can I share my resume with others who don’t have an account on the platform?',
        info: 'Yes, you can generate a shareable link for your resume, allowing anyone to view it without needing an account. The link will serve as a snapshot of your cover letter at that time.'
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'How can I remove Selfcv branding (watermark) from my resume?',
        info: 'You can only remove Selfcv branding (watermark) if you are a premium user.\n' +
          'You can either hover over the brand in the footer and click \'Remove\', or you can use the switch in the settings sidebar.\n'
      },
      {
        title: 'What happens when my premium plan expires?',
        info: 'After your premium plan expires, you\'ll still have access to your account and any resumes you\'ve created. However, you\'ll revert to our basic plan, which may have limited features and customization options. While you can still modify premium templates created during your premium membership, you will not be able to download them.'
      },
    ],
  },
  resumeTemplates: {
    title: '<strong>Frequently</strong> asked questions about Resume templates',
    items: [
      {
        title: 'Are selfcv templates free?',
        info: 'Yes, all selfcv templates are free, but there’s also an option to upgrade to get access to premium features.'
      },
      {
        title: 'Are the templates ATS-compatible?',
        info: 'Yes, all the templates are ATS-compatible, so any template you build using selfcv will be made ATS-compliant automatically, and there’s no need to take any additional actions'
      },
      {
        title: 'Who are the templates made by?',
        info: 'The templates are made by our professional designers based on industry best practices and with Applicant Tracking Systems in mind. Our goal is to create templates that will make your job application stand out, maximizing your chances of getting hired.'
      },
      {
        title: 'How many pages do the templates have?',
        info: 'By default, our templates are one-page documents, but you can always expand and adjust the length based on your needs.'
      },
    ]
  },
  cvTemplates: {
    title: '<strong>Frequently</strong> asked questions about CV templates',
    items: [
      {
        title: 'Is this resume builder completely free?',
        info: 'Selfcv has a free plan which provides you the key features for creating awesome resumes,' +
          ' and you can stay free forever. Nevertheless, as soon as you feel the need to go to the next' +
          ' level and produce even more professional documents, there’s always an option to upgrade to a' +
          ' Premium plan.'
      },
      {
        title: 'Are the templates compatible with Application Tracking Systems (ATS)?',
        info: 'Our resume templates are designed to seamlessly integrate with the most widely used applicant tracking systems, minimizing the risk of automatic rejection by any ATS.'
      },
      {
        title: 'Is the payment for Selfcv builder app a one-time fee or subscription?',
        info: 'When you upgrade to our premium plan, you make a one-time payment, ensuring that you won\'t be automatically charged at the end of the period.'
      },
      {
        title: 'What is the primary format for downloading from this platform?',
        info: 'The primary format for downloading resumes is PDF. However, you also have the ability to export them as an image.'
      },
      {
        title: 'Can I share my resume with others who don’t have an account on the platform?',
        info: 'Yes, you can generate a shareable link for your resume, allowing anyone to view it without needing an account. The link will serve as a snapshot of your cover letter at that time.'
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'How can I remove Selfcv branding (watermark) from my resume?',
        info: 'You can only remove Selfcv branding (watermark) if you are a premium user.\n' +
          'You can either hover over the brand in the footer and click \'Remove\', or you can use the switch in the settings sidebar.\n'
      },
      {
        title: 'What happens when my premium plan expires?',
        info: 'After your premium plan expires, you\'ll still have access to your account and any resumes you\'ve created. However, you\'ll revert to our basic plan, which may have limited features and customization options. While you can still modify premium templates created during your premium membership, you will not be able to download them.'
      },
    ],
  },
  coverLetter: {
    title: '<strong>Frequently</strong> asked questions about Cover letter templates',
    items: [
      {
        title: 'How to find the right cover letter template?',
        info: 'How to find the right cover letter template?\n' +
          'Since a cover letter is supposed to supplement your resume for the job application, we recommend selecting a cover letter template that matches your selfcv resume template.'
      },
      {
        title: 'Is the payment for Selfcv builder app a one-time fee or subscription?',
        info: 'When you upgrade to our premium plan, you make a one-time payment, ensuring that you won\'t be automatically charged at the end of the period.'
      },
      {
        title: 'What is the primary format for downloading from this platform?',
        info: 'The primary format for downloading cover letter is PDF. However, you also have the ability to export them as an image.'
      },
      {
        title: 'Can I share my Cover Letter with others who don’t have an account on the platform?',
        info: 'Yes, you can generate a shareable link for your Cover Letter, allowing anyone to view it without needing an account. The link will serve as a snapshot of your cover letter at that time.'
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'What happens when my premium plan expires?',
        info: 'After your premium plan expires, you\'ll still have access to your account and any Cover Letter you\'ve created. However, you\'ll revert to our basic plan, which may have limited features and customization options. While you can still modify premium templates created during your premium membership, you will not be able to download them.'
      },
    ]
  },
  accountSubscription: {
    title: '<strong>Frequently</strong> asked questions about selfcv',
    items: [
      {
        title: 'Is this resume builder completely free?',
        info: 'Selfcv has a free plan which provides you the key features for creating awesome resumes,' +
          ' and you can stay free forever. Nevertheless, as soon as you feel the need to go to the next' +
          ' level and produce even more professional documents, there’s always an option to upgrade to a' +
          ' Premium plan.'
      },
      {
        title: 'Are the templates compatible with Application Tracking Systems (ATS)?',
        info: 'Our resume templates are designed to seamlessly integrate with the most widely used applicant tracking systems, minimizing the risk of automatic rejection by any ATS.'
      },
      {
        title: 'Is the payment for Selfcv builder app a one-time fee or subscription?',
        info: 'When you upgrade to our premium plan, you make a one-time payment, ensuring that you won\'t be automatically charged at the end of the period.'
      },
      {
        title: 'What is the primary format for downloading from this platform?',
        info: 'The primary format for downloading resumes is PDF. However, you also have the ability to export them as an image.'
      },
      {
        title: 'Can I share my resume with others who don’t have an account on the platform?',
        info: 'Yes, you can generate a shareable link for your resume, allowing anyone to view it without needing an account. The link will serve as a snapshot of your cover letter at that time.'
      },
      // {
      //   title: 'How does the Referral Program work?',
      //   info: 'Send your friend a special coupon code for a 25% discount when they upgrade to our premium plan. Your unique coupon code can be found on your account page. For the successful referral, you\'ll receive a complimentary month of our premium plan. You can activate your free month whenever it suits you best.'
      // },
      {
        title: 'How can I remove Selfcv branding (watermark) from my resume?',
        info: 'You can only remove Selfcv branding (watermark) if you are a premium user.\n' +
          'You can either hover over the brand in the footer and click \'Remove\', or you can use the switch in the settings sidebar.\n'
      },
      {
        title: 'What happens when my premium plan expires?',
        info: 'After your premium plan expires, you\'ll still have access to your account and any resumes you\'ve created. However, you\'ll revert to our basic plan, which may have limited features and customization options. While you can still modify premium templates created during your premium membership, you will not be able to download them.'
      },
    ],
  },
};
