<sf-payment-banner/>

<div class="user-page-main-container">
  <div class="top-part">
    <div class="cover"></div>

    <div class="user-image">
      <ng-container *ngIf="!appStateService.user?.image; else UserImageRef">
        <img ngSrc="assets/img/user-upload.svg" width="122" height="122" alt="selfcv user upload">
        <input type="file" (input)="onSelectImage($event)">
      </ng-container>
    </div>
  </div>

  <div class="form-part">
    <div class="form-top">
      <h4>Personal Information</h4>
      <p>This information will be displayed in your resume.</p>
    </div>

    <form [formGroup]="userFrom" #form="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
      <div class="input-part">
        <div class="sf-input-wr">
          <input
            class="sf-input small"
            type="text"
            placeholder="Full Name"
            formControlName="fullName"
            sfTrim
            [class.error]="form.submitted && userFrom.controls['fullName'].errors">

          <div class="sf-err-wr" *ngIf="form.submitted && userFrom.controls['fullName'].errors as error">
            <div class="sf-input-err" *ngIf="error['required']">
              <i class="ph ph-warning"></i>
              Full name is required
            </div>

            <div class="sf-input-err" *ngIf="error['maxlength'] as length">
              <i class="ph ph-warning"></i>
              Full name max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
            </div>
          </div>
        </div>

        <div class="sf-input-wr">
          <input
            class="sf-input small readonly"
            type="text"
            placeholder="Email"
            [value]="appStateService.user?.email || ''">
        </div>

        <div class="sf-input-wr">
          <nz-date-picker
            [nzShowToday]="false"
            [nzDefaultPickerValue]="initialBirthDayValue"
            [nzDisabledDate]="disabledDate"
            class="sf-date small" formControlName="birthDate"
            [nzPlaceHolder]="'Birth Date'"
          ></nz-date-picker>
        </div>

        <div class="sf-phone-input small">
          <nz-select
            [nzSize]="'large'"
            nzShowSearch
            nzAllowClear
            [nzDropdownMatchSelectWidth]="false"
            [nzPlaceHolder]="'Code'"
            formControlName="phoneCode">
            <nz-option
              *ngFor="let country of appStateService.countries"
              nzCustomContent
              [nzValue]="country.dialCode"
              [nzLabel]="country.dialCode">
              {{ country.name + ' (' + country.dialCode + ')' }}
            </nz-option>
          </nz-select>
          <input class="sf-input" type="number" placeholder="Phone Number" formControlName="phoneNumber" sfTrim/>
        </div>

        <div class="sf-input-wr">
          <input
            class="sf-input small"
            placeholder="Country"
            formControlName="country"
            (input)="onCountryChange($any($event.target).value)"
            [nzAutocomplete]="auto"
            [class.error]="form.submitted && userFrom.controls['country'].errors"/>
          <nz-autocomplete #auto>
            <nz-auto-option
              *ngFor="let country of filteredCountries"
              [nzValue]="country.name"
              [nzLabel]="country.name">
              {{ country.name }}
            </nz-auto-option>
          </nz-autocomplete>

          <div class="sf-err-wr" *ngIf="form.submitted && userFrom.controls['country'].errors as error">
            <div class="sf-input-err" *ngIf="error['maxlength'] as length">
              <i class="ph ph-warning"></i>
              Country max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
            </div>
          </div>
        </div>

        <div class="sf-input-wr">
          <input
            class="sf-input small"
            type="text"
            placeholder="City"
            formControlName="city"
            sfTrim
            [class.error]="form.submitted && userFrom.controls['city'].errors">

          <div class="sf-err-wr" *ngIf="form.submitted && userFrom.controls['city'].errors as error">
            <div class="sf-input-err" *ngIf="error['maxlength'] as length">
              <i class="ph ph-warning"></i>
              City max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
            </div>
          </div>
        </div>
      </div>

      <sf-coupon-banner/>

      <div class="checkbox-part">
        <h3>Notifications</h3>
        <div class="checkboxes">
          <div class="sf-checkbox readonly opacity-60">
            <input type="checkbox" id="payment" checked>
            <label for="payment">Payment Success</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="news" formControlName="subscribeToNotifications">
            <label for="news">News</label>
          </div>

          <div class="sf-checkbox readonly opacity-60">
            <input type="checkbox" id="pass" checked>
            <label for="pass">Password Change</label>
          </div>
        </div>
      </div>

      <div class="button-part">
        <div class="btn-content">
          <span class="delete-ac" (click)="onDeleteUser()">Delete Account</span>
          <div class="right-side">
            <button class="sf-btn-primary">Save Changes</button>
          </div>
        </div>
      </div>

    </form>

  </div>

</div>


<ng-template #UserImageRef>
  <div
    class="user-uploaded-img-wrapper"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="EditImg">
    <img
      [src]="appStateService.user!.image || ''"
      class="user-uploaded-img"
      width="132"
      height="132"
      alt="selfcv User">
    <input type="file" class="d-none" (input)="onSelectImage($event)" #InputRef>
  </div>
</ng-template>


<nz-dropdown-menu #EditImg="nzDropdownMenu">
  <ul nz-menu class="p-6 b-radius-4" style="transform: translate(110px, -16px);">
    <li nz-menu-item (click)="InputRef.nativeElement.click()">
      <div class="d-flex ai-c">
        <i class="ph ph-pencil-simple mr-6"></i>
        Edit
      </div>
    </li>

    <li nz-menu-item>
      <div class="d-flex ai-c" (click)="deleteUserImage()">
        <i class="ph ph-trash-simple mr-6"></i>
        Delete
      </div>
    </li>
  </ul>
</nz-dropdown-menu>
