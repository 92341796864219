<div class="reset-main-container">
  <div class="auth-container forgot-password">
    <div class="left-side">
      <div class="content-part">
        <div class="logo-wrapper">
          <a routerLink="/">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <ng-container *ngIf="!checkEmailBlockState; else EmailSentRef">
          <h1>Forgot password?</h1>
          <p class="sub-title">No worries, we’ll send you reset instructions</p>

          <div class="form-wrapper">
            <form [formGroup]="forgotPasswordForm" #form="ngForm" (ngSubmit)="onSubmit()">

              <div class="sf-input-wr mb-20">
                <input
                  class="sf-input"
                  type="text"
                  placeholder="Email"
                  formControlName="email"
                  sfTrim
                  [class.error]="form.submitted && forgotPasswordForm.controls['email'].errors">

                <div class="sf-err-wr" *ngIf="form.submitted && forgotPasswordForm.controls['email'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    Email is required
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    Email max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['email']">
                    <i class="ph ph-warning"></i>
                    Incorrect email address
                  </div>
                </div>
              </div>

              <div class="btn-part">
                <button class="sf-btn-primary" [class.loading]="loaderState">
                  Reset Password
                </button>
              </div>

              <div class="have-not-account">
                <span> Don’t have an account? </span>
                <a class="sf-link" routerLink="/auth/sign-up"> Sign Up </a>
              </div>

            </form>
          </div>
        </ng-container>

        <div class="sf-info-banner error ph ph-x-circle mt-30" *ngIf="errorMessage">
          <div>
            <div>Error</div>
            <p>{{ errorMessage }}</p></div>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #EmailSentRef>
  <div class="email-send-banner">
    <h2>Check your email</h2>
    <p>We sent a password reset link to your email address.</p>
    <div class="have-not-account">
      <span> Didn’t receive the email? </span>
      <a class="sf-link" (click)="onResent()" [class.resent]="!resetState"> Click to resend. </a>
    </div>
  </div>
</ng-template>
