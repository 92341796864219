import {RouterLink} from "@angular/router";
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'sf-more-then-section',
  templateUrl: './more-then-section.component.html',
  styleUrls: ['./more-then-section.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink
  ]
})
export class MoreThenSectionComponent {

}
