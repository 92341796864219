<div class="security-main-container">
  <div class="top-part">
    <h1>Change password</h1>

    @if (appStateService.user?.emailVerified === false) {
      <div class="sf-info-banner info ph ph-info max-w-400">
        <div>
          <p class="fs-15">To change password <strong>email verification</strong> is required!</p>
        </div>
      </div>
    } @else if (appStateService.user?.passwordLess === true) {
      <div class="sf-info-banner info ph ph-info max-w-400">
        <div>
          <p class="fs-15">You sign in with <strong>Google</strong> and do not have password to reset.</p>
        </div>
      </div>
    } @else {
      <div class="bottom-part">
        <div class="pass-to-change" *ngIf="!passwordFormState; else FormRef">
          <div class="left-side">
            <div>Password</div>
            <p *ngIf="appStateService.user?.passwordChangedAt">
              Last change <span>{{ appStateService.user!.passwordChangedAt | date }}</span>
            </p>
          </div>

          <button class="sf-btn-primary w-210" (click)="passwordFormState = true">Change Password</button>
        </div>
      </div>
    }
  </div>
</div>

<ng-template #FormRef>
  <div class="form-wrapper" *ngIf="!socialLoggedIn; else SocialLoggedInRef">
    <form [formGroup]="resetPassForm" #form="ngForm" (ngSubmit)="onSubmit()">

      <div class="sf-input-wr mb-20">
        <input
          type="password"
          class="sf-input"
          autocomplete
          placeholder="Current Password"
          formControlName="currentPassword"
          sfTrim
          [class.error]="form.submitted && resetPassForm.controls['currentPassword'].errors">

        <div class="sf-err-wr" *ngIf="form.submitted && resetPassForm.controls['currentPassword'].errors as error">
          <div class="sf-input-err" *ngIf="error['required']">
            <i class="ph ph-warning"></i>
            Password is required
          </div>

          <div class="sf-input-err" *ngIf="error['maxlength'] as length">
            <i class="ph ph-warning"></i>
            Password max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
          </div>

          <div class="sf-input-err" *ngIf="error['minlength'] as length">
            <i class="ph ph-warning"></i>
            Required password length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
          </div>
        </div>
      </div>

      <div class="sf-input-wr mb-20">
        <input
          type="password"
          class="sf-input"
          autocomplete
          placeholder="New Password"
          formControlName="newPassword"
          sfTrim
          [class.error]="form.submitted && resetPassForm.controls['newPassword'].errors">

        <div class="sf-err-wr" *ngIf="form.submitted && resetPassForm.controls['newPassword'].errors as error">
          <div class="sf-input-err" *ngIf="error['required']">
            <i class="ph ph-warning"></i>
            Password is required
          </div>

          <div class="sf-input-err" *ngIf="error['maxlength'] as length">
            <i class="ph ph-warning"></i>
            Password max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
          </div>

          <div class="sf-input-err" *ngIf="error['minlength'] as length">
            <i class="ph ph-warning"></i>
            Required password length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
          </div>
        </div>
      </div>

      <div class="sf-input-wr mb-20">
        <input
          type="password"
          class="sf-input"
          autocomplete
          placeholder="Confirm new password"
          formControlName="rp_password"
          sfTrim
          [class.error]="(form.submitted && resetPassForm.controls['rp_password'].errors) ||
                         (form.submitted &&
                          form.errors?.['mismatch'] &&
                          !resetPassForm.controls['newPassword']['errors'] &&
                          !resetPassForm.controls['rp_password']['errors'])">

        <div class="sf-err-wr" *ngIf="form.submitted && resetPassForm.controls['rp_password'].errors as error">
          <div class="sf-input-err" *ngIf="error['required']">
            <i class="ph ph-warning"></i>
            Password is required
          </div>

          <div class="sf-input-err" *ngIf="error['maxlength'] as length">
            <i class="ph ph-warning"></i>
            Password max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
          </div>

          <div class="sf-input-err" *ngIf="error['minlength'] as length">
            <i class="ph ph-warning"></i>
            Required password length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
          </div>
        </div>

        <div
          class="sf-err-wr"
          *ngIf="form.submitted &&
                 form.errors?.['mismatch'] &&
                 !resetPassForm.controls['newPassword']['errors'] &&
                 !resetPassForm.controls['rp_password']['errors']">
          <div class="sf-input-err">
            <i class="ph ph-warning"></i>
            Passwords don’t match
          </div>
        </div>
      </div>

      <div class="btn-part">
        <button class="sf-btn-tertiary mr-12" type="button" (click)="onCancel()">Cancel</button>
        <button class="sf-btn-primary" [class.loading]="loaderState">Update</button>
      </div>

    </form>
  </div>
</ng-template>

<ng-template #SocialLoggedInRef>
  <div class="sf-info-banner info ph ph-info mb-30">
    <div>
      <div>Password change not allowed</div>
      <p>You have logged in using your social media account</p>
    </div>
  </div>

  <div class="social-logged-in">
    <p>If you want to set a password to your account, follow these steps</p>

    <ul>
      <li class="sf-link" (click)="onSocialReset()">Go to the 'forgot password' page</li>
      <li>Instructions for resetting your password will be sent to your email.</li>
    </ul>
  </div>
</ng-template>

