import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {httpContextDefaults} from "@app/shared/constants";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable()
export class ErrorLogInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = req.context.get(httpContextDefaults);

    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (params.errorLog) {
            console.error(err);
          }
          return throwError(err);
        })
      );
  }

}
