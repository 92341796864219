import {BillingPlan} from "@app/shared/interfaces";

// ARS - Argentina (Argentine Peso)             $4.99
// AUD - Australia (Australian Dollar)          same 9.99
// BRL - Brazil (Brazilian Real)                $4.99
// CAD - Canada (Canadian Dollar)               same
// CHF - Switzerland (Swiss Franc)              same
// CNY - China (Chinese Yuan)                   same
// COP - Colombia (Colombian Peso)              $3.99
// CZK - Czech Republic (Czech Koruna)          same
// DKK - Denmark (Danish Krone)                 same
// EUR - Euro zone countries (Euro)             same
// GBP - United Kingdom (Pound Sterling)        same
// HKD - Hong Kong (Hong Kong Dollar)           same
// HUF - Hungary (Hungarian Forint)             same
// ILS - Israel (Israeli Shekels)               same
// INR - India (Indian Rupee)                   $2.99
// JPY - Japan (Japanese Yen)                   same
// KRW - South Korea (South Korean Won)         $2.99
// MXN - Mexico (Mexican Pesos)                 $2.99
// NOK - Norway (Norwegian Krone)               same
// NZD - New Zealand (New Zealand Dollar)       $4.99
// PLN - Poland (Polish Zloty)                  same
// RUB - Russia (Ruble)                         $4.99
// SEK - Sweden (Swedish Krona)                 same
// SGD - Singapore (Singapore Dollar)           same
// THB - Thailand (Thai Baht)                   $4.99
// TRY - Turkey (Turkish Lira)                  same
// TWD - Taiwan (New Taiwan Dollar)             $4.99
// UAH - Ukraine (Ukraine Hryvnia)              $4.99
// ZAR - South Africa (South African Rand)      $2.99

export interface CountryPrices {
  country: string;
  currency: string;
  currencyCode: string;
  prices: CountryPrice[];
}

export interface CountryPrice {
  id: 'plan_1' | 'plan_2' | 'plan_3';
  periodDays: string;
  monthlyPrice: string;
  totalPrice: string;
  periodMonths: number;
  name: string;
  description: string;
  popular?: boolean;
  currency: string;
  currencyCode: string;
  savePercentage: string;
}

export const ALL_COUNTRY_PRICES: CountryPrices[] = [
  {
    country: 'US',
    currency: 'USD',
    currencyCode: '$',
    prices: [
      {
        id: 'plan_1',
        periodDays: '30',
        monthlyPrice: '5.99',
        totalPrice: '5.99',
        periodMonths: 1,
        name: 'Monthly',
        description: '1 Month',
        currency: 'USD',
        currencyCode: '$',
        savePercentage: ''
      },
      {
        id: "plan_2",
        periodDays: '90',
        monthlyPrice: '3.99',
        totalPrice: '11.99',
        periodMonths: 3,
        popular: true,
        name: 'Quarterly',
        description: '3 Months',
        currency: 'USD',
        currencyCode: '$',
        savePercentage: '33.33%'
      },
      {
        id: "plan_3",
        periodDays: '366',
        monthlyPrice: '3.24',
        totalPrice: '38.99',
        periodMonths: 12,
        name: 'Annual',
        description: '12 Months',
        currency: 'USD',
        currencyCode: '$',
        savePercentage: '46%'
      },
    ]
  },
  {
    country: 'AM',
    currency: 'AMD',
    currencyCode: '֏',
    prices: [
      {
        id: 'plan_1',
        periodDays: '30',
        monthlyPrice: '1499.99',
        totalPrice: '1499.99',
        periodMonths: 1,
        name: 'Monthly',
        description: '1 Month',
        currency: 'AMD',
        currencyCode: '֏',
        savePercentage: ''
      },
      {
        id: "plan_2",
        periodDays: '90',
        monthlyPrice: '999.99',
        totalPrice: '2999.99',
        periodMonths: 3,
        popular: true,
        name: 'Quarterly',
        description: '3 Months',
        currency: 'AMD',
        currencyCode: '֏',
        savePercentage: '33.33%'
      },
      {
        id: "plan_3",
        periodDays: '366',
        monthlyPrice: '809.99',
        totalPrice: '9719.99',
        periodMonths: 12,
        name: 'Annual',
        description: '12 Months',
        currency: 'AMD',
        currencyCode: '֏',
        savePercentage: '46%'
      },
    ]
  },
  {
    country: 'EUR',
    currency: 'EUR',
    currencyCode: '€',
    prices: [
      {
        id: 'plan_1',
        periodDays: '30',
        monthlyPrice: '5.99',
        totalPrice: '5.99',
        periodMonths: 1,
        name: 'Monthly',
        description: '1 Month',
        currency: 'EUR',
        currencyCode: '€',
        savePercentage: ''
      },
      {
        id: "plan_2",
        periodDays: '90',
        monthlyPrice: '3.99',
        totalPrice: '11.99',
        periodMonths: 3,
        popular: true,
        name: 'Quarterly',
        description: '3 Months',
        currency: 'EUR',
        currencyCode: '€',
        savePercentage: '33.33%'
      },
      {
        id: "plan_3",
        periodDays: '366',
        monthlyPrice: '3.24',
        totalPrice: '38.99',
        periodMonths: 12,
        name: 'Annual',
        description: '12 Months',
        currency: 'EUR',
        currencyCode: '€',
        savePercentage: '46%'
      },
    ]
  },
];

export const BILLING_PLANS: Readonly<BillingPlan[]> = [
  {
    type: 'Basic',
    id: 'basic',
    periodDays: '',
    monthlyPrice: '0.00',
    totalPrice: '0.00',
    periodMonths: 0,
    plan_id: '0',
    name: ''
  },
  {
    type: 'Premium',
    id: 'premium_1m',
    periodDays: '30',
    monthlyPrice: '9.99',
    totalPrice: '9.99',
    periodMonths: 1,
    plan_id: 'pri_01htz4wvbazsqb9e7a7v9xd9ys',
    name: 'Monthly'
  },
  {
    type: 'Premium',
    id: "premium_3m",
    periodDays: '90',
    monthlyPrice: '6.66',
    totalPrice: '19.99',
    periodMonths: 3,
    popular: true,
    plan_id: 'pri_01hv1k41bpxq7jwsb1t687defj',
    name: 'Quarterly'
  },
  {
    type: 'Premium',
    id: "premium_12m",
    periodDays: '366',
    monthlyPrice: '4.99',
    totalPrice: '59.99',
    periodMonths: 12,
    plan_id: 'pri_01hv449fkptq4yv5cpcd8r09pg',
    name: 'Annual'
  },
];

export const PREMIUM_BILLING_PLAN_INCLUDES: Readonly<{ title: string }[]> = [
  {title: 'All Resume Templates'},
  {title: 'Matching Cover Letter'},
  {title: 'No Branding'},
  {title: 'Creative Infographics'},
  {title: '6 Picture Styles'},
  {title: 'Up to 29 Color Themes'},
  {title: 'Shareable Link'},
  {title: 'Export as PNG'},
];

export const FREE_BILLING_PLAN_INCLUDES: Readonly<{ title: string }[]> = [
  {title: '2 Free Templates'},
  {title: 'Unlimited Downloads'},
  {title: 'Multiple Layout Options'},
  {title: '3 Color Themes'},
  {title: '3 Fonts'},
  {title: 'No Cover Letter'},
];
