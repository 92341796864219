<div
  class="help-center"
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzTrigger="click"
  [nzPlacement]="'topLeft'"
  id="introjs_help-icon">
  <img ngSrc="/assets/img/icons/question.svg" width="12" height="17" alt="selfcv support" priority>

  <div class="support-dropdown">
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu class="header-user-menu">
        <li nz-menu-item class="user-menu-item" (click)="openChat()">
          <a>
            <i class="ph ph-chat-circle-dots"></i>
            Chat with us
          </a>
        </li>

        <li nz-menu-item class="user-menu-item" (click)="openFeedbackModal()">
          <a>
            <i class="ph ph-paper-plane-tilt"></i>
            Submit feedback
          </a>
        </li>

        <li nz-menu-divider></li>

        <li nz-menu-item class="user-menu-item">
          <a href="https://www.youtube.com/@selfcvbuilder" target="_blank">
            <i class="mr-10 ph ph-play"></i>
            Video Tutorials
          </a>
        </li>

        @if (appStateService.user?.emailVerified) {
          @if (appStateService.user?.referAndEarn?.expired && !appStateService.user?.referAndEarn?.eligible) {
            <li nz-menu-item class="user-menu-item" (click)="onReferFriend()">
              <a>
                <i class="mr-10 ph ph-gift"></i>
                Refer a friend
              </a>
            </li>
          }
        }

        <!--<li nz-menu-divider></li>

        <li nz-menu-item class="user-menu-item">
          <a>
            <i class="mr-10 ph ph-handshake"></i>
            Hire an expert
          </a>
        </li>
        <li nz-menu-item class="user-menu-item">
          <a>
            <i class="mr-10 ph ph-gift"></i>
            What's new
          </a>
        </li>-->
      </ul>
    </nz-dropdown-menu>
  </div>

</div>
