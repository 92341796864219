import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Resource} from '@app/shared/interfaces';
import {NgForOf, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'sf-resources-section',
  templateUrl: './resources-section.component.html',
  styleUrls: ['./resources-section.component.scss'],
  imports: [
    NgOptimizedImage,
    NgForOf
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesSectionComponent {
  @Input() public ngTitle = '';
  @Input() public resources: Readonly<Resource[]> = [];
}
