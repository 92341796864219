import {Router} from "@angular/router";
import {JWT} from "@app/shared/interfaces";
import {inject, Injectable} from '@angular/core';
import {AuthService} from "@app/shared/services";
import {httpContextDefaults} from "@app/shared/constants";
import {catchError, filter, finalize} from 'rxjs/operators';
import {BehaviorSubject, Observable, switchMap, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

const enum RequestHeaderKey {
  AuthToken = 'Authorization',
  RefreshToken = 'X-REFRESH-TOKEN',
  APIKey = 'API-KEY',
  ContentType = 'Content-Type'
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly tokenSubject = new BehaviorSubject<JWT | null>(null);
  private refreshTokenInProgress = false;

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = request.context.get(httpContextDefaults);

    if (params.skipAuth) {
      return next.handle(request);
    }

    if (!request.url.includes('refresh-token')) {
      request = this.addAuthHeader(request);
    }

    if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
      request = request.clone({headers: request.headers.set(RequestHeaderKey.ContentType, 'application/json')});
    }

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 && !this.router.url.includes('auth') && !error?.error?.refreshTokenExpired) {
            return this.refreshToken(request, next);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const authHeader = this.authService.token;
    if (authHeader) {
      return request.clone({
        setHeaders: {[RequestHeaderKey.AuthToken]: 'Bearer ' + authHeader}
      });
    }
    return request;
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.tokenSubject.next(null);
      return this.authService.refreshToken()
        .pipe(
          switchMap((res: JWT) => {
            this.refreshTokenInProgress = false;
            this.tokenSubject.next(res);
            return next.handle(this.addAuthHeader(request));
          }),
          catchError(() => {
            const urls = ['dashboard', 'account'];
            if (urls.some((url: string) => this.router.url.includes(url))) {
              this.router.navigate(['/']);
            }
            return this.authService.signOut();
          }),
          finalize(() => this.refreshTokenInProgress = false)
        );
    } else {
      return this.tokenSubject
        .pipe(
          filter((res) => res != null),
          switchMap(() => {
            return next.handle(this.addAuthHeader(request));
          })
        );
    }
  }

}

