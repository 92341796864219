<div class="choose-plan-main-container">

  <sf-payment-banner/>

  @if (title) {
    <div class="pl-top-part">
      <h1>{{ title }}</h1>
    </div>
  }

  <div class="sf-info-banner info ph ph-info mb-30">
    <div>
      <div>No recurring billing</div>
      <p>
        <strong class="fw-600">Make a one-time investment</strong> in your career and unlock a world of possibilities.
        No more monthly fees or unexpected charges just a comprehensive CV solution at an unbeatable price!
      </p>
    </div>
  </div>

  <div class="pl-bottom-part">
    <div class="pl-left-side">
      <div class="ls-top-part">
        <h3 class="basic">Basic</h3>
        <div class="free-pl">
          Free
          <p>with restrictions</p>
        </div>
      </div>

      <div class="ls-bottom-part">
        <div class="pl-includes">
          <h3>Plan includes:</h3>
          <ul>
            <li class="mb-4" *ngFor="let item of appStateService.freeBillingPlanIncludes">{{ item.title }}</li>
          </ul>
        </div>
      </div>
    </div>

   <sf-premium-plans/>
  </div>
</div>
