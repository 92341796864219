<div class="home-page-main-container">

  <div class="img-vector-CV-wrapper">
    <section class="main-section">
      <div class="lef-side">
        <h1><span>selfcv</span> resumes get people hired at top companies</h1>

        <p>
          Create a modern resume that will result in interview callbacks.
          All key things on a single page. Fast & easy-to-use.
        </p>

        <a class="sf-btn-primary" routerLink="/dashboard">Build resume now</a>
      </div>

      <div class="right-side"></div>
    </section>

    <sf-why-use-us-section/>
  </div>

  <div class="S-logo-sections">
    <sf-resume-examples-section/>

    <div class="find-new-career-section">
      <section>
        <div class="small-desc">selfcv</div>

        <h2>Find new career opportunities with the help of selfcv</h2>

        <div class="mb-52">Start building for free</div>

        <a class="sf-btn-primary" style="color:white" routerLink="/cv-templates">Get started</a>
      </section>
    </div>

    <div class="build-your-resume-section">
      <section>
        <div class="top-part">
          <div class="blue-small-desc">Find the best solutions for you</div>

          <h2>Build your resume in <b>4 simple steps</b></h2>

          <p>
            There are no additional services involved. Just follow the simple process described below to create and
            download your resume.
          </p>
        </div>

        <div class="body-part">
          <div class="step-item" *ngFor="let step of resumeBuildSteps">
            <i class="{{step.icon}}"></i>
            <div>{{ step.title }}</div>
            <p>{{ step.desc }}</p>
          </div>

          <i class="sf-icon-icon-logo"></i>
        </div>

      </section>
    </div>

  </div>

  <sf-faq-section [faq]="appStateService.FAQObj.homePage"/>
  <sf-more-then-section/>
</div>
