import {FAQ, FAQItem} from "@app/shared/interfaces";
import {NzCollapseComponent, NzCollapsePanelComponent} from "ng-zorro-antd/collapse";
import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'sf-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzCollapseComponent,
    NzCollapsePanelComponent
  ],
  standalone: true
})
export class FAQSectionComponent implements OnChanges {
  @Input() public faq: FAQ | null = null;
  public selectedInfo: FAQItem | null = null;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["faq"].currentValue) {
      this.selectedInfo = this.faq!.items[0];
    }
  }

  public onSelectItem(item: FAQItem): void {
    this.selectedInfo = item;
  }

}
