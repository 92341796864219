<div class="tm-st-banner" *ngIf="selectedItem === section.title">

  <div class="new-entry" *ngIf="isList" (click)="onAddListItem($event, section, -1, elRef)">
    <i class="ph ph-plus-circle"></i>
    New Entry
  </div>

  <div class="new-entry" *ngIf="!isList" (click)="onAddNewEntry(section, -1, elRef)">
    <i class="ph ph-plus-circle"></i>
    New Entry
  </div>

  <i
    class="ph ph-caret-down"
    nz-tooltip="Move Section Down"
    *ngIf="section.items.length > 0 && sectionIndex !== page[sectionName].items.length - 1 && template.pages.items.length === 1"
    (click)="moveSectionDown(pageIndex, sectionIndex, sectionName)"
  ></i>

  <i
    class="ph ph-caret-up"
    nz-tooltip="Move Section Up"
    (click)="moveSectionUp(pageIndex, sectionIndex, sectionName)"
    *ngIf="section.items.length > 0 && sectionIndex !== 0 && template.pages.items.length === 1"
  ></i>

<!--  <i class="ph ph-lightbulb-filament" nz-tooltip="Information and Examples"></i>-->

  <i class="ph ph-intersect-square" nz-tooltip="Rearrange Sections" (click)="openRearrangeSections()"></i>

  <i
    class="ph ph-trash-simple"
    nz-tooltip="Delete Sections"
    nz-popconfirm="Are you sure delete this section?"
    (nzOnConfirm)="onRemoveLastSectionItem(pageIndex, sectionIndex, sectionName)"
  ></i>

</div>
