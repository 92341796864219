import {NgIf} from "@angular/common";
import {Params} from "@angular/router";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {DeleteUserReason} from "@app/shared/interfaces";
import {myCustomValidator, requireOneControl} from "@app/shared/helpers";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sf-user-delete-reason',
  templateUrl: './user-delete-reason.component.html',
  styleUrls: ['./user-delete-reason.component.scss'],
  imports: [
    NzDividerModule,
    NgIf,
    ReactiveFormsModule
  ],
  standalone: true
})
export class UserDeleteReasonComponent implements OnInit {
  @Output() public close$ = new EventEmitter<void>();
  @Output() public save$ = new EventEmitter();
  private readonly formBuilder = inject(FormBuilder);

  protected deleteUserForm = this.formBuilder.group({
    reason: this.formBuilder.group({
      tooExpensive: [null],
      missingFeatures: [null],
      hardToLearn: [null],
      doNotNeed: [null],
      other: [null, myCustomValidator('otherMessage')],
      otherMessage: [null],
    }, {
      validator: requireOneControl(),
    }),
    advantages: this.formBuilder.group({
      goodPrice: [null],
      helpfulSupport: [null],
      easyToUse: [null],
      other: [null, myCustomValidator('otherMessage')],
      otherMessage: [null],
    }, {
      validator: requireOneControl()
    }),
    areYouSure: [null, [Validators.requiredTrue]],
  });

  protected step: 1 | 2 = 1;
  public deleteBtnLoadingState = false;

  ngOnInit() {
  }

  protected onCloseModal(): void {
    this.close$.emit();
  }

  protected onSubmit(): void {
    const obj = {
      reason: {
        tooExpensive: "Too expensive",
        missingFeatures: "Missing features I need",
        hardToLearn: "Hard to learn or use",
        doNotNeed: "I don’t need it anymore",
      },
      advantages: {
        goodPrice: "Affordable price",
        helpfulSupport: "Helpful support",
        easyToUse: "Easy to use",
      },
    };

    const result: DeleteUserReason = {
      deleteReason: this.getReasonMessage(obj.reason, this.deleteUserForm.value.reason),
      advantages: this.getReasonMessage(obj.advantages, this.deleteUserForm.value.advantages),
    }

    if (this.deleteUserForm.value.reason.other) {
      result.reasonOtherMessage = this.deleteUserForm.value.reason.otherMessage;
    }

    if (this.deleteUserForm.value.advantages.other) {
      result.advantagesOtherMessage = this.deleteUserForm.value.advantages.otherMessage;
    }

    if (this.deleteUserForm.valid) {
      this.save$.emit(result);
    }
  }

  private getReasonMessage(reason: Params, obj: Params): string {
    return Object.keys(reason)
      .filter((key: string) => obj[key])
      .filter((key) => key !== 'other')
      .filter((key) => key !== 'otherMessage')
      .map((key: string) => (reason[key]))
      .join(',');
  }

}

