export enum DocumentTypes {
  CV = 'cv',
  COVER = 'cover_letter'
}

export enum TemplateTypes {
  ONE_ROW = 'one-row',
  SIDEBAR = 'sidebar',
  ONLY_FIRST_PAGE_SIDEBAR = 'only-first-page-sidebar',
  TWO_ROWS = 'two-rows'
}

export enum SectionNames {
  SECTION = 'section',
  SIDEBAR_SECTION = 'sidebarSection',
  SIDEBAR_RIGHT_SECTION = 'sidebarRightSection',
  TWO_ROW_RIGHT_SIDE_SECTION = 'twoRowRightSideSection',
  TWO_ROW_LEFT_SIDE_SECTION = 'twoRowLeftSideSection',
}

export enum TemplateSectionTypes {
  SKILLS = 'skills',
  SOFT_SKILLS = 'soft-skills',
  LANGUAGES = 'languages',
  INTERESTS = 'interests',
  CAUSES = 'causes',
  WORK_EXPERIENCE = 'work-experience',
  EDUCATION = 'education',
  ACHIEVEMENTS = 'achievements',
  AWARDS = 'awards',
  CONFERENCES_AND_COURSES = 'conferences-and-courses',
  CERTIFICATES = 'certificates',
  TEACHING = 'teaching',
  VOLUNTEER = 'volunteer',
  ORGANIZATIONS = 'organizations',
  // REFERENCES = 'references',
  // PERSONAL_PROJECTS = 'personal-projects',
  // PUBLICATIONS = 'publications',
  AREAS_OF_EXPERTISE = 'areas-of-expertise',  // Does not exist on NOVO
  // TECHNICAL_SKILLS = 'technical-skills', // Exist on novo, but we do not
}

export enum TemplateSettingsShapes {
  TEXT = 'text',
  CHIPS = 'chips',
  INFOGRAPHICS = 'infographics',
  TEXT_OPTIONS = 'textOptions',
}

export enum TemplateInfographics {
  ROUND = 'round'
}

export enum TmSocialInfos {
  EMAIL = 'email',
  PHONE = 'phone',
  CITY = 'city',
  ADDRESS = 'address',
  LINKEDIN = 'linkedin',
  BIRTHDAY = 'birthday',
  LINK = 'link',
  FACEBOOK = 'facebook',
  STACKOVERFLOW = 'stackoverflow',
  SKYPE = 'skype',
  INSTAGRAM = 'instagram',
  GITHUB = 'github',
  MEDIUM = 'medium',
}

export enum TmClassnames {
  TEMPLATE_PAGE_PARENT = 'template-page-parent',
}

export enum TmFontSizesTypes {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large'
}
