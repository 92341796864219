<sf-header/>

<div class="contact-us-container">
  <h1>Contact Us</h1>

  <div class="c-as-child-container">

    <div class="c-us-part">
      <p class="mb-20">
        For any comments, questions or suggestions regarding your selfcv account, please, contact our support team.
      </p>


      <div class="mb-20">
        <h2 class="fw-600">Location</h2>
        <p>Nikoghayos Tigranyan blind alley 1 , Armenia, Yerevan, 0014 </p>
      </div>

      <p class="mb-20">
        <a class="sf-link" href="mailto: support@selfcv.com">support&#64;selfcv.com</a>
      </p>

      <p>
        <sf-project-social-icons/>
      </p>
    </div>

    <div class="c-us-part">
      <form
        [formGroup]="contactUsForm"
        #form="ngForm"
        (ngSubmit)="onSubmit()"
        (keydown.enter)="$event.preventDefault()">
        <div class="inputs-part">
          <div class="side-to-side-inputs">

            <div class="sf-input-wr">
              <input
                class="sf-input small"
                type="text"
                placeholder="Full Name"
                formControlName="fullName"
                sfTrim
                [class.error]="form.submitted && contactUsForm.controls['fullName'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['fullName'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  Full name is required
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  Full name max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
                </div>
              </div>
            </div>

            <div class="sf-input-wr">
              <input
                class="sf-input small"
                type="text"
                placeholder="Email"
                formControlName="email"
                sfTrim
                [class.error]="form.submitted && contactUsForm.controls['email'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['email'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  Email is required
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  Email max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
                </div>

                <div class="sf-input-err" *ngIf="error['email']">
                  <i class="ph ph-warning"></i>
                  Incorrect email address
                </div>
              </div>
            </div>
          </div>

          <div class="sf-input-wr mb-20">
            <input
              class="sf-input small"
              type="text"
              placeholder="Subject"
              formControlName="subject"
              sfTrim
              [class.error]="form.submitted && contactUsForm.controls['subject'].errors">

            <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['subject'].errors as error">
              <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                <i class="ph ph-warning"></i>
                Subject max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
              </div>
            </div>
          </div>

          <div class="sf-input-wr mb-20">
            <textarea
              class="sf-textarea"
              type="text"
              placeholder="Message"
              formControlName="message"
              sfTrim
              [class.error]="form.submitted && contactUsForm.controls['fullName'].errors"
            ></textarea>

            <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['fullName'].errors as error">
              <div class="sf-input-err" *ngIf="error['required']">
                <i class="ph ph-warning"></i>
                Message is required
              </div>

              <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                <i class="ph ph-warning"></i>
                Message length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
              </div>
            </div>
          </div>
        </div>

        <div class="button-part">
          <div class="btn-content">
            <div class="right-side">
              <button class="sf-btn-primary">Send Message</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>


</div>

<sf-footer/>
