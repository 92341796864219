<div class="resources-section">
  <section>
    <div class="top-part">
      <div class="title-top-desc">selfcv</div>
      <h2><strong>{{ngTitle}}</strong> Resources</h2>
    </div>

    <div class="content-part">
      <div class="content-item" *ngFor="let item of resources">
        <div class="left-side">
          <h3>{{item.title}}</h3>
          <p [innerHTML]="item.content"></p>
        </div>
        <img [ngSrc]="item.image" width="370" height="360" alt="selfcv" [priority]="item.priority">
      </div>
    </div>

  </section>
</div>
