import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from "@app/shared/interceptors/token.interceptor";
import {CacheInterceptor} from "@app/shared/interceptors/cache.interceptor";
import {NotifierInterceptor} from "@app/shared/interceptors/notifier.interceptor";
import {ErrorLogInterceptor} from "@app/shared/interceptors/error-log.interceptor";
import {UrlPrefixInterceptor} from "@app/shared/interceptors/url-prefix.interceptor";



export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: UrlPrefixInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: NotifierInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorLogInterceptor, multi: true},
];
