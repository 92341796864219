import {finalize} from "rxjs/operators";
import {RouterLink} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {CouponBannerComponent} from "@app/shared/ui-kits";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AppStateService, FeedbackService} from "@app/shared/services";
import {NzDropDownDirective, NzDropdownMenuComponent} from "ng-zorro-antd/dropdown";
import {NzMenuDirective, NzMenuDividerDirective, NzMenuItemComponent} from "ng-zorro-antd/menu";
import {SendFeedbackComponent} from "@app/shared/ui-kits/send-feedback/send-feedback.component";

declare const $crisp: any;

@Component({
  selector: 'sf-helper',
  templateUrl: './helper.component.html',
  styleUrl: './helper.component.scss',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuDividerDirective,
    NzMenuItemComponent,
    RouterLink
  ],
})
export class HelperComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly nzModalService = inject(NzModalService);
  private readonly feedbackService = inject(FeedbackService);
  protected readonly appStateService = inject(AppStateService);
  private readonly nzMessageService = inject(NzMessageService);

  constructor() {
  }

  ngOnInit() {
    this.appStateService.sendFeedback$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.openFeedbackModal();
      });
  }

  protected openChat() {
    if (typeof $crisp !== 'undefined') {
      $crisp.push(['do', 'chat:show']);
      $crisp.push(['do', 'chat:open']);
      $crisp.push(["on", "chat:closed",
        () => {
          $crisp.push(['do', 'chat:close']);
          $crisp.push(['do', 'chat:hide']);
        }
      ]);
    }
  }

  protected openFeedbackModal() {
    const modalRef = this.nzModalService.create({
      nzContent: SendFeedbackComponent,
      nzCentered: true,
      nzFooter: null
    });

    const component = modalRef.componentInstance;

    component?.close$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        modalRef.close();
      });

    component?.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((feedback) => {
        console.log('feedback', feedback);
        component!.btnLoaderState = true;
        this.feedbackService.sendFeedback(feedback)
          .pipe(
            finalize(() => component!.btnLoaderState = false),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(() => {
            modalRef.close();
            this.nzMessageService.create("success", "Thank you for your feedback! We've received it successfully.");
          });
      });
  }

  protected onReferFriend() {
    this.nzModalService.create({
      nzContent: CouponBannerComponent,
      nzFooter: null,
      nzClosable: false
    });
  }

}
