<div class="template-layouts" id="introjs_section-tm-layouts">
  <h3 class="d-flex ai-c mb-24">
    Template Layouts
    <i
      class="ph ph-question ml-4 c-pointer"
      nz-popover="Each template has 3 layouts."
      [nzPopoverContent]="TmTypeInfo"
      nzPopoverPlacement="bottom"
    ></i>
  </h3>

  <div class="d-flex jc-sb">
    @for (type of templateTypes; track type.src) {
      <img
        [ngSrc]="type.src"
        width="100"
        height="140"
        class="c-pointer"
        [alt]="type.name"
        [nz-tooltip]="type.name"
        [class.active]="template.type === type.type"
        (click)="onChangeTemplateType(type.type)">
    }
  </div>
</div>

<ng-template #TmTypeInfo>
  <div class="fs-14">
    <ul class="pl-20" style="list-style: initial">
      <li>One Row Template</li>
      <li>Two Rows Template</li>
      <li>Template with Sidebar</li>
    </ul>

    <p class="mt-10">You can switch between them at any time.</p>
  </div>
</ng-template>
