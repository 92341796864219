import {RouterLink} from "@angular/router";
import {finalize, Subject, takeUntil} from "rxjs";
import {AuthService} from "@app/shared/services";
import {HttpErrorResponse} from "@angular/common/http";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {SfTrimDirective} from "@app/shared/directives";

@Component({
  selector: 'sf-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  imports: [
    RouterLink,
    NgOptimizedImage,
    ReactiveFormsModule,
    NgIf,
    SfTrimDirective
  ],
  standalone: true
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly authService = inject(AuthService);

  public forgotPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]]
  });
  public loaderState = false;
  public checkEmailBlockState = false;
  public errorMessage = '';
  public resetState = true;
  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      this.loaderState = true;
      this.forgotPasswordRequests();
    }
  }

  private forgotPasswordRequests(): void {
    this.errorMessage = '';
    this.authService.forgotPassword(this.forgotPasswordForm.value)
      .pipe(
        finalize(() => this.loaderState = false),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          this.checkEmailBlockState = true;
        },
        error: (err: HttpErrorResponse) => this.errorMessage = err?.error?.reason
      });
  }

  public onResent(): void {
    this.resetState = false;
    this.forgotPasswordRequests();
    setTimeout(() => this.resetState = true, 5000);
  }

}
