import {Component, inject} from '@angular/core';
import {
  CouponBannerComponent,
  FooterComponent,
  HeaderComponent,
  ProjectSocialIconsComponent
} from "@app/shared/ui-kits";
import {NgForOf, NgIf} from "@angular/common";
import {
  NzAutocompleteComponent,
  NzAutocompleteOptionComponent,
  NzAutocompleteTriggerDirective
} from "ng-zorro-antd/auto-complete";
import {NzDatePickerComponent} from "ng-zorro-antd/date-picker";
import {NzOptionComponent, NzSelectComponent} from "ng-zorro-antd/select";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {SfTrimDirective} from "@app/shared/directives";

@Component({
  selector: 'sf-contact-us',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    CouponBannerComponent,
    NgForOf,
    NgIf,
    NzAutocompleteComponent,
    NzAutocompleteOptionComponent,
    NzAutocompleteTriggerDirective,
    NzDatePickerComponent,
    NzOptionComponent,
    NzSelectComponent,
    ReactiveFormsModule,
    SfTrimDirective,
    ProjectSocialIconsComponent
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {
  private readonly formBuilder = inject(FormBuilder);

  public readonly contactUsForm = this.formBuilder.group({
    fullName: [null, [Validators.required, Validators.maxLength(128)]],
    email: [null, [Validators.required, Validators.email, Validators.maxLength(128)]],
    subject: [null, [Validators.maxLength(128)]],
    message: [null, [Validators.required, Validators.maxLength(128)]],
  });

  protected onSubmit() {
    console.log(this.contactUsForm)
  }
}
