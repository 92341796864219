import {inject, Injectable} from '@angular/core';
import {TmClassnames} from "@app/shared/enums";
import {allSectionsDefaults, defaultEmptyPage} from "@app/shared/constants";
import {cloneObject, getElHeightWithMargins, isListSection} from "@app/shared/helpers";
import {TemplateConfigs, TemplatePage, TemplateSection, TemplateSectionItem} from "@app/shared/interfaces";
import {AppStateService} from "@app/shared/services";

const pagePaddingTop = 50;

@Injectable({
  providedIn: 'root'
})
export class TwoRowTmSplitLogicService {
  private readonly appStateService = inject(AppStateService);

  constructor() {
  }

  public twoRowSplitPages(template: TemplateConfigs) {
    this.twoRowLeftSideSplit(template);
    this.twoRowRightSideSplit(template);
  }

  /** Two Row Right Split Start */

  private twoRowRightSideSplit(template: TemplateConfigs) {
    const pageELList = document.querySelectorAll(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`);
    const lineElHeight = getElHeightWithMargins(document.querySelector(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`)?.querySelector('.sf-line')!);

    pageELList.forEach((pageEL, index) => {
      const pageBodyEl = pageEL.querySelector('.two-row-body .two-r-right');
      const pageBodyElSizes = pageBodyEl?.getBoundingClientRect();

      if (!pageBodyEl) {
        return;
      }

      const size = this.getSize(pageEL, index);

      if (pageBodyElSizes!.height > size) {
        this.addTwoRowRightSideSectionLogic(template, index);
      } else {
        const nextPageEl = pageELList[index + 1];
        if (nextPageEl) {
          console.log('the case smaller');

          const page = template.pages.items[index + 1];
          if (!page) {
            return;
          }

          const nextPageElBody = nextPageEl.querySelector(`.two-row-body .two-r-right`);
          const pageFirstSection = page.twoRowRightSideSection.items[0];

          if (!pageFirstSection) {
            this.removeTwoRowPage(template, page, index);
            return;
          }

          const firstSecEL = nextPageElBody?.children[0];

          if (!firstSecEL) {
            return;
          }
          const firstSecELSizes = firstSecEL.getBoundingClientRect();

          const prevPage = template.pages.items[index];
          const prevPageLastSection = prevPage.twoRowRightSideSection.items[prevPage.twoRowRightSideSection.items.length - 1];

          if (isListSection(pageFirstSection)) {

            console.log('list sidebarRightSection case');

            if ((pageBodyElSizes!.height + firstSecELSizes.height) + lineElHeight <= size) {

              console.log('Remove List Section Left Side', page);

              page.twoRowRightSideSection.items.shift();
              prevPage.twoRowRightSideSection.items.push(pageFirstSection);
              this.appStateService.saveChanges();

              this.removeTwoRowPage(template, page, index);
            }
          } else {

            console.log('NON list sidebarRightSection case');

            if (pageFirstSection.type === prevPageLastSection?.type) {
              console.log('same type');

              if ((pageBodyElSizes!.height + this.getWorkExpFirstChildElSizes(firstSecEL).height) + 12 <= size) {
                const first = pageFirstSection.items.shift() as TemplateSectionItem;

                prevPageLastSection.items = [...prevPageLastSection.items, first];

                if (pageFirstSection.items.length === 0) {
                  page.twoRowRightSideSection.items.splice(0, 1);
                  this.appStateService.saveChanges();
                }

                this.removeTwoRowPage(template, page, index);
              }
            } else {

              console.log('Dif Types');

              if (pageFirstSection.items.length === 1) {

                console.log('length is 1');

                if ((pageBodyElSizes!.height + this.getLastSecELSizes(nextPageEl, '.right-side .two-r-right').height) + lineElHeight <= size) {

                  const first = page.twoRowRightSideSection.items.shift() as TemplateSection;

                  prevPage.twoRowRightSideSection.items.push(first);
                  this.appStateService.saveChanges();
                  this.removeTwoRowPage(template, page, index);
                }
              } else {

                console.log('Length is bigger');

                const workExpPartTitleEL: HTMLDivElement | null = firstSecEL!.querySelector('.work-experience-part .w-e-t-w-t');
                const blockTitle = workExpPartTitleEL?.clientHeight || 44;

                console.log('blockTitle', blockTitle);

                if ((pageBodyElSizes!.height + this.getWorkExpFirstChildElSizes(firstSecEL).height) + blockTitle + lineElHeight <= size) {
                  const first = pageFirstSection.items.shift() as TemplateSectionItem;

                  const newSection = cloneObject(allSectionsDefaults[pageFirstSection.type]);
                  newSection.items = [first];

                  prevPage.twoRowRightSideSection.items.push(newSection);
                  this.appStateService.saveChanges();
                }

              }

            }


          }

        }

      }

    });
  }

  private addTwoRowRightSideSectionLogic(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const lastSection = page.twoRowRightSideSection.items[page.twoRowRightSideSection.items.length - 1];

    if (isListSection(lastSection)) {
      this.addTwoRowRightSideListSection(template, index);
    } else {
      this.addTwoRowRightSideNotListSection(template, index);
    }
  }

  private addTwoRowRightSideListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.twoRowRightSideSection.items.pop() as TemplateSection;

    if (!nextPage) {
      const defaultPage = cloneObject(defaultEmptyPage);
      defaultPage.twoRowRightSideSection.items = [lastSection];

      template.pages.items.push(defaultPage);
      this.appStateService.saveChanges();
    } else {
      nextPage.twoRowRightSideSection.items.unshift(lastSection);
      this.appStateService.saveChanges();
    }
  }

  private addTwoRowRightSideNotListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.twoRowRightSideSection.items[page.twoRowRightSideSection.items.length - 1];
    const lastSectionLastItem = lastSection.items[lastSection.items.length - 1];

    if (!nextPage) {
      const newPage = cloneObject(defaultEmptyPage);

      if (lastSection.items.length === 1) {
        newPage.twoRowRightSideSection.items = [lastSection];
        page.twoRowRightSideSection.items.pop();
      } else {
        const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
        newSection.items = [lastSectionLastItem];
        newSection.title = lastSection.title;
        newPage.twoRowRightSideSection.items = [newSection];
        lastSection.items.pop();
      }

      template.pages.items.push(newPage);
      this.appStateService.saveChanges();

    } else {

      if (nextPage.twoRowRightSideSection.items.length === 0) {
        if (lastSection.items.length === 1) {
          nextPage.twoRowRightSideSection.items = [lastSection];
          page.twoRowRightSideSection.items.pop();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.twoRowRightSideSection.items = [newSection];
          lastSection.items.pop();
        }
        return;
      }

      const nextPageFirstSection = nextPage.twoRowRightSideSection.items[0];

      if (!nextPageFirstSection) {
        return;
      }

      if (lastSection.items.length === 1) {

        if (nextPageFirstSection.type === lastSection.type) {

          nextPageFirstSection.items.unshift(lastSectionLastItem);
          page.twoRowRightSideSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          nextPage.twoRowRightSideSection.items.unshift(lastSection);
          page.twoRowRightSideSection.items.pop();
          this.appStateService.saveChanges();
        }

      } else {
        if (nextPageFirstSection.type === lastSection.type) {
          nextPageFirstSection.items.unshift(lastSectionLastItem);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.twoRowRightSideSection.items.unshift(newSection);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        }
      }

    }

  }

  /** Two Row Right Split End */

  /** Two Row Left Side Split Start */

  private getSize(pageEL: Element, index: number) {
    const pageElSizes = pageEL.getBoundingClientRect();
    const pageBodyEl = pageEL.querySelector('.two-row-body .two-r-left');
    const pageBodyElSizes = pageBodyEl?.getBoundingClientRect();

    let size = pageElSizes!.height; // 1348

    if (index === 0) {
      size = size - (pageBodyElSizes!.top - pageElSizes!.top);
    } else {
      size = size - pagePaddingTop;
    }

    return size;
  }

  private twoRowLeftSideSplit(template: TemplateConfigs) {
    const pageELList = document.querySelectorAll(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`);
    const lineElHeight = getElHeightWithMargins(document.querySelector(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`)?.querySelector('.sf-line')!);

    pageELList.forEach((pageEL, index) => {
      const pageBodyEl = pageEL.querySelector('.two-row-body .two-r-left');
      if (!pageBodyEl) {
        return;
      }

      const pageBodyElSizes = pageBodyEl?.getBoundingClientRect();

      const size = this.getSize(pageEL, index);

      if (pageBodyElSizes!.height > size) {
        this.addTwoRowLeftSideSectionLogic(template, index);
      } else {
        const nextPageEl = pageELList[index + 1];

        if (nextPageEl) {

          console.log('the case smaller');

          const page = template.pages.items[index + 1];
          if (!page) {
            return;
          }

          const pageFirstSection = page.twoRowLeftSideSection.items[0];
          if (!pageFirstSection) {
            this.removeTwoRowPage(template, page, index);
            return;
          }

          const nextPageElBody = nextPageEl.querySelector(`.two-row-body .two-r-left`);
          const firstSecEL = nextPageElBody?.children[0];
          if (!firstSecEL) {
            return;
          }

          const firstSecELSizes = firstSecEL.getBoundingClientRect();

          const prevPage = template.pages.items[index];
          const prevPageLastSection = prevPage.twoRowLeftSideSection.items[prevPage.twoRowLeftSideSection.items.length - 1];


          if (isListSection(pageFirstSection)) {

            console.log('list twoRowLeftSideSection case');

            if ((pageBodyElSizes!.height + firstSecELSizes.height) + lineElHeight <= size) {

              page.twoRowLeftSideSection.items.shift();
              prevPage.twoRowLeftSideSection.items.push(pageFirstSection);
              this.appStateService.saveChanges();

              console.log('Remove List Section Left Side', page);

              this.removeTwoRowPage(template, page, index);
            }
          } else {

            console.log('NON list twoRowLeftSideSection case');

            if (pageFirstSection.type === prevPageLastSection?.type) {
              console.log('same type');

              if ((pageBodyElSizes!.height + this.getWorkExpFirstChildElSizes(firstSecEL).height) + 12 <= size) {
                const first = pageFirstSection.items.shift() as TemplateSectionItem;

                prevPageLastSection.items = [...prevPageLastSection.items, first];

                if (pageFirstSection.items.length === 0) {
                  page.twoRowLeftSideSection.items.splice(0, 1);
                  this.appStateService.saveChanges();
                }

                this.removeTwoRowPage(template, page, index);
              }
            } else {

              console.log('Dif Types');

              if (pageFirstSection.items.length === 1) {

                console.log('length is 1');
                if ((pageBodyElSizes!.height + this.getLastSecELSizes(nextPageEl, '.two-row-body .two-r-left').height) + lineElHeight <= size) {

                  const first = page.twoRowLeftSideSection.items.shift() as TemplateSection;

                  prevPage.twoRowLeftSideSection.items.push(first);
                  this.appStateService.saveChanges();
                  this.removeTwoRowPage(template, page, index);
                }
              } else {

                console.log('Length is bigger');

                const workExpPartTitleEL: HTMLDivElement | null = firstSecEL!.querySelector('.work-experience-part .w-e-t-w-t');
                const blockTitle = workExpPartTitleEL?.clientHeight || 44;

                if ((pageBodyElSizes!.height + this.getWorkExpFirstChildElSizes(firstSecEL).height) + blockTitle + lineElHeight <= size) {
                  const first = pageFirstSection.items.shift() as TemplateSectionItem;

                  const newSection = cloneObject(allSectionsDefaults[pageFirstSection.type]);
                  newSection.items = [first];

                  prevPage.twoRowLeftSideSection.items.push(newSection);
                  this.appStateService.saveChanges();
                }

              }

            }

          }

        }

      }

    });
  }

  private removeTwoRowPage(template: TemplateConfigs, page: TemplatePage, index: number) {
    if (page.twoRowLeftSideSection.items.length === 0 && page.twoRowRightSideSection.items.length === 0) {
      template.pages.items.splice(index + 1, 1);
      this.appStateService.saveChanges();
    }
  }

  private getLastSecELSizes(nextPageEl: Element, className: string) {
    const lastSecBodyEL = nextPageEl.querySelector(className);
    const lastSecEL = lastSecBodyEL?.children[0];
    return lastSecEL!.getBoundingClientRect();
  }

  private getWorkExpFirstChildElSizes(firstSecEL: Element) {
    const workExpFirstChildEl: HTMLDivElement | undefined = firstSecEL!.querySelector('.work-experience-list')?.children[0] as HTMLDivElement;
    return workExpFirstChildEl.getBoundingClientRect();
  }

  private addTwoRowLeftSideSectionLogic(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const lastSection = page?.twoRowLeftSideSection.items[page.twoRowLeftSideSection.items.length - 1];

    if (!lastSection) {
      return;
    }

    if (isListSection(lastSection)) {
      this.addTwoRowLeftSideListSection(template, index);
    } else {
      this.ddTwoRowLeftSideNotListSection(template, index);
    }
  }

  private addTwoRowLeftSideListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.twoRowLeftSideSection.items.pop() as TemplateSection;

    if (!nextPage) {
      const defaultPage = cloneObject(defaultEmptyPage);
      defaultPage.twoRowLeftSideSection.items = [lastSection];

      template.pages.items.push(defaultPage);
      this.appStateService.saveChanges();
    } else {
      nextPage.twoRowLeftSideSection.items.unshift(lastSection);
      this.appStateService.saveChanges();
    }
  }

  private ddTwoRowLeftSideNotListSection(template: TemplateConfigs, index: number) {
    const page = template.pages.items[index];
    const nextPage = template.pages.items[index + 1];

    const lastSection = page.twoRowLeftSideSection.items[page.twoRowLeftSideSection.items.length - 1];
    const lastSectionLastItem = lastSection.items[lastSection.items.length - 1];

    if (!nextPage) {
      const newPage = cloneObject(defaultEmptyPage);

      if (lastSection.items.length === 1) {
        newPage.twoRowLeftSideSection.items = [lastSection];
        page.twoRowLeftSideSection.items.pop();
      } else {
        const newSection = cloneObject(allSectionsDefaults[lastSection.type]);

        newSection.items = [lastSectionLastItem];
        newSection.title = lastSection.title;
        newPage.twoRowLeftSideSection.items = [newSection];
        lastSection.items.pop();
      }

      template.pages.items.push(newPage);
      this.appStateService.saveChanges();

    } else {

      if (nextPage.twoRowLeftSideSection.items.length === 0) {
        if (lastSection.items.length === 1) {
          nextPage.twoRowLeftSideSection.items = [lastSection];
          page.twoRowLeftSideSection.items.pop();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);

          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.twoRowLeftSideSection.items = [newSection];
          lastSection.items.pop();
        }
        return;
      }

      const nextPageFirstSection = nextPage.twoRowLeftSideSection.items[0];

      if (lastSection.items.length === 1) {

        if (nextPageFirstSection.type === lastSection.type) {

          nextPageFirstSection.items.unshift(lastSectionLastItem);
          page.twoRowLeftSideSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          nextPage.twoRowLeftSideSection.items.unshift(lastSection);
          page.twoRowLeftSideSection.items.pop();
          this.appStateService.saveChanges();
        }

      } else {
        if (nextPageFirstSection.type === lastSection.type) {
          nextPageFirstSection.items.unshift(lastSectionLastItem);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        } else {
          const newSection = cloneObject(allSectionsDefaults[lastSection.type]);
          newSection.items = [lastSectionLastItem];
          newSection.title = lastSection.title;
          nextPage.twoRowLeftSideSection.items.unshift(newSection);
          lastSection.items.pop();
          this.appStateService.saveChanges();
        }
      }

    }

  }

  /** Two Row Left Side Split Start */

}
