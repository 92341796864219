import {Params} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {httpContextDefaults} from "@app/shared/constants";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

@Injectable()
export class NotifierInterceptor implements HttpInterceptor {

  private readonly nzNotificationService = inject(NzNotificationService);

  public intercept(req: HttpRequest<Params>, next: HttpHandler): Observable<HttpEvent<Params>> {
    const params = req.context.get(httpContextDefaults);

    return next.handle(req)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (params.notifySuccess) {
              this.nzNotificationService.success('Success', 'Changes Successfully saved!');
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (params.notifyError) {
            if (error?.error?.reason) {
              let message = 'Something weird happened, please try later!';
              this.nzNotificationService.error('Error', error.error.reason);
            }
          }
          return throwError(error);
        })
      );
  }

}

