<div class="template-settings-sidebar-container">

  <div class="header-parts d-flex ai-c jc-sb">
    <div class="sf-checkbox min-w-80">
      <input
        type="checkbox"
        id="tm-title"
        [(ngModel)]="template.settings.subTitle.state"
        (ngModelChange)="saveChanges()">
      <label for="tm-title">Title</label>
    </div>

    <div class="sf-checkbox min-w-80">
      <input type="checkbox" id="tm-photo" [(ngModel)]="template.settings.img.state" (ngModelChange)="saveChanges()">
      <label for="tm-photo">Photo</label>
    </div>

    <div class="sf-checkbox min-w-80">
      @if (template.documentType === DocumentTypes.CV) {
        <input
          type="checkbox"
          id="tm-summary"
          [(ngModel)]="$any(template.settings).summary.state"
          (ngModelChange)="saveChanges()">
        <label for="tm-summary">Summary</label>
      }
    </div>
  </div>

  <nz-divider></nz-divider>

  @if (template.documentType === DocumentTypes.CV) {
    <sf-tm-layouts [template]="$any(template).settings"/>

    <nz-divider></nz-divider>
  }

  @if ($any(template.settings).type === TemplateTypes.SIDEBAR) {
    <div class="move-to-sidebar">
      <div (click)="toggleSocialInfoSidebarPosition()">Move Social Info into sidebar</div>
      <nz-switch
        [(ngModel)]="template.settings.socialInfo.onSidebar"
        (ngModelChange)="saveChanges()"
      ></nz-switch>
    </div>
  }

  @if (!template.settings.pureTemplate) {
    @if (template.settings.socialInfo.onSidebar) {
      @if ($any(template.settings).type === TemplateTypes.SIDEBAR) {
        <div class="move-to-sidebar">
          <div (click)="onToggleSocialInfoTitleState()">Social Info Title Enabled</div>
          <nz-switch
            [(ngModel)]="template.settings.socialInfo.title.state"
            (ngModelChange)="saveChanges()"
          ></nz-switch>
        </div>
      }
    }

    @if (template.documentType === DocumentTypes.CV) {
      <div class="move-to-sidebar">
        <div (click)="onToggleSummaryTitleState()">Summary Title Enabled</div>
        <nz-switch
          [(ngModel)]="$any(template.settings).summary.title.state"
          (ngModelChange)="saveChanges()"
        ></nz-switch>
      </div>
    }
  }

  @if (template.documentType === DocumentTypes.COVER) {
    <div class="d-flex align-c jc-sb mt-20">
      <div class="c-pointer" (click)="toggleCoverRecipient()">Cover Letter Recipient</div>
      <nz-switch
        [(ngModel)]="$any(template.settings).companyDetails.state"
        (ngModelChange)="coverRecipientStateChanged()"
      ></nz-switch>
    </div>

    <div class="d-flex align-c jc-sb mt-20">
      <div class="c-pointer" (click)="toggleCoverSender()">Cover Letter Sender</div>
      <nz-switch
        [(ngModel)]="$any(template.settings).content.sender.state"
        (ngModelChange)="saveChanges()"
      ></nz-switch>
    </div>
  }

  @if (template.documentType === DocumentTypes.CV) {
    @if ($any(template.settings).type === TemplateTypes.SIDEBAR) {

      <nz-divider/>

      <div>
        <div class="fs-13 mb-4">Sidebar position</div>
        <nz-segmented
          [nzOptions]="sidebarOptions"
          [ngModel]="$any(template.settings).sidebarPosition === 'left' ? 0 : 1"
          (nzValueChange)="handleIndexChange($event)"/>
      </div>

      <nz-divider/>

      <div>
        <div class="fs-13 mb-4">Sidebar width</div>
        <nz-slider
          [nzMarks]="marks"
          [nzMin]="$any(template.settings).settings.sidebar.minWidth"
          [nzMax]="$any(template.settings).settings.sidebar.maxWidth"
          [(ngModel)]="$any(template.settings).settings.sidebar.with"
          (ngModelChange)="saveChanges()"/>
      </div>
    }

  }

  <nz-divider/>

  <div class="d-flex align-c jc-sb">
    <div class="c-pointer d-flex ai-c" (click)="toggleBranding()">
      <i class="ph ph-star mr-6 color-4b57dc" nz-tooltip="Paid"></i>
      Branding
    </div>

    <nz-switch
      [(ngModel)]="template.settings!.branding.enabled"
      (ngModelChange)="brandingStateChanged()"
    ></nz-switch>
  </div>

</div>
