interface NavMenu {
  route: string;
  text: string;
  icon: string;
  external?: boolean;
}

export const NAV_MENU:  Readonly<NavMenu[]> = [
  // {route: '/resume-templates', text: 'Resume Templates', icon: 'ph ph-read-cv-logo'},
  {route: '/cv-templates', text: 'Templates', icon: 'ph ph-read-cv-logo'},
  {route: '/cover-letters', text: 'Cover Letters', icon: 'ph ph-envelope'},
  {route: '/pricing', text: 'Pricing', icon: 'ph ph-hand-coins'},
  // {route: 'https://blog.angular.io/', text: 'Blog', icon: 'ph ph-arrow-square-out', external: true},
];
