<div class="user-delete-main-container">

  <form [formGroup]="deleteUserForm" #form="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
    <div class="delete-part-container" *ngIf="step === 1" formGroupName="reason">

      <div class="top-part">
        <h2>Why do you want to leave us?</h2>
        <p>Your feedback would be appreciated to improve our product</p>
      </div>

      <nz-divider></nz-divider>

      <div class="checkboxes-part">
        <p class="mb-20">Why do you want to leave us?</p>

        <div class="checkboxes">
          <div class="sf-checkbox">
            <input type="checkbox" id="too-expensive" formControlName="tooExpensive">
            <label for="too-expensive">Too expensive</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="features-need" formControlName="missingFeatures">
            <label for="features-need">Missing features I need</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="hard-to-use" formControlName="hardToLearn">
            <label for="hard-to-use">Hard to learn or use</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="need-not" formControlName="doNotNeed">
            <label for="need-not">I don’t need it anymore</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="other-reason" formControlName="other">
            <label for="other-reason">Other</label>
          </div>
        </div>
      </div>

      <div class="textarea-part">
        <p>Is there anything else you’d like to share with the team?</p>
        <textarea
          [class.disabled]="!deleteUserForm.controls.reason.controls['other'].value"
          [readOnly]="!deleteUserForm.controls.reason.controls['other'].value"
          class="sf-textarea"
          [autofocus]="false"
          placeholder="We read all of these messages, one by one. Seriously."
          formControlName="otherMessage"
        ></textarea>
      </div>

      <nz-divider></nz-divider>

      <div class="buttons-part">
        <button type="button" class="sf-btn-tertiary small mr-16 w-100" (click)="onCloseModal()">Cancel</button>
        <button
          type="button" class="sf-btn-primary small w-80"
          (click)="step = 2"
          [disabled]="deleteUserForm.controls.reason.invalid">
          Next
        </button>
      </div>

    </div>

    <div class="delete-part-container" *ngIf="step === 2">

      <div class="top-part">
        <h2>It was’t all that bad, was it?</h2>
        <p>Your feedback would be appreciated to improve our product</p>
      </div>

      <nz-divider></nz-divider>

      <ng-container formGroupName="advantages">
        <div class="checkboxes-part">
          <p class="mb-20">What did we do well?</p>

          <div class="checkboxes">
            <div class="sf-checkbox">
              <input type="checkbox" id="good-price" formControlName="goodPrice">
              <label for="good-price">Affordable price</label>
            </div>

            <div class="sf-checkbox">
              <input type="checkbox" id="helpful-support" formControlName="helpfulSupport">
              <label for="helpful-support">Helpful support</label>
            </div>

            <div class="sf-checkbox">
              <input type="checkbox" id="easy-to-use" formControlName="easyToUse">
              <label for="easy-to-use">Easy to use</label>
            </div>

            <div class="sf-checkbox">
              <input type="checkbox" id="other-reason2" formControlName="other">
              <label for="other-reason2">Other</label>
            </div>
          </div>
        </div>

        <div class="textarea-part">
          <p>Is there anything else you’d like to share with the team?</p>

          <textarea
            [class.disabled]="!deleteUserForm.controls.advantages.controls['other'].value"
            [readOnly]="!deleteUserForm.controls.advantages.controls['other'].value"
            class="sf-textarea"
            placeholder="We read all of these messages, one by one. Seriously."
            formControlName="otherMessage"
          ></textarea>
        </div>
      </ng-container>

      <div class="sf-input-wr mt-40">
        <div class="sf-checkbox" [class.error]="form.submitted && deleteUserForm.controls.areYouSure.errors">
          <input type="checkbox" id="are-you-sure" formControlName="areYouSure">
          <label for="are-you-sure">Your account will be deleted permanently. Are you sure?</label>
        </div>

        <div class="sf-err-wr" *ngIf="form.submitted && deleteUserForm.controls.areYouSure.errors as error">
          <div class="sf-input-err" *ngIf="error['required']">
            <i class="ph ph-warning"></i>
            This field is required
          </div>
        </div>
      </div>

      <nz-divider></nz-divider>

      <div class="buttons-part">
        <button type="button" class="sf-btn-tertiary small mr-16 w-100" (click)="step = 1">Back</button>
        <button
          class="sf-btn-delete small w-220"
          [disabled]="deleteUserForm.controls.advantages.invalid"
          [class.loading]="deleteBtnLoadingState">
          Yes, delete my account
        </button>
      </div>

    </div>
  </form>

</div>
