import {cloneObject} from "@app/shared/helpers";
import {NzDrawerOptions} from "ng-zorro-antd/drawer/drawer-options";
import {TemplateInfographics, TemplateSectionTypes, TemplateSettingsShapes, TmSocialInfos} from "@app/shared/enums";
import {
  AllSectionsDefaults,
  AllSectionsDefaultsItems,
  SectionAchievement,
  SectionItemDate,
  TemplateBranding,
  TemplateImage,
  TemplatePage,
  TemplateSectionListItem,
  TemplateSectionsShape,
  TemplateSocialInfo,
  TextMaxSizes
} from "@app/shared/interfaces";

export const nzDrw: NzDrawerOptions = {
  nzClosable: false,
  nzPlacement: 'left',
  nzBodyStyle: {
    paddingLeft: '16px',
    paddingRight: '16px'
  }
};

export const TEMPLATE_TEXT_MAX_SIZES: Readonly<TextMaxSizes> = {
  mainTitle: 40,
  suTitle: 40,

  // summaryMaxHeight: 900,
  summary: 2000,

  sectionMainTitle: 160,
  sectionTitle: 480,
  sectionSubtitle: 480,
  sectionDesc: 1200,
  // descMaxHeight: 210,

  achievementTitle: 40,
  achievement: 480,
  // achievementMaxHeight: 360,
  address: 60,

  listSectionItem: 80,

  coverLetterTo: 80,
  coverLetterRecipient: 300,

  coverLetterContent: 4302,
  // coverLetterContentMaxHeight: 800,
};

export const templateSectionsShape: Readonly<TemplateSectionsShape> = {
  [TemplateSettingsShapes.TEXT]: {
    defaultValue: 'text-initial',
    items: [
      {name: 'Beginner'},
      {name: 'Intermediate'},
      {name: 'Advanced'},
      {name: 'Proficient'},
      {name: 'Native'},
    ]
  },
  [TemplateSettingsShapes.CHIPS]: {
    items: [
      {type: 'chip-initial', className: 'chip-initial'},
      {type: 'bg-color-full', className: 'bg-color-full'},
      {type: 'color-full', className: 'color-full'},
    ]
  },
  [TemplateSettingsShapes.INFOGRAPHICS]: {
    items: [
      {type: 'infographic-round', paid: false, spans: true},
      {type: 'infographic-square', paid: true, spans: true},
    ]
  }
};

export const sectionAchievement: Readonly<SectionAchievement> = {
  text: '',
  hasEditor: true
};

export const tmSkill: Readonly<TemplateSectionListItem> = {
  icon: 'ph ph-user',
  text: '',
  skillCount: 0
};

export const tmDate: Readonly<SectionItemDate> = {
  pl: 'mm/yyyy-present',
  fromMonth: '',
  fromYear: '',
  toMonth: '',
  toYear: '',
  prefix: '/',
  present: false,
  presentString: 'Present',
  state: true
};

export const allSectionsItemsDefaults: Readonly<AllSectionsDefaultsItems> = {
  [TemplateSectionTypes.WORK_EXPERIENCE]: {
    type: TemplateSectionTypes.WORK_EXPERIENCE,
    title: {
      text: '',
      pl: 'Your Profession',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Company Name',
      state: true
    },
    desc: {
      text: '',
      pl: 'Description about company you worked on',
      state: true,
      hasEditor: true
    },
    date: cloneObject(tmDate),
    address: {
      pl: 'City, Country',
      text: '',
      state: true
    },
    achievements: {
      title: {
        text: 'Achievements',
        pl: 'Achievements',
        state: true,
      },
      state: true,
      hasEditor: false,
      achItemPl: 'Responsibilities',
      items: [cloneObject(sectionAchievement)],
      column: {
        cols: 1
      }
    },
    contact: {
      person: '',
      info: ''
    }
  },
  [TemplateSectionTypes.EDUCATION]: {
    type: TemplateSectionTypes.EDUCATION,
    title: {
      text: '',
      pl: 'Study Program',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'School or University',
      state: true
    },
    desc: {
      text: '',
      pl: 'About University',
      state: true,
      hasEditor: true
    },
    date: cloneObject(tmDate),
    address: {
      pl: 'City, Country',
      text: '',
      state: true
    },
    achievements: {
      title: {
        text: 'Courses',
        pl: 'Courses',
        state: true
      },
      state: true,
      achItemPl: 'Courses/Theses/Project',
      items: [cloneObject(sectionAchievement)],
      column: {
        cols: 2
      }
    }
  },
  [TemplateSectionTypes.TEACHING]: {
    type: TemplateSectionTypes.TEACHING,
    title: {
      text: '',
      pl: 'Name of the class',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Place of Education',
      state: true
    },
    desc: {
      text: '',
      pl: 'Description',
      state: true,
      hasEditor: true
    },
    date: cloneObject(tmDate),
    address: {
      pl: 'City, Country',
      text: '',
      state: true
    },
    achievements: {
      title: {
        text: 'Achievements',
        pl: 'Achievements',
        state: true
      },
      state: true,
      achItemPl: 'Task/Responsibility/Accomplishment',
      items: [cloneObject(sectionAchievement)],
      column: {
        cols: 3
      }
    },
    contact: {
      person: '',
      info: ''
    }
  },
  [TemplateSectionTypes.VOLUNTEER]: {
    type: TemplateSectionTypes.VOLUNTEER,
    title: {
      text: '',
      pl: 'Title/Position',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Organization',
      state: true
    },
    desc: {
      text: '',
      pl: 'Description',
      state: true,
      hasEditor: true
    },
    date: cloneObject(tmDate),
    address: {
      pl: 'City, Country',
      text: '',
      state: true
    },
    achievements: {
      title: {
        text: 'Achievements',
        pl: 'Achievements',
        state: true
      },
      state: true,
      achItemPl: 'Task/Responsibility/Accomplishment',
      items: [cloneObject(sectionAchievement)],
      column: {
        cols: 2
      }
    },
    contact: {
      person: '',
      info: ''
    }
  },
  [TemplateSectionTypes.AWARDS]: {
    type: TemplateSectionTypes.AWARDS,
    title: {
      text: '',
      pl: 'Title/Award Name',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Name of the institution',
      state: true
    },
    desc: {
      text: '',
      pl: 'Description',
      state: true,
      hasEditor: true
    },
    date: cloneObject(tmDate),
  },

  [TemplateSectionTypes.CONFERENCES_AND_COURSES]: {
    type: TemplateSectionTypes.CONFERENCES_AND_COURSES,
    title: {
      text: '',
      pl: 'Conference/Course Name',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Subtitle',
      state: true
    },
    desc: {
      text: '',
      pl: 'Description',
      state: false,
      hasEditor: true
    },
    date: cloneObject(tmDate),
    achievements: {
      title: {
        text: 'Achievements',
        pl: 'Achievements',
        state: true
      },
      achItemPl: 'Description (optional)',
      state: false,
      items: [cloneObject(sectionAchievement)],
      column: {
        cols: 3
      }
    },
  },
  [TemplateSectionTypes.ACHIEVEMENTS]: {
    type: TemplateSectionTypes.ACHIEVEMENTS,
    title: {
      text: '',
      pl: 'Achievement Name',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Subtitle',
      state: true
    },
    date: cloneObject(tmDate),
    desc: {
      text: '',
      pl: 'Description',
      state: false,
      hasEditor: true
    },
  },
  [TemplateSectionTypes.CERTIFICATES]: {
    type: TemplateSectionTypes.CERTIFICATES,
    title: {
      text: '',
      pl: 'Certificate Name',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Subtitle',
      state: true
    },
    date: cloneObject(tmDate),
    desc: {
      text: '',
      pl: 'Description',
      state: false,
      hasEditor: true
    }
  },
  [TemplateSectionTypes.ORGANIZATIONS]: {
    type: TemplateSectionTypes.ORGANIZATIONS,
    title: {
      text: '',
      pl: 'Organization Name',
      state: true
    },
    subTitle: {
      text: '',
      pl: 'Subtitle',
      state: true
    },
    date: cloneObject(tmDate),
    desc: {
      text: '',
      pl: 'Role',
      state: false,
      hasEditor: true
    },
  },
  /*[TemplateSectionTypes.REFERENCES]: {
    type: TemplateSectionTypes.REFERENCES,
    title: {
      text: '',
      pl: 'Name of Position',
      state: true
    },
    desc: {
      text: '',
      pl: 'Reference',
      state: true
    },
    email: {
      text: '',
      pl: 'robinson@gmail.com',
      state: true
    },
    phone: {
      text: '',
      pl: '+1212-941-7824',
      state: true
    }
  },*/

  [TemplateSectionTypes.SKILLS]: cloneObject(tmSkill),
  [TemplateSectionTypes.SOFT_SKILLS]: cloneObject(tmSkill),
  [TemplateSectionTypes.LANGUAGES]: {
    icon: 'ph ph-globe-hemisphere-east',
    text: '',
    skillCount: 1
  },
  [TemplateSectionTypes.INTERESTS]: {
    icon: 'ph ph-person-simple-bike',
    text: '',
    skillCount: 0
  },
  [TemplateSectionTypes.CAUSES]: cloneObject(tmSkill),
  [TemplateSectionTypes.AREAS_OF_EXPERTISE]: cloneObject(tmSkill),
};

export const defaultEmptyPage: Readonly<TemplatePage> = {
  section: {
    items: []
  },
  sidebarSection: {
    items: []
  },
  sidebarRightSection: {
    items: []
  },
  twoRowRightSideSection: {
    items: []
  },
  twoRowLeftSideSection: {
    items: []
  },
};

export const allSectionsDefaults: Readonly<AllSectionsDefaults> = {
  [TemplateSectionTypes.SKILLS]: {
    type: TemplateSectionTypes.SKILLS,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'SKILLS',
      pl: 'SKILLS',
      state: true
    },
    className: 'skills',
    hasIcon: false,
    pl: 'Skill',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.SKILLS])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.TEXT,
        hasIcon: false,
        chipValue: '',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Skills',
      icon: ''
    },
  },
  [TemplateSectionTypes.SOFT_SKILLS]: {
    type: TemplateSectionTypes.SOFT_SKILLS,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'SOFT SKILLS',
      pl: 'SOFT SKILLS',
      state: true
    },
    className: 'soft-skills',
    hasIcon: false,
    pl: 'Soft Skill',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.SOFT_SKILLS])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.TEXT,
        hasIcon: false,
        chipValue: '',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Soft Skills',
      icon: ''
    }
  },
  [TemplateSectionTypes.LANGUAGES]: {
    type: TemplateSectionTypes.LANGUAGES,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'LANGUAGES',
      pl: 'LANGUAGES',
      state: true
    },
    className: 'lng',
    hasIcon: false,
    pl: 'Language',
    infographics: {
      state: true,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.LANGUAGES])],
    settings: {
      hasTextOptions: true,
      selectedShape: {
        type: TemplateSettingsShapes.INFOGRAPHICS,
        hasIcon: false,
        chipValue: '',
        infographicValue: 'infographic-round',
      },
      listTypeOnSidebar: 'list'
    },
    sectionInfo: {
      name: 'Languages',
      icon: ''
    },
  },
  [TemplateSectionTypes.INTERESTS]: {
    type: TemplateSectionTypes.INTERESTS,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'INTERESTS',
      pl: 'INTERESTS',
      state: true
    },
    className: 'interests',
    hasIcon: false,
    pl: 'Sport',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.INTERESTS])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.TEXT,
        hasIcon: true,
        chipValue: '',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Interests',
      icon: ''
    },
  },
  [TemplateSectionTypes.CAUSES]: {
    type: TemplateSectionTypes.CAUSES,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'CAUSES',
      pl: 'CAUSES',
      state: true
    },
    className: 'causes',
    hasIcon: false,
    pl: 'Cause',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CAUSES])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.CHIPS,
        hasIcon: false,
        chipValue: 'bg-color-full',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Causes',
      icon: ''
    }
  },
  [TemplateSectionTypes.AREAS_OF_EXPERTISE]: {
    type: TemplateSectionTypes.AREAS_OF_EXPERTISE,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'AREAS OF EXPERTISE',
      pl: 'AREAS OF EXPERTISE',
      state: true
    },
    className: 'areas-of-expertise',
    hasIcon: false,
    pl: 'Email Marketing',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.AREAS_OF_EXPERTISE])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.CHIPS,
        hasIcon: true,
        chipValue: 'chip-initial',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Areas of Expertise',
      icon: ''
    },
  },

  [TemplateSectionTypes.WORK_EXPERIENCE]: {
    type: TemplateSectionTypes.WORK_EXPERIENCE,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'WORK EXPERIENCE',
      pl: 'WORK EXPERIENCE',
      state: true
    },
    className: 'work-experience',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.WORK_EXPERIENCE]),
    ],
    sectionInfo: {
      name: 'Work Experience',
      icon: ''
    }
  },
  [TemplateSectionTypes.EDUCATION]: {
    type: TemplateSectionTypes.EDUCATION,
    className: 'education',
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'EDUCATION',
      pl: 'EDUCATION',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.EDUCATION]),
    ],
    sectionInfo: {
      name: 'Education',
      icon: ''
    }
  },
  [TemplateSectionTypes.TEACHING]: {
    type: TemplateSectionTypes.TEACHING,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'TEACHING EXPERIENCE',
      pl: 'TEACHING EXPERIENCE',
      state: true
    },
    className: 'teaching',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.TEACHING]),
    ],
    sectionInfo: {
      name: 'Teaching',
      icon: ''
    }
  },
  [TemplateSectionTypes.VOLUNTEER]: {
    type: TemplateSectionTypes.VOLUNTEER,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'VOLUNTEER EXPERIENCE',
      pl: 'VOLUNTEER EXPERIENCE',
      state: true
    },
    className: 'volunteer',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.VOLUNTEER]),
    ],
    sectionInfo: {
      name: 'Volunteer',
      icon: ''
    }
  },

  [TemplateSectionTypes.AWARDS]: {
    type: TemplateSectionTypes.AWARDS,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'AWARDS',
      pl: 'AWARDS',
      state: true
    },
    className: 'awards',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.AWARDS]),
    ],
    sectionInfo: {
      name: 'Awards',
      icon: ''
    }
  },

  [TemplateSectionTypes.CONFERENCES_AND_COURSES]: {
    type: TemplateSectionTypes.CONFERENCES_AND_COURSES,
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'CONFERENCES & COURSES',
      pl: 'CONFERENCES & COURSES',
      state: true
    },
    className: 'conferences-and-courses',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CONFERENCES_AND_COURSES]),
    ],
    sectionInfo: {
      name: 'Conf... & Courses',
      icon: ''
    }
  },
  [TemplateSectionTypes.ACHIEVEMENTS]: {
    type: TemplateSectionTypes.ACHIEVEMENTS,
    className: 'achievements',
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'ACHIEVEMENTS',
      pl: 'ACHIEVEMENTS',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.ACHIEVEMENTS]),
    ],
    sectionInfo: {
      name: 'Achievements',
      icon: ''
    }
  },
  [TemplateSectionTypes.CERTIFICATES]: {
    type: TemplateSectionTypes.CERTIFICATES,
    className: 'certificates',
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'CERTIFICATES',
      pl: 'CERTIFICATES',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CERTIFICATES]),
    ],
    sectionInfo: {
      name: 'Certificates',
      icon: ''
    }
  },
  [TemplateSectionTypes.ORGANIZATIONS]: {
    type: TemplateSectionTypes.ORGANIZATIONS,
    className: 'organizations',
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'ORGANIZATIONS',
      pl: 'ORGANIZATIONS',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.ORGANIZATIONS]),
    ],
    sectionInfo: {
      name: 'Organizations',
      icon: ''
    }
  },

  /*[TemplateSectionTypes.REFERENCES]: {
    type: TemplateSectionTypes.REFERENCES,
    className: 'references',
    title: {
      text: 'REFERENCES',
      pl: 'REFERENCES',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.REFERENCES]),
    ],
    sectionInfo: {
      name: 'References',
      icon: ''
    }
  },*/
};

export const allSectionsDefaults2: Readonly<AllSectionsDefaults> = {
  [TemplateSectionTypes.SKILLS]: {
    type: TemplateSectionTypes.SKILLS,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-skills'
      },
      text: 'SKILLS',
      pl: 'SKILLS',
      state: true
    },
    className: 'skills',
    hasIcon: false,
    pl: 'Skill',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.SKILLS])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.TEXT,
        hasIcon: false,
        chipValue: '',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Skills',
      icon: ''
    },
  },
  [TemplateSectionTypes.SOFT_SKILLS]: {
    type: TemplateSectionTypes.SOFT_SKILLS,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-skills'
      },
      text: 'SOFT SKILLS',
      pl: 'SOFT SKILLS',
      state: true
    },
    className: 'soft-skills',
    hasIcon: false,
    pl: 'Soft Skill',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.SOFT_SKILLS])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.TEXT,
        hasIcon: false,
        chipValue: '',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Soft Skills',
      icon: ''
    }
  },
  [TemplateSectionTypes.LANGUAGES]: {
    type: TemplateSectionTypes.LANGUAGES,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-lng'
      },
      text: 'LANGUAGES',
      pl: 'LANGUAGES',
      state: true
    },
    className: 'lng',
    hasIcon: false,
    pl: 'Language',
    infographics: {
      state: true,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.LANGUAGES])],
    settings: {
      hasTextOptions: true,
      selectedShape: {
        type: TemplateSettingsShapes.INFOGRAPHICS,
        hasIcon: false,
        chipValue: '',
        infographicValue: 'infographic-round',
      },
      listTypeOnSidebar: 'list'
    },
    sectionInfo: {
      name: 'Languages',
      icon: ''
    },
  },
  [TemplateSectionTypes.INTERESTS]: {
    type: TemplateSectionTypes.INTERESTS,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-interests'
      },
      text: 'INTERESTS',
      pl: 'INTERESTS',
      state: true
    },
    className: 'interests',
    hasIcon: false,
    pl: 'Sport',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.INTERESTS])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.TEXT,
        hasIcon: true,
        chipValue: '',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Interests',
      icon: ''
    },
  },
  [TemplateSectionTypes.CAUSES]: {
    type: TemplateSectionTypes.CAUSES,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-causes'
      },
      text: 'CAUSES',
      pl: 'CAUSES',
      state: true
    },
    className: 'causes',
    hasIcon: false,
    pl: 'Cause',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CAUSES])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.CHIPS,
        hasIcon: false,
        chipValue: 'bg-color-full',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Causes',
      icon: ''
    }
  },
  [TemplateSectionTypes.AREAS_OF_EXPERTISE]: {
    type: TemplateSectionTypes.AREAS_OF_EXPERTISE,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-ar-of-exp'
      },
      text: 'AREAS OF EXPERTISE',
      pl: 'AREAS OF EXPERTISE',
      state: true
    },
    className: 'areas-of-expertise',
    hasIcon: false,
    pl: 'Email Marketing',
    infographics: {
      state: false,
      type: TemplateInfographics.ROUND,
    },
    items: [cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.AREAS_OF_EXPERTISE])],
    settings: {
      selectedShape: {
        type: TemplateSettingsShapes.CHIPS,
        hasIcon: true,
        chipValue: 'chip-initial',
        infographicValue: '',
      },
      listTypeOnSidebar: 'wrap'
    },
    sectionInfo: {
      name: 'Areas of Expertise',
      icon: ''
    },
  },

  [TemplateSectionTypes.WORK_EXPERIENCE]: {
    type: TemplateSectionTypes.WORK_EXPERIENCE,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-work-exp'
      },
      text: 'WORK EXPERIENCE',
      pl: 'WORK EXPERIENCE',
      state: true
    },
    className: 'work-experience',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.WORK_EXPERIENCE]),
    ],
    sectionInfo: {
      name: 'Work Experience',
      icon: ''
    }
  },
  [TemplateSectionTypes.EDUCATION]: {
    type: TemplateSectionTypes.EDUCATION,
    className: 'education',
    title: {
      icon: {
        state: true,
        name: 'icon-sf-education'
      },
      text: 'EDUCATION',
      pl: 'EDUCATION',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.EDUCATION]),
    ],
    sectionInfo: {
      name: 'Education',
      icon: ''
    }
  },
  [TemplateSectionTypes.TEACHING]: {
    type: TemplateSectionTypes.TEACHING,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-teaching'
      },
      text: 'TEACHING EXPERIENCE',
      pl: 'TEACHING EXPERIENCE',
      state: true
    },
    className: 'teaching',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.TEACHING]),
    ],
    sectionInfo: {
      name: 'Teaching',
      icon: ''
    }
  },
  [TemplateSectionTypes.VOLUNTEER]: {
    type: TemplateSectionTypes.VOLUNTEER,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-volunteer'
      },
      text: 'VOLUNTEER EXPERIENCE',
      pl: 'VOLUNTEER EXPERIENCE',
      state: true
    },
    className: 'volunteer',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.VOLUNTEER]),
    ],
    sectionInfo: {
      name: 'Volunteer',
      icon: ''
    }
  },

  [TemplateSectionTypes.AWARDS]: {
    type: TemplateSectionTypes.AWARDS,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-awards'
      },
      text: 'AWARDS',
      pl: 'AWARDS',
      state: true
    },
    className: 'awards',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.AWARDS]),
    ],
    sectionInfo: {
      name: 'Awards',
      icon: ''
    }
  },

  [TemplateSectionTypes.CONFERENCES_AND_COURSES]: {
    type: TemplateSectionTypes.CONFERENCES_AND_COURSES,
    title: {
      icon: {
        state: true,
        name: 'icon-sf-conf-and-cours'
      },
      text: 'CONFERENCES & COURSES',
      pl: 'CONFERENCES & COURSES',
      state: true
    },
    className: 'conferences-and-courses',
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CONFERENCES_AND_COURSES]),
    ],
    sectionInfo: {
      name: 'Conf... & Courses',
      icon: ''
    }
  },
  [TemplateSectionTypes.ACHIEVEMENTS]: {
    type: TemplateSectionTypes.ACHIEVEMENTS,
    className: 'achievements',
    title: {
      icon: {
        state: true,
        name: 'icon-sf-achievement'
      },
      text: 'ACHIEVEMENTS',
      pl: 'ACHIEVEMENTS',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.ACHIEVEMENTS]),
    ],
    sectionInfo: {
      name: 'Achievements',
      icon: ''
    }
  },
  [TemplateSectionTypes.CERTIFICATES]: {
    type: TemplateSectionTypes.CERTIFICATES,
    className: 'certificates',
    title: {
      icon: {
        state: true,
        name: 'icon-sf-publications'
      },
      text: 'CERTIFICATES',
      pl: 'CERTIFICATES',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.CERTIFICATES]),
    ],
    sectionInfo: {
      name: 'Certificates',
      icon: ''
    }
  },
  [TemplateSectionTypes.ORGANIZATIONS]: {
    type: TemplateSectionTypes.ORGANIZATIONS,
    className: 'organizations',
    title: {
      icon: {
        state: true,
        name: 'icon-sf-prof-org'
      },
      text: 'ORGANIZATIONS',
      pl: 'ORGANIZATIONS',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.ORGANIZATIONS]),
    ],
    sectionInfo: {
      name: 'Organizations',
      icon: ''
    }
  },

  /*[TemplateSectionTypes.REFERENCES]: {
    type: TemplateSectionTypes.REFERENCES,
    className: 'references',
    title: {
      icon: {
        state: true,
        name: 'icon-sf-references'
      },
      text: 'REFERENCES',
      pl: 'REFERENCES',
      state: true
    },
    items: [
      cloneObject(allSectionsItemsDefaults[TemplateSectionTypes.REFERENCES]),
    ],
    sectionInfo: {
      name: 'References',
      icon: ''
    }
  },*/
};

export const templateBranding: TemplateBranding = {
  link: 'https://selfcv.com/',
  enabled: true,
  paid: true
};

export const socialInfo: Readonly<TemplateSocialInfo> = {
  title: {
    icon: {
      state: false,
      name: ''
    },
    text: 'CONTACTS',
    pl: 'CONTACTS',
    state: true
  },
  onSidebar: false,
  className: 'social-info-sidebar',
  items: [
    {
      type: TmSocialInfos.EMAIL,
      icon: 'ph ph-envelope',
      text: '',
      pl: 'john.doe@gmail.com',
      state: true,
    },
    {
      type: TmSocialInfos.PHONE,
      icon: 'ph ph-phone',
      text: '',
      pl: '202-555-0166',
      state: true
    },
    {
      type: TmSocialInfos.CITY,
      icon: 'ph ph-globe-hemisphere-east',
      text: '',
      pl: 'New York, USA',
      state: true
    },
    {
      type: TmSocialInfos.ADDRESS,
      icon: 'ph ph-map-pin',
      text: '',
      pl: 'Broadway 10012'
    },
    {
      type: TmSocialInfos.LINKEDIN,
      icon: 'ph ph-linkedin-logo',
      text: '',
      pl: 'John.linkedin.com',
      state: true,
      link: true,
      settings: {
        shorten: false,
        shortenName: 'linkedin.com'
      }
    },
    {
      type: TmSocialInfos.BIRTHDAY,
      icon: 'ph ph-calendar-blank',
      text: '',
      pl: '01/02/2020'
    },
    {
      type: TmSocialInfos.LINK,
      icon: 'ph ph-link',
      text: '',
      pl: 'John.blog.com',
      link: true,
      settings: {
        shorten: false,
        shortenName: 'https://www.link.com'
      }
    },
    {
      type: TmSocialInfos.FACEBOOK,
      icon: 'ph ph-facebook-logo',
      text: '',
      pl: 'John.facebook.com',
      link: true,
      settings: {
        shorten: false,
        shortenName: 'facebook.com'
      }
    },
    {
      type: TmSocialInfos.STACKOVERFLOW,
      icon: 'ph ph-stack-overflow-logo',
      text: '',
      pl: 'John.Stackoverflow.com',
      link: true,
      settings: {
        shorten: false,
        shortenName: 'stackoverflow.com'
      }
    },
    {
      type: TmSocialInfos.SKYPE,
      icon: 'ri-skype-line',
      text: '',
      pl: 'John.skype.com',
    },
    {
      type: TmSocialInfos.INSTAGRAM,
      icon: 'ph ph-instagram-logo',
      text: '',
      pl: 'John.instagram.com',
      link: true,
      settings: {
        shorten: false,
        shortenName: 'instagram.com'
      }
    },
    // {
    //   icon: 'quora',
    //   text: '',
    //   pl: 'John.quora.com',
    //   settings: {
    //     shorten: false,
    //     shortenName: 'quora.com'
    //   }
    // },
    {
      type: TmSocialInfos.GITHUB,
      icon: 'ph ph-github-logo',
      text: '',
      pl: 'John.github.com',
      link: true,
      settings: {
        shorten: false,
        shortenName: 'github.com'
      }
    },
    {
      type: TmSocialInfos.MEDIUM,
      icon: 'ph ph-medium-logo',
      text: '',
      pl: 'John.medium.com',
      link: true,
      settings: {
        shorten: false,
        shortenName: 'medium.com'
      }
    },
  ]
};

export const templateImg: TemplateImage = {
  src: '',
  state: true,
  settings: {
    selectedShape: {
      name: 'img',
      value: 'initial',
      premium: false
    }
  }
};
