import {FormsModule} from '@angular/forms';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {AppStateService} from '../../services';
import {Component, inject} from '@angular/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {TranslateService} from "@ngx-translate/core";
import {NgForOf, NgOptimizedImage} from '@angular/common';
import {projectMessages} from "@app/shared/constants/project-messages";
import {DropdownComponent} from "@app/shared/ui-kits/dropdown/dropdown.component";
import {ProjectSocialIconsComponent} from "@app/shared/ui-kits/project-social-icons/project-social-icons.component";

interface LgEvent {
  icon: string;
  key: string;
  name: string;
}

@Component({
  selector: 'sf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    NgForOf,
    RouterLink,
    FormsModule,
    NzIconModule,
    NzSelectModule,
    NgOptimizedImage,
    DropdownComponent,
    ProjectSocialIconsComponent,
    RouterLinkActive,
  ],
  standalone: true,
})
export class FooterComponent {
  public readonly appStateService = inject(AppStateService);
  public readonly translateService = inject(TranslateService);

  protected readonly projectMessages = projectMessages;

  public changeLanguage(event: LgEvent) {
    this.translateService.use(event.key);
  }

  protected sendFeedback() {
    this.appStateService.sendFeedback$.next();
  }

}
