import {finalize} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {NzModalService} from "ng-zorro-antd/modal";
import {ImageCropComponent} from "@app/shared/modals";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {TmSettings} from "@app/shared/types/template-types";
import {ClickOutsideDirective} from "@app/shared/directives";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Component, DestroyRef, inject, Input} from '@angular/core';
import {AppStateService, TemplateService} from "@app/shared/services";
import {NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";

@Component({
  selector: 'sf-template-img',
  templateUrl: './template-img.component.html',
  styleUrls: ['./template-img.component.scss'],
  imports: [
    NgForOf,
    NgIf,
    ClickOutsideDirective,
    NgStyle,
    NgOptimizedImage,
    NzTooltipDirective
  ],
  standalone: true
})
export class TemplateImgComponent {
  private readonly destroyRef = inject(DestroyRef);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly nzModalService = inject(NzModalService);
  protected readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);

  @Input({required: true}) public template!: TmSettings;
  @Input({required: true}) public stroke!: string;

  protected readonly imgBorderShapes: { className: string }[] = [
    {className: 'round'},
    {className: 'initial'},
    {className: 'left-top'},
    {className: 'right-top'},
    {className: 'left-bottom'},
    {className: 'right-bottom'},
  ];
  protected settingsSelected = false;
  protected imgBannerState = false;

  protected readonly Date = new Date();

  constructor() {
  }

  protected onSelectImage(file: Event) {
    const modalRef = this.nzModalService.create({
      nzTitle: 'Image Upload',
      nzContent: ImageCropComponent,
      nzCentered: true,
      nzFooter: null
    });

    const component = modalRef.componentInstance;
    component!.imageChangedEvent = file;

    modalRef.afterClose.subscribe(() => {
      (file.target as HTMLInputElement).value = '';
    });

    component?.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        modalRef.close();
        this.updateTemplateImage(res.blob as Blob, component);
      });
  }

  private updateTemplateImage(blob: Blob, component: ImageCropComponent) {
    const documentId = this.activatedRoute.snapshot.params['documentId'];
    this.templateService.updateTemplateImage(documentId, blob)
      .pipe(
        finalize(() => component.loadingState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        this.template.img.src = res.src;
        this.saveChanges();
      });
  }

  protected onUploadFile(fileInputRef: HTMLInputElement) {
    setTimeout(() => fileInputRef.click());
  }

  protected openImgSettings() {
    this.settingsSelected = !this.settingsSelected;
  }

  protected onSelectImgShape(shape: { className: string }) {
    this.template.img.settings.selectedShape.value = shape.className;
    this.saveChanges();
    this.appStateService.upgradeBannerState$.next();
  }

  protected onClickOutsideImg() {
    this.imgBannerState = false;
    this.settingsSelected = false;
  }

  protected onRemoveTemplateImage() {
    this.template.img.state = false;
    this.onClickOutsideImg();
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}

