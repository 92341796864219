<div
  class="sf-cover-letter-template template-id-{{template.templateId}}"
  (sfClickOutside)="onClickOutSide()"
  [ngStyle]="{
  color: coverLetterTheme.fontColor,
  'font-family': template.settings.settings.font.type.fontFamily
  }"
  [style.--tm-cover-link-color]="coverLetterTheme.linkColor"
  [style.--before-after-color]="coverLetterTheme.demoColor2">

  <div *ngIf="selectedItem" [@fadeInOut] class="template-overlay" (click)="onOverlayClick()"></div>

  <ng-container [ngTemplateOutlet]="TemplateTopPartRef"/>

  <div class="body-part">

    <div class="body-part-first-child">
      <div class="body-part-second-child">
        @if (template.settings.companyDetails.state) {
          <div
            class="to-whom"
            [class.edit-mode]="selectedItem === template.settings.companyDetails"
            (click)="onSelect(template.settings.companyDetails)">

            <ng-container
              *ngIf="selectedItem === template.settings.companyDetails"
              [ngTemplateOutlet]="ActionsBannerRef"
              [ngTemplateOutletContext]="{data: template.settings.companyDetails}"
            ></ng-container>

            <ul
              [ngStyle]="{
                 fontSize: appStateService.coverCurrentFontSize.sectionContent + 'px',
                 lineHeight: appStateService.coverCurrentFontSize.sectionContentLineHeight + 'px',
              }">
              @if (template.settings.companyDetails.employer.state ||
              template.settings.companyDetails.name.state ||
              template.settings.companyDetails.address.state ||
              template.settings.companyDetails.zip.state) {
                <li class="mb-8">To</li>
              }

              <li
                *ngIf="template.settings.companyDetails.employer.state"
                contenteditable="true"
                [innerHTML]="template.settings.companyDetails.employer.text"
                [attr.placeholder]="template.settings.companyDetails.employer.pl"
                (keydown.enter)="$event.preventDefault()"
                (blur)="onBlurText($event, template.settings.companyDetails.employer)"
                (focus)="onFocusEL($event, template.settings.companyDetails.employer.text)"
                [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterTo"
              ></li>

              <li
                *ngIf="template.settings.companyDetails.name.state"
                contenteditable="true"
                [innerHTML]="template.settings.companyDetails.name.text"
                [attr.placeholder]="template.settings.companyDetails.name.pl"
                (keydown.enter)="$event.preventDefault()"
                (blur)="onBlurText($event, template.settings.companyDetails.name)"
                (focus)="onFocusEL($event, template.settings.companyDetails.name.text)"
                [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterTo"
              ></li>

              <li
                *ngIf="template.settings.companyDetails.address.state"
                contenteditable="true"
                [innerHTML]="template.settings.companyDetails.address.text"
                [attr.placeholder]="template.settings.companyDetails.address.pl"
                (keydown.enter)="$event.preventDefault()"
                (blur)="onBlurText($event, template.settings.companyDetails.address)"
                (focus)="onFocusEL($event, template.settings.companyDetails.address.text)"
                [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterTo"
              ></li>

              <li
                *ngIf="template.settings.companyDetails.zip.state"
                contenteditable="true"
                [innerHTML]="template.settings.companyDetails.zip.text"
                [attr.placeholder]="template.settings.companyDetails.zip.pl"
                (keydown.enter)="$event.preventDefault()"
                (blur)="onBlurText($event, template.settings.companyDetails.zip)"
                (focus)="onFocusEL($event, template.settings.companyDetails.zip.text)"
                [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterTo"
              ></li>

              <li *ngIf="template.settings.companyDetails.date.state">
                <nz-date-picker
                  class="{{'date-picker-fs-' + appStateService.coverCurrentFontSize.sectionContent}}"
                  (click)="onSelect(template.settings.companyDetails)"
                  style="padding: 0"
                  nzBorderless
                  [nzFormat]="'dd MMMM, YYYY'"
                  [nzSuffixIcon]="''"
                  [(ngModel)]="template.settings.companyDetails.date.text"
                  [nzAllowClear]="false"
                ></nz-date-picker>
              </li>
            </ul>
          </div>

          <nz-divider></nz-divider>
        }

        <div
          class="cover-content"
          [ngStyle]="{
              fontSize: appStateService.coverCurrentFontSize.sectionContent + 'px',
              lineHeight: appStateService.coverCurrentFontSize.sectionContentLineHeight + 'px',
          }">
          <div
            class="mr-mrs"
            contenteditable="true"
            [innerHTML]="template.settings.content.recipient.text"
            [attr.placeholder]="template.settings.content.recipient.pl"
            [class.edit-mode]="selectedItem === template.settings.content.recipient"
            (click)="onSelect(template.settings.content.recipient)"
            [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterRecipient"
            (keydown.enter)="$event.preventDefault()"
            (blur)="onBlurText($event, template.settings.content.recipient)"
            (focus)="onFocusEL($event, template.settings.content.recipient.text)"
          ></div>

          <div class="p-relative">
            <sf-tm-editor *ngIf="selectedItem === template.settings.content.content"/>

            <div
              class="c-content"
              contenteditable="true"
              [innerHTML]="template.settings.content.content.text"
              [attr.placeholder]="template.settings.content.content.pl"
              [class.edit-mode]="selectedItem === template.settings.content.content"

              (click)="onSelect(template.settings.content.content)"
              (blur)="onBlurText($event, template.settings.content.content)"
              (focus)="onFocusEL($event, template.settings.content.content.text)"
              (mouseup)="handleMouseUp(template.settings.content.content)"
              (sfCtrlA)="handleCtrlA(template.settings.content.content)"
              (keydown.enter)="onEnterDesc($event)"
              [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterContent"
            ></div>
          </div>

          <div class="from-me" *ngIf="template.settings.content.sender.state">
            <div class="tm-st-banner c-details" *ngIf="selectedItem === template.settings.content.sender">
              <i
                class="ph ph-trash-simple"
                nz-popconfirm="Are you sure delete this section?"
                (click)="template.settings.content.sender.state = false"
              ></i>
            </div>

            <div
              class="cover-sender"
              contenteditable="true"
              [innerHTML]="template.settings.content.sender.text"
              [attr.placeholder]="template.settings.content.sender.pl"
              [class.edit-mode]="selectedItem === template.settings.content.sender"
              (click)="onSelect(template.settings.content.sender)"
              (keydown.enter)="$event.preventDefault()"
              (blur)="onBlurText($event, template.settings.content.sender)"
              (focus)="onFocusEL($event, template.settings.content.sender.text)"
              [sfContentEditableMaxLength]="appStateService.textMaxSizes.coverLetterTo"
            ></div>

            <div class="cover-receiver">{{ template.settings.title.text }}</div>
          </div>
        </div>

      </div>
    </div>

    <sf-branding [forPrint]="forPrint" [tmTheme]="coverLetterTheme" [template]="template.settings"/>
  </div>
</div>


<!--------------------------------------------------------------------------------------------------------------------->


<!-- Template Top Parts Start -->

<ng-template #TemplateTopPartRef>
  <ng-container [ngSwitch]="template.settings.templateTopPartType">
    <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="Type_1_Ref"/>
    <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="Type_2_Ref"/>
    <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="Type_3_Ref"/>
    <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="Type_4_Ref"/>
    <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="Type_5_Ref"/>
    <ng-container *ngSwitchCase="6" [ngTemplateOutlet]="Type_6_Ref"/>
    <ng-container *ngSwitchCase="7" [ngTemplateOutlet]="Type_7_Ref"/>
  </ng-container>
</ng-template>

<!-- Template Top Parts End -->


<!--------------------------------------------------------------------------------------------------------------------->

<ng-template #DrawerTitleRef>
  <div class="dashboard-sidebar-title">
    Social Info Settings
    <i nz-icon nzType="close" nzTheme="outline" (click)="onCloseSidebar()"></i>
  </div>
</ng-template>

<ng-template #ActionsBannerRef let-data="data">
  <div class="tm-st-banner c-details">
    <i class="ph ph-gear" [class.active]="sectionSettingsSelected" (click)="onselectSectionSettings($event)"></i>

    <div
      class="tm-st-banner-settings work-exp"
      style="right: -220px"
      *ngIf="sectionSettingsSelected"
      [sfCapture]="true"
      (sfClickOutside)="onClickOutsideWorkExpSection()">
      <div class="checkboxes-container">
        <div class="sf-checkbox pink" *ngFor="let item of defaults">
          <input
            type="checkbox"
            [id]="'set-checkbox' + item.keyName"
            [(ngModel)]="$any(template.settings.companyDetails)[item.keyName].state"
            (ngModelChange)="checkboxStateChanged()">
          <label [for]="'set-checkbox' + item.keyName">{{ item.name }}</label>
        </div>
      </div>
    </div>

    <i
      class="ph ph-trash-simple"
      nz-popconfirm="Are you sure delete this section?"
      (click)="onRemoveSectionItem(data)"
    ></i>

  </div>
</ng-template>

<!-- TM TOP PARTS -->

<ng-template #Type_1_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-1"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <sf-tm-name-part
          [template]="template.settings"
          [tmTheme]="coverLetterTheme"
          [selectedItem]="selectedItem"
          (selectedItemChange)="onSelect($event)"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_2_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-2"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <sf-tm-name-part
          [template]="template.settings"
          [tmTheme]="coverLetterTheme"
          [selectedItem]="selectedItem"
          (selectedItemChange)="onSelect($event)"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_3_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-3"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <sf-tm-name-part
          [template]="template.settings"
          [tmTheme]="coverLetterTheme"
          [selectedItem]="selectedItem"
          (selectedItemChange)="onSelect($event)"/>

        <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
      </div>

      <ng-container [ngTemplateOutlet]="ImgRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_4_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-4 {{template.settings.img.state ? 'has-image' : ''}}"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <sf-tm-name-part
          [template]="template.settings"
          [tmTheme]="coverLetterTheme"
          [selectedItem]="selectedItem"
          (selectedItemChange)="onSelect($event)"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_5_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-5"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <div class="w-full">
          <sf-tm-name-part
            [template]="template.settings"
            [tmTheme]="coverLetterTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"/>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_6_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-6"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <div class="w-full">
          <sf-tm-name-part
            [template]="template.settings"
            [tmTheme]="coverLetterTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"/>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_7_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-7"
    [ngStyle]="{
       backgroundColor: coverLetterTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <sf-tm-name-part
          [template]="template.settings"
          [tmTheme]="coverLetterTheme"
          [selectedItem]="selectedItem"
          (selectedItemChange)="onSelect($event)"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef2"/>
    </div>

    <ng-container [ngTemplateOutlet]="SocialInfoRef3"/>
  </div>
</ng-template>


<!-- -------------------------------------------------------------- -->

<ng-template #SocialInfoRef>
  <div
    class="social-info"
    (click)="openSocialInfoSettings($event)"
    [ngStyle]="{
        backgroundColor: coverLetterTheme.socialInfo.bgColor,
        color: coverLetterTheme.socialInfo.fontColor,
      }">
    <ul>
      @for (item of template.settings.socialInfo.items; track $index) {
        @if (item.state) {
          @if (item.link && forPrint) {
            <a [href]="item.text || 'https://www.' + item.settings!.shortenName" target="_blank">
              <li
                [ngStyle]="{
                 fontSize: appStateService.coverCurrentFontSize.socialInfo + 'px',
                 lineHeight: appStateService.coverCurrentFontSize.socialInfoLineHeight + 'px',
              }">
                <i class="{{item.icon}}"></i>
                {{ item?.settings?.shorten ? item.settings!.shortenName : item.text || item.pl }}
              </li>
            </a>
          } @else {
            <li
              [ngStyle]="{
                 fontSize: appStateService.coverCurrentFontSize.socialInfo + 'px',
                 lineHeight: appStateService.coverCurrentFontSize.socialInfoLineHeight + 'px',
              }">
              <i class="{{item.icon}}"></i>
              {{ item?.settings?.shorten ? item.settings!.shortenName : item.text || item.pl }}
            </li>
          }

        }
      }
    </ul>
  </div>
</ng-template>

<ng-template #SocialInfoRef2>
  @if (enabledSocialItemsFirst().length) {
    <div
      class="social-info on-side"
      (click)="openSocialInfoSettings($event)"
      [ngStyle]="{
        color: coverLetterTheme.socialInfo.fontColor,
      }">
      <ul>
        @for (item of enabledSocialItemsFirst(); track $index) {
          <li
            [ngStyle]="{
            fontSize: appStateService.coverCurrentFontSize.socialInfo + 'px',
            lineHeight: appStateService.coverCurrentFontSize.socialInfoLineHeight + 'px',
          }">
            <i class="{{item.icon}}"></i>
            {{ item?.settings?.shorten ? item.settings!.shortenName : item.text || item.pl }}
          </li>
        }
      </ul>
    </div>
  }
</ng-template>

<ng-template #SocialInfoRef3>
  @if (enabledSocialItemsLast().length) {
    <div
      class="social-info mb-10 mt-0"
      (click)="openSocialInfoSettings($event)"
      [ngStyle]="{
        backgroundColor: coverLetterTheme.socialInfo.bgColor,
        color: coverLetterTheme.socialInfo.fontColor,
      }">
      <ul>
        @for (item of enabledSocialItemsLast(); track $index) {
          <li
            *ngIf="item.state"
            [ngStyle]="{
             backgroundColor: coverLetterTheme.socialInfo.bgColor,
             color: coverLetterTheme.socialInfo.fontColor,
          }">
            <i class="{{item.icon}}"></i>
            {{ item?.settings?.shorten ? item.settings!.shortenName : item.text || item.pl }}
          </li>
        }
      </ul>
    </div>
  }
</ng-template>

<ng-template #ImgRef>
  @if (template.settings.img.state) {
    @if (!forPrint || (forPrint && (appStateService.user?.image || template.settings.img.src))) {
      <sf-template-img [stroke]="coverLetterTheme.emptyImgColor" [template]="template.settings"/>
    }
  }
</ng-template>

