import {Observable} from 'rxjs';
import {environment} from "@env";
import {Injectable} from '@angular/core';
import {httpContextDefaults} from "@app/shared/constants";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable()
export class UrlPrefixInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = req.context.get(httpContextDefaults);

    /** For languages json (see assets file) */
    if (req.url.includes('.json')) {
      return next.handle(req);
    }

    if (params.skipPrefix) {
      return next.handle(req);
    }

    req = req.clone({url: `${environment.endpoint}/${req.url}`});
    return next.handle(req);
  }

}
