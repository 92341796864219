<div class="paid-feature-banner">
  <div class="d-flex ai-c">
    You are using
    <strong class="fw-600 ml-2">premium features</strong>!
    <i class="ph ph-info ml-6" nz-tooltip="To download your professional resume, a premium account is necessary."></i>
  </div>

  <div class="d-flex">
    <button (click)="onUpgrade()" class="mr-10">Upgrade</button>
    <button class="icon-bnt" (click)="onSeeMore()" nz-tooltip="See more">
      <i class="ph ph-caret-up"></i>
    </button>
  </div>
</div>

<ng-template #SelectPaidFeaturesRef let-data let-drawerRef="drawerRef">
  <div class="selected-paid-features">
    <ul class="paid-features-list">
      @for (feature of selectedPaidFeatures; track feature.key) {
        @if (feature.state) {
          <li>
            <div class="d-flex al-c jc-sb">
              <div class="feature-title">
                {{ feature.title }}
                @if (feature.infoMessage) {
                  <i class="ph ph-info ml-6" nz-popover [nzPopoverContent]="feature.infoMessage"></i>
                }
              </div>
              @if (feature.revertible) {
                <button class="sf-btn-bg-none w-100" (click)="feature.action?.()">Revert</button>
              }
            </div>
          </li>
        }
      }
    </ul>
  </div>
</ng-template>

<ng-template #PlansDrawerTitleRef>
  <div class="dashboard-sidebar-title">
    Premium Plans
    <i nz-icon nzType="close" nzTheme="outline" (click)="onCloseDrawer()"></i>
  </div>
</ng-template>

<ng-template #FeaturesDrawerTitleRef>
  <div class="dashboard-sidebar-title paid-banner-title">
    <div class="d-flex ai-c">
      Premium Features
      <i class="ph ph-star ml-8" nz-tooltip="Premium fearutes marked with star."></i>
    </div>

    <div class="d-flex">
      <button (click)="onUpgrade()" class="mr-10">Upgrade</button>
      <button class="icon-bnt" (click)="onCloseDrawer()">
        <i class="ph ph-caret-down"></i>
      </button>
    </div>
  </div>
</ng-template>
