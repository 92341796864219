import {ToFixedPipe} from "@app/shared/pipes";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzMessageModule} from "ng-zorro-antd/message";
import {AppStateService} from "@app/shared/services";
import {Component, inject, Input} from '@angular/core';
import {DatePipe, DecimalPipe, NgForOf} from "@angular/common";
import {PaymentBannerComponent, PremiumPlansComponent} from "@app/shared/ui-kits";

@Component({
  selector: 'sf-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss'],
  imports: [
    NgForOf,
    NzDrawerModule,
    NzModalModule,
    NzMessageModule,
    DatePipe,
    PaymentBannerComponent,
    DecimalPipe,
    ToFixedPipe,
    PremiumPlansComponent
  ],
  standalone: true
})
export class ChoosePlanComponent {
  protected readonly appStateService = inject(AppStateService);

  @Input() public title = 'Plans';
}
