<ng-template #TmPreviewTitleRef>
  <div class="d-flex fd-rr template-prev-title">
    <button class="sf-btn-fifth small" (click)="onSelectTemplate()">
      <span class="mr-8">Choose Template</span>
      <i class="ph ph-sign-in"></i>
    </button>

    @if (!template.settings.pureTemplate) {
      <nz-divider class="tm-list-preview" nzType="vertical"/>

      <div class="tm-color on-tm-preview d-flex">
        <div class="tm-ds-title"></div>

        <div class="tm-colors-list">
          @for (color of templateColors; let first = $first; track color.id) {
            <div
              [class.active]="color.id === selectedColor?.id"
              class="tm-color-item h-32 w-32 theme-id-{{color.id}}"
              (click)="onSelectTheme(color)">
              <div [style.background-color]="color.demoColor1"></div>
              <div [style.background-color]="color.demoColor2"></div>

              @if (color.paid) {
                <i class="ph-fill ph-star" nz-tooltip="Paid" [nzTooltipMouseEnterDelay]="1"></i>
              }
            </div>
          }
        </div>
      </div>
    }

    @if (template.documentType === DocumentTypes.CV) {
      <nz-divider class="tm-list-preview" nzType="vertical"/>

      <div class="template-types-container">
        <div class="template-types">
          @for (type of templateTypes; track type) {
            <img
              [class.active]="type.type === selectedTemplateType.type"
              [nz-tooltip]="type.name"
              [nzTooltipMouseEnterDelay]="1"
              (click)="onChangeTemplateType(type)"
              [ngSrc]="type.src"
              width="34"
              height="40"
              [alt]="type.name">
          }
        </div>
      </div>
    }

  </div>
</ng-template>

<ng-template #TmPreviewRef>
  <div class="tm-images-list">
    @for (image of this.modalData.images; track image) {
      <img [src]="image" alt="selfcv">
    }
  </div>
</ng-template>
