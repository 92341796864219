import {NgForOf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {scrollToElement} from "@app/shared/helpers";
import {FooterComponent, HeaderComponent} from "@app/shared/ui-kits";

@Component({
  selector: 'sf-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  imports: [
    NgForOf,
    HeaderComponent,
    FooterComponent
  ],
  standalone: true
})
export class PrivacyPolicyComponent implements OnInit {
  protected selectedId = 1;
  protected contentTitles = [
    {id: 1, name: 'Interpretation and Definitions'},
    {id: 2, name: 'Personal Data'},
    {id: 3, name: 'Security and Data Privacy'},
    {id: 4, name: 'Processing of Your personal data'},
    {id: 5, name: 'Usage Data'},
    {id: 6, name: 'Links to Other Websites'},
    {id: 7, name: 'Right to Be Forgotten'},
    {id: 8, name: 'Can this Privacy Policy change?'},
  ];
  protected contentsInfo: { id: number, title: string, info: string }[] = [
    {
      id: 1,
      title: 'Interpretation and Definitions',
      info: '<div class="sf-policy-text-justify">' +
        '<p class="sf-policy"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>' +
        '<p class="sf-policy"><strong>Company</strong> (referred to as either "the Company", “Selfcv”, ' +
        '"We", "Us" or "Our" in this Agreement) refers to <strong>SELFCV LLC</strong>' +
        ', a company organised and existing under the laws of the Republic of Armenia, with its registered ' +
        'address at: Nikoghayos Tigranyan blind alley 1,  0014, Yerevan, Armenia.</p>' +
        '<p class="sf-policy"><strong>Country</strong> refers to Armenia.' +
        '</p>' +
        '<p class="sf-policy"><strong>Service</strong> refers to the Website and the services thereof.</p>' +
        '<p class="sf-policy"><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>' +
        '<p class="sf-policy"><strong>Website</strong> refers to the official website of Selfcv, accessible from https://www.selfcv.com.</p>' +
        '<p class="sf-policy"><strong>YOU</strong> means the individual accessing or using the Service, or the company, ' +
        'or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></div>'
    },
    {
      id: 2,
      title: 'Personal Data',
      info: '<div class="sf-policy-text-justify">When you sign up/register as a Selfcv user, You provide us with your basic information, as well as any other data You want to be included in your resume,' +
        ' cover letter or data that might help Selfcv to provide you assistance in your job seeking & career development. Furthermore, if you wish to use Selfcv ' +
        'premium features, we might also ask for payment details. Personally identifiable information may include, but is not limited to your first and last name, ' +
        'e-mail address, and password. We recommend that You keep your account data updated at all times.</div>'
    },
    {
      id: 3,
      title: 'Security and Data Privacy',
      info: '<div class="sf-policy-text-justify">This section of the policy applies to Selfcv users that are located in the European Economic Area (EEA), Switzerland or United Kingdom (UK).' +
        ' Selfcv processes your personal data in accordance with European laws and regulations, such as the General Data Protection Regulation (GDPR) ' +
        'and UK General Data Protection Regulation (UK GDPR).</div>'
    },
    {
      id: 4,
      title: 'Processing of Your personal data',
      info: '<div class="sf-policy-text-justify">Selfcv uses Amazon’s cloud services to store resume, cover letter or other media content. Find more about Amazon Privacy Notice (<a class="sf-link" target="_blank" href= "https://aws.amazon.com/privacy/?nc1=f_pr">https://aws.amazon.com/privacy</a>) and AWS Site Terms (<a class="sf-link" target="_blank" href= "https://aws.amazon.com/terms/?nc1=f_pr">https://aws.amazon.com/terms</a>).</div>'
    },
    {
      id: 5,
      title: 'Usage Data',
      info: '<div class="sf-policy-text-justify">Like many web/app/software applications operators, we also collect information that your browser sends whenever you visit Selfcv.' +
        ' This Log Data may include information such as your computer\'s Internet Protocol (\'IP\') address, your location (city, country), browser ' +
        'type, browser version, and the pages of Selfcv that you visit, the time and date of your visit, the time spent on those pages and other statistics ' +
        '(hereinafter Personal Data). We collect your data automatically when you browse our website. We may use cookies to improve user experience. ' +
        'This will not, under any circumstances, affect your privacy or data security. Members who disable cookies will not be able to use the service, ' +
        'as they are required to maintain your login to our website within a browser session. The personal information collected by Selfcv may be used to ' +
        'inform You about your purchase or if You want to purchase a certain product. If You have given your consent to send you email newsletters, we may ' +
        'also use this information to inform you about specific services that you may be interested in.</div>'
    },
    {
      id: 6,
      title: 'Links to Other Websites',
      info: '<div class="sf-policy-text-justify">Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, ' +
        'You will be directed to that third party\'s site. We strongly advise You to review the Privacy Policy of every site You visit. ' +
        'We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</div>'
    },
    {
      id: 7,
      title: 'Right to Be Forgotten',
      info: '<div class="sf-policy-text-justify">If you no longer want us to possess your personal information or to provide you with Selfcv services, you can erase all your personal information ' +
        'by simply deleting your Selfcv account. Nevertheless, we will retain a part of your personal information as necessary for our legitimate business interests:' +
        ' preventing frauds, resolving disputes and troublesome problems, complying with the law and assisting investigations and other actions permitted by law ' +
        '(The information will not be retained for more than 90 days). Note that after deleting your account we will not be able to provide you with our services or restore your account,' +
        ' and if you want to rejoin Selfcv you will need to complete a new registration process.</div>'
    },
    {
      id: 8,
      title: 'Can this Privacy Policy change?',
      info: '<div class="sf-policy-text-justify">We may update our Privacy Policy from time to time. We will notify you whenever we change the policy in a material way by contacting ' +
        'you through email and publishing the updated privacy policy on this same URL address.</div>'
    },
  ];

  ngOnInit() {
  }

  protected scrollToElementById(id: number): void {
    this.selectedId = id;
    const title = this.contentsInfo.find((info) => info.id === id)?.title;
    const element = document.getElementById(title || '');
    scrollToElement(element!, 120);
  }

}
