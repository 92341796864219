<div class="header-container">

  @if (bannersState) {
    <div class="top-banners-wrapper">

      @if (!appStateService.couponBannerClosed && appStateService.user?.referAndEarn?.eligible) {
        <ng-container [ngTemplateOutlet]="CouponBannerRef"></ng-container>
      }

      <!-- TODO fix this later -->
      @if (authService.isAuthenticated && !appStateService.user?.emailVerified) {
        <ng-container [ngTemplateOutlet]="EmailUnverifiedRef"></ng-container>
      }
    </div>
  }

  <header>
    <div class="left-side">

      <div class="logo-wrapper">
        <a routerLink="/">
          <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
          <span>selfcv</span>
        </a>
      </div>

      <ng-container *ngIf="hasTmTitle" [ngTemplateOutlet]="TemplateTitleEditRef"/>

      <ng-container *ngIf="hasNavbar" [ngTemplateOutlet]="NavbarRef"/>
    </div>

    <div class="right-side">
      <div class="buttons in-header">
        <a routerLink="/auth/sign-in" *ngIf="!authService.isAuthenticated">
          <button class="sf-btn-bg-none w-100">{{ "log_in" | translate }}</button>
        </a>

        @if (hasGetStaredBtn) {
          @if (appStateService.userTemplates.length) {
            <a class="sf-btn-primary small" routerLink="/dashboard">
              My Documents
            </a>
          } @else {
            <a class="sf-btn-tertiary small get-started" routerLink="/cv-templates">
              Get started
            </a>
          }
        }

        <ng-container *ngIf="hasBuilderButtons" [ngTemplateOutlet]="BuilderProgressStateRef"/>

        <ng-container *ngIf="authService.isAuthenticated" [ngTemplateOutlet]="UserIconRef"/>
      </div>

      <i class="menu-icon" nz-icon nzType="unordered-list" nzTheme="outline" (click)="openSidebarMenu()"></i>
    </div>

  </header>

</div>

<ng-template #TemplateTitleEditRef>

  <nz-divider nzType="vertical"></nz-divider>

  <div class="tm-title-wrapper" id="introjs_edit-cv-title">
    <div *ngIf="!onTitleEditMode" (click)="onTmTitleEdit()">
      <div>{{ tmTitle }}</div>
      <i class="ph ph-pencil-simple"></i>
    </div>
    <input *ngIf="onTitleEditMode" type="text" [(ngModel)]="tmTitle" #TmTitleElRef (blur)="onBlurTmTitle()">
  </div>
</ng-template>

<ng-template #BuilderProgressStateRef>
  <div class="builder-buttons">
    @if (!navigator.onLine) {
      <i
        class="ph ph-wifi-slash"
        nz-popover="You are currently offline"
        [nzPopoverContent]="NoInetDescRef"
        nzPopoverPlacement="rightBottom"
      ></i>

      <ng-template #NoInetDescRef>
        <div>
          You can continue editing.
          <br>
          Your changes will be saved automatically once the internet connection is restored.
        </div>
      </ng-template>
    } @else {
      @if (appStateService.templatesLoaderState) {
        <i
          class="ph ph-arrows-clockwise"
          nz-tooltip="Saving"
          [nzTooltipMouseEnterDelay]="1"
          nzTooltipPlacement="bottom"
        ></i>
      } @else {
        <i
          class="ph ph-check-circle"
          nz-tooltip="All Changes Saved"
          [nzTooltipMouseEnterDelay]="1"
          nzTooltipPlacement="bottom"
        ></i>
      }
    }

    <nz-divider nzType="vertical"></nz-divider>

    <div class="d-flex" id="introjs_builder-actions">
      <button
        nz-tooltip="Download As Image"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="1"
        [class.disabled]="appStateService.templateDownloadAsImageState"
        (click)="downloadAsImage()">
        @if (appStateService.templateDownloadAsImageState) {
          <i class="ph ph-arrows-clockwise"></i>
        } @else {
          <i class="ph ph-image"></i>
        }
      </button>

      <button
        nz-tooltip="Get Template URL"
        nzTooltipPlacement="bottom"
        [class.disabled]="appStateService.getTemplateUrlLoaderState"
        (click)="getTemplateUrl()">
        @if (appStateService.getTemplateUrlLoaderState) {
          <i class="ph ph-arrows-clockwise"></i>
        } @else {
          <i class="ph ph-share"></i>
        }
      </button>

      <button
        nz-tooltip="Preview"
        [nzTooltipMouseEnterDelay]="1"
        nzTooltipPlacement="bottom"
        (click)="onPreviewTemplate()">
        <i class="ph ph-eye"></i>
      </button>

      <button
        nz-tooltip="Download"
        nzTooltipPlacement="bottom"
        [nzTooltipMouseEnterDelay]="1"
        [class.disabled]="appStateService.templateDownloadState"
        (click)="onDownload()">

        @if (appStateService.templateDownloadState) {
          <i class="ph ph-arrows-clockwise"></i>
        } @else {
          <i class="ph ph-download-simple"></i>
        }
      </button>
    </div>


  </div>
</ng-template>

<ng-template #UserIconRef>
  <div class="user-icon-part">
    <div
      class="user-icon-img"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu">
      {{ appStateService.user?.firstName?.charAt(0) }}{{ appStateService.user?.lastName?.charAt(0) }}
    </div>

    <div class="user-manu-list">
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="header-user-menu">

          <!--<li nz-menu-item class="user-menu-item">
            <div class="profile-info-menu-item">
              <div class="u-full-name">
                {{appStateService.user?.fullName}}
              </div>
              <div class="u-email">
                {{appStateService.user?.email}}
              </div>
            </div>
          </li>

          <li nz-menu-divider></li>-->

          <li nz-menu-item class="user-menu-item">
            <a routerLink="/account">
              <i class="ph ph-user"></i>
              My Account
            </a>
          </li>

          <li nz-menu-item class="user-menu-item">
            <a routerLink="/dashboard">
              <i class="ph ph-file"></i>
              My Documents
            </a>
          </li>

          @if (appStateService.user?.emailVerified) {
            @if (appStateService.user?.referAndEarn?.expired && appStateService.user?.referAndEarn?.eligible) {
              <li nz-menu-item class="user-menu-item" (click)="onReferFriend()">
                <a>
                  <i class="ph ph-gift"></i>
                  Refer a friend
                </a>
              </li>
            }
          }

          <li nz-menu-divider></li>

          <li nz-menu-item class="user-menu-item">
            <a routerLink="/cv-templates">
              <i class="ph ph-read-cv-logo"></i>
              Templates
            </a>
          </li>

          <li nz-menu-item class="user-menu-item">
            <a routerLink="/cover-letters">
              <i class="ph ph-envelope"></i>
              Cover Letters
            </a>
          </li>

          <li nz-menu-divider></li>

          <li nz-menu-item class="user-menu-item" (click)="logOut()">
            <a>
              <i class="ph ph-power"></i>
              Log Out
            </a>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</ng-template>

<ng-template #NavbarRef>
  <nav>
    <ul>
      <li *ngFor="let item of appStateService.navMenuItems">
        <a *ngIf="!item.external" [routerLink]="[item.route]" routerLinkActive="active">{{ item.text }}</a>
        <a *ngIf="item.external" [href]="item.route" target="_blank">
          {{ item.text }}
          <i class="{{item.icon}} ml-4"></i>
        </a>
      </li>

      <!--<li class="mr-10">
        <div class="sf-dropdown-wrapper">
          <sf-dropdown
            [unSelectable]="false"
            [additionalClassName]="'small-paddings-10'"
            [selectedItem]="appStateService.selectedLanguage"
            [items]="appStateService.languages"
          ></sf-dropdown>
        </div>
      </li>-->

    </ul>
  </nav>
</ng-template>

<ng-template #HeaderSidebarRed>
  <div class="header-sidebar-main-container">
    <div class="sd-top-part">
      @if (appStateService.user) {
        <div class="user-info-sidebar">

          <div class="sidebar-img">
            @if (appStateService.user.image) {
              <img [src]="appStateService.user.image" alt="selfcv-user">
            } @else {
              <img src="assets/img/user-empty-icon.svg" alt="selfcv">
            }
          </div>

          <div>
            <div class="fs-16 mb-4">
              {{ appStateService.user.fullName }}
            </div>
            <div>{{ appStateService.user.email }}</div>
          </div>

        </div>
      }

      <div class="buttons mb-20" (click)="closeSidebarMenu()">

        <a routerLink="/auth/sign-in" *ngIf="!authService.isAuthenticated">
          <button class="sf-btn-bg-none">Log in</button>
        </a>

        @if (appStateService.userTemplates.length) {
          <a class="sf-btn-primary small" routerLink="/dashboard">
            My Documents
          </a>
        } @else {
          <a class="sf-btn-tertiary small get-started" routerLink="/cv-templates">
            Get started
          </a>
        }
      </div>

      <nz-divider class="m-0" *ngIf="!authService.isAuthenticated"></nz-divider>

      <nav class="pt-20">
        <ul>
          <ng-container *ngIf="authService.isAuthenticated">
            <li (click)="closeSidebarMenu()">
              <a routerLink="/account">
                <i class="ph ph-user"></i>
                My Account
              </a>
            </li>

            <li nz-menu-divider class="ant-dropdown-menu-item-divider"></li>
          </ng-container>

          <li *ngFor="let item of appStateService.navMenuItems" (click)="closeSidebarMenu()">
            <a [routerLink]="[item.route]" routerLinkActive="active">
              <i class="{{item.icon}}"></i>
              {{ item.text }}
            </a>
          </li>

          <ng-container *ngIf="authService.isAuthenticated">
            <li nz-menu-divider class="ant-dropdown-menu-item-divider"></li>

            <li (click)="logOut()">
              <a>
                <i class="ph ph-power"></i>
                Log Out
              </a>
            </li>
          </ng-container>
        </ul>
      </nav>
    </div>

    <div class="sd-bottom-part">
      <!--<div class="sf-dropdown-wrapper w-150">
        <sf-dropdown
          [unSelectable]="false"
          [hasSearch]="false"
          [additionalClassName]="'lng-dropdown'"
          [selectedItem]="appStateService.selectedLanguage"
          [items]="appStateService.languages"
          (select$)="changeLanguage($event)"
        />
      </div>-->

      <nz-divider></nz-divider>

      <sf-project-social-icons [className]="'on-sidebar'"/>

      {{ projectMessages.allRights }}
    </div>
  </div>
</ng-template>

<ng-template #SidebarTitleRef>
  <div class="sf-sidebar-title">
    <div class="logo-wrapper" (click)="closeSidebarMenu()">
      <a routerLink="/">
        <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
        <span>selfcv</span>
      </a>
    </div>

    <i nz-icon nzType="close" nzTheme="outline" (click)="closeSidebarMenu(true)"></i>
  </div>
</ng-template>

<ng-template #CouponBannerRef>
  <div class="d-flex ai-c js-sb" style="background-color: #C8EBFF">
    <div class="sf-info-banner info ph ph-info">
      <div class="mb-0">
        <p>
          <span class="mr-8">
            <strong style="letter-spacing: 0.2px">Congratulations your coupon was successfully used.</strong>
          </span>
          <span class="reset-email">
            <a class="sf-link fs-14" (click)="onCloseCouponBanner()">
              Activate one month for free.
            </a>
          </span>
        </p>
      </div>
    </div>

    <i class="ph ph-x p-4 c-pointer mr-8" (click)="onCloseCouponBanner()"></i>
  </div>
</ng-template>

<ng-template #EmailUnverifiedRef>
  <div class="sf-info-banner warning ph ph-warning">
    <div>
      <p>
        <span class="mr-8">Some features will not be available until you <strong>verify your email.</strong></span>
        <span class="reset-email"><strong *ngIf="emailSentState; else ResentEmailRef"> Email Sent </strong></span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #ResentEmailRef>
  <span class="sf-link" (click)="resentEmail()">Resend Email</span>
</ng-template>


