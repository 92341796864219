import {fixUrlStartPoint} from "@app/shared/helpers";
import {AfterViewInit, Directive, ElementRef, inject, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[sfClickableLinks]',
  standalone: true
})
export class ClickableLinksDirective implements AfterViewInit, OnDestroy {
  private readonly renderer = inject(Renderer2);
  private readonly elementRef = inject(ElementRef);
  private mutationObserver!: MutationObserver;

  ngAfterViewInit() {
    const element: HTMLElement = this.elementRef.nativeElement;
    this.bindClickEventsToAnchors(element);

    // Initialize MutationObserver to watch for changes to the DOM
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          this.bindClickEventsToAnchors(element);
        }
      });
    });

    // Configure the observer to watch for added/removed child elements
    this.mutationObserver.observe(element, {
      childList: true,
      subtree: true
    });
  }

  ngOnDestroy() {
    this.mutationObserver?.disconnect();
  }

  private bindClickEventsToAnchors(element: HTMLElement) {
    const anchorElements = element.querySelectorAll('a');

    anchorElements.forEach((anchor) => {
      this.renderer.listen(anchor, 'click', (event) => {
        event.preventDefault();

        const myPrompt = prompt('Enter the URL:', anchor.href);

        if (myPrompt !== null) {
          anchor.setAttribute('href', fixUrlStartPoint(myPrompt));
        }
      });
    });
  }

}
