export const projectMessages = {
  getTmUrl: 'Template Url Copied Into Clipboard!',
  copyClpFailed: 'Failed to copy text to clipboard!',
  emailVerified: 'Email successfully verified!',
  tokenNotExist: 'Token does not exist!',
  tooLongText: 'Too long text!',
  tooBigSize: 'Too big size!',
  allRights: `Copyright ©${new Date().getFullYear()} All rights reserved`,
  switchTm: 'Change Template layout while maintaining your data.',
  pureTmActions: `<strong>Note:</strong> This is a pure template with a minimalist design. Some advanced features are not available in this template. <br> We recommend exploring our other templates for a more feature-rich experience.`
}
