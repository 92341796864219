import * as sections from '../sections';
import {Component, inject} from '@angular/core';
import {Resource} from '@app/shared/interfaces';
import {AppStateService} from "@app/shared/services";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'sf-cv-templates-page',
  templateUrl: './cv-templates-page.component.html',
  styleUrls: ['./cv-templates-page.component.scss'],
  imports: [
    sections.FAQSectionComponent,
    sections.MoreThenSectionComponent,
    sections.WhyUseUsSectionComponent,
    sections.ResourcesSectionComponent,
    sections.TemplatesSectionComponent,
    sections.ResumeExamplesSectionComponent
  ],
  standalone: true
})
export class CvTemplatesPageComponent {
  private readonly title = inject(Title);
  private readonly meta = inject(Meta);
  public readonly appStateService = inject(AppStateService);

  public readonly resources: Readonly<Resource[]> = [
    {
      title: 'What is a resume?',
      content: `
            A resume is a formal document created by a job applicant to summarize their skills and professional
            qualifications for the position.
            <br><br>
            Most employers ask to accompany a resume with a cover letter that explains
            the applicant’s interest in the job and provides some additional details about the applicant’s background.
          `,
      image: 'assets/img/illustrations/what-is-resume.svg',
      priority: true
    },
    {
      title: 'How long should my resume be?',
      content: `
              American job coaches usually insist that a resume should be one or two pages at most. In contrast, British
              employers normally expect a more detailed document, which is often referred to as a CV (Curriculum Vitae).
              <br><br>
              The general rule is to keep it as short as possible as long as you can fit in all the key details that you
              want to communicate to your potential employee.
             `,
      image: 'assets/img/illustrations/CV-update.svg',
    },
    {
      title: 'What’s the difference between a CV and a resume?',
      content: `
             Generally, a CV is supposed to present a full history of your credentials, so the length of the document
             can vary.
             <br><br>
             A resume, in turn, provides a more concise picture of your qualifications for the given position.
             Therefore, the resume length is expected to be shorter.
          `,
      image: 'assets/img/illustrations/CV-vs-Resume.svg',
    },
    {
      title: 'What mistakes should I avoid when creating a resume?',
      content: `
            Grammatical and spelling errors are really common in resumes, and this is something that can make a bad
            first impression on the recruiters. Therefore, you should carefully proofread your resume several times.
            It’s even better if you can also ask someone else to review it for you.
          `,
      image: 'assets/img/illustrations/cover-letter-mistakes.svg',
    },
    {
      title: 'Do I need multiple versions of my resume?',
      content: `
             It’s a common practice to have a single resume and use it for all the job applications. However, some
             industries require specific resume formats, and, especially if you’re applying to jobs within multiple
             industries, you might need to prepare different versions of your resume.
             <br><br>
             Additionally, if you’re applying for jobs that require different skill set, you might want to adjust your
             resume for each application in order to highlight specific skills and experience for each position.
          `,
      image: 'assets/img/illustrations/resume-versions.svg',
    },
    {
      title: 'How should I address gaps in employment history?',
      content: `
            Most people have some sort of gaps in their employment history, and there is no need to try hiding this.
            It’s a common practice to indicate the gaps and their dates, explaining how you’ve used that time to
            improve certain skills, take courses, work on personal development, etc.
          `,
      image: 'assets/img/illustrations/gaps-in-history.svg'
    },
    {
      title: 'How can I fit all my experience into my CV?',
      content: `
               The key is to prioritize and remove all the irrelevant or unimportant information. You should aim at
               providing more  information about your current or recent roles, making older milestones more concise.
               <br><br>
               Lastly, pick what’s more important for your target employee and leave out the rest.
             `,
      image: 'assets/img/illustrations/fit-CV.svg',
    },
    {
      title: 'How often should I update my CV?',
      content: `
             Most people update their CVs when they start actively looking for a new role. However, it’s a good habit
             to update your CV on a regular basis as soon as you learn a new skill, obtain a certification, and so on.
              This way, you won’t miss any important information and will always have an up-to-date CV that you can
              share with any interested parties.
          `,
      image: 'assets/img/illustrations/cover-letter-need.svg'
    },
    {
      title: 'How long should my CV be?',
      content: `
            If you can fit in all the relevant information into a single page, it’s certainly great to have a one-page
             CV that recruiters can quickly scan through and digest. Anyways, that’s not always the case in real life,
             and people with substantial experience usually need more space.
             <br><br>
             While employers don’t normally force limitations on CV length, it’s a good practice to keep it 2-3 pages
             long in order not to overwhelm the recruiters or whoever is reviewing your application.
          `,
      image: 'assets/img/illustrations/CV-length.svg',
    },
  ];

}
