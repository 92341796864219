<div class="reset-main-container">
  <div class="auth-container forgot-password">
    <div class="left-side">
      <div class="content-part">
        <div class="logo-wrapper">
          <a routerLink="/">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <ng-container *ngIf="!passwordResetBlockState; else EmailSentRef">
          <h1>Set new password</h1>
          <p class="sub-title">Choose a new password</p>

          <div class="form-wrapper">
            <form [formGroup]="resetPasswordForm" #form="ngForm" (ngSubmit)="onSubmit()">

              <div class="sf-input-wr mb-20">
                <input
                  type="password"
                  class="sf-input"
                  autocomplete
                  placeholder="Password"
                  formControlName="password"
                  sfTrim
                  [class.error]="form.submitted && resetPasswordForm.controls['password'].errors">

                <div class="sf-err-wr" *ngIf="form.submitted && resetPasswordForm.controls['password'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    Password is required
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    Password max length is {{length["requiredLength"]}}, actual length is {{length["actualLength"]}}
                  </div>

                  <div class="sf-input-err" *ngIf="error['minlength'] as length">
                    <i class="ph ph-warning"></i>
                    Required password length is {{length["requiredLength"]}}, actual length
                    is {{length["actualLength"]}}
                  </div>
                </div>
              </div>

              <div class="sf-input-wr mb-20">
                <input
                  type="password"
                  class="sf-input"
                  autocomplete
                  placeholder="Repeat Password"
                  formControlName="rp_password"
                  sfTrim
                  [class.error]="(form.submitted && resetPasswordForm.controls['rp_password'].errors) ||
                                 (form.submitted &&
                                  form['errors'] &&
                                  form.errors['mismatch'] &&
                                  !resetPasswordForm.controls['password']['errors'] &&
                                  !resetPasswordForm.controls['rp_password']['errors'])">

                <div
                  class="sf-err-wr"
                  *ngIf="form.submitted && resetPasswordForm.controls['rp_password'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    Password is required
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    Password max length is {{length["requiredLength"]}}, actual length is {{length["actualLength"]}}
                  </div>

                  <div class="sf-input-err" *ngIf="error['minlength'] as length">
                    <i class="ph ph-warning"></i>
                    Required password length is {{length["requiredLength"]}}, actual length
                    is {{length["actualLength"]}}
                  </div>
                </div>

                <div
                  class="sf-err-wr"
                  *ngIf="form.submitted &&
                       form['errors'] &&
                       form.errors['mismatch'] &&
                       !resetPasswordForm.controls['password']['errors'] &&
                       !resetPasswordForm.controls['rp_password']['errors']">
                  <div class="sf-input-err">
                    <i class="ph ph-warning"></i>
                    Passwords don’t match
                  </div>
                </div>
              </div>

              <div class="btn-part">
                <button class="sf-btn-primary" [class.loading]="loaderState">
                  Reset Password
                </button>
              </div>

              <div class="have-not-account">
                <span> Don’t have an account? </span>
                <a class="sf-link" routerLink="/auth/sign-up"> Sign Up </a>
              </div>

            </form>
          </div>
        </ng-container>


        <div class="sf-info-banner error ph ph-x-circle mt-30" *ngIf="errorMessage">
          <div>
            <div>Error</div>
            <p>{{errorMessage}}</p></div>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #EmailSentRef>
  <div class="email-send-banner">
    <h2>Password reset</h2>
    <p>Your password has been successfully reset</p>
    <div class="have-not-account">
      <span></span>
      <a class="sf-link" routerLink="/auth/sign-in"> Log in </a>
    </div>
  </div>
</ng-template>
