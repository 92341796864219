<div class="auth-main-container">
  <div class="auth-container sign-in">

    <div class="left-side">
      <div class="content-part">
        <div class="logo-wrapper">
          <a routerLink="/">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <h1 class="mb-30">Welcome back</h1>

        <div class="form-wrapper">
          <form [formGroup]="signInForm" #form="ngForm" (ngSubmit)="onSubmit()">
            <div class="sf-input-wr mb-20">
              <input
                class="sf-input"
                type="text"
                placeholder="Email"
                formControlName="email"
                sfTrim
                [class.error]="form.submitted && signInForm.controls['email'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signInForm.controls['email'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  Email is required
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  Email max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
                </div>

                <div class="sf-input-err" *ngIf="error['email']">
                  <i class="ph ph-warning"></i>
                  Incorrect email address
                </div>
              </div>
            </div>

            <div class="sf-input-wr">
              <input
                type="password"
                class="sf-input"
                autocomplete
                placeholder="Password"
                formControlName="password"
                sfTrim
                [class.error]="form.submitted && signInForm.controls['password'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signInForm.controls['password'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  Password is required
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  Password max length is {{ length["requiredLength"] }}, actual length is {{ length["actualLength"] }}
                </div>

                <div class="sf-input-err" *ngIf="error['minlength'] as length">
                  <i class="ph ph-warning"></i>
                  Required password length is {{ length["requiredLength"] }}, actual length
                  is {{ length["actualLength"] }}
                </div>
              </div>
            </div>

            <div class="forgot-pass">
              <a class="sf-link" routerLink="/auth/forgot-password">Forgot Password?</a>
            </div>

            <div class="btn-part">
              <button class="sf-btn-primary" [class.loading]="loaderState">
                Sign In
              </button>

              <div class="m-10 fs-12">or</div>

              <div class="d-flex jc-c mb-32" id="sign-in-google"></div>
            </div>

            <div class="have-not-account">
              <span> Don’t have an account? </span>
              <a class="sf-link" routerLink="/auth/sign-up" queryParamsHandling="preserve"> Sign Up </a>
            </div>

          </form>
        </div>

        <div class="sf-info-banner error ph ph-x-circle mt-30" *ngIf="errorMessage">
          <div>
            <div>Error</div>
            <p>{{ errorMessage }}</p></div>
        </div>
      </div>
    </div>

    <div class="right-side"></div>
  </div>
</div>
