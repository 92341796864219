import {environment} from "@env";
import {filter, finalize} from "rxjs/operators";
import {HelperComponent} from "@app/shared/ui-kits";
import {TranslateService} from "@ngx-translate/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzModalModule, NzModalService} from "ng-zorro-antd/modal";
import {NgOptimizedImage, ViewportScroller} from "@angular/common";
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AnalyticsService} from "@app/shared/services/analytics.service";
import {parseJwt, parseQueryParams, removeQueryParams} from "@app/shared/helpers";
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {AppStateService, AuthService, PaymentService, TemplateService} from "@app/shared/services";
import {
  AmeriaBankQueryParams,
  AmeriaPaymentConfirmRes,
  JWT,
  MySocialUser,
  Template,
  User
} from "@app/shared/interfaces";

// declare const Paddle: any;
declare let gtag: Function;
declare const google: any;

@Component({
  selector: 'sf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    NgOptimizedImage,
    NzModalModule,
    NzDropDownModule,
    HelperComponent,
  ],
  standalone: true
})
export class AppComponent implements OnInit {
  protected readonly router = inject(Router);
  protected readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly paymentService = inject(PaymentService);
  // private readonly paymentService = inject(PaymentService);
  private readonly nzModalService = inject(NzModalService);
  private readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly viewportScroller = inject(ViewportScroller);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly nzMessageService = inject(NzMessageService);

  constructor() {
  }

  ngOnInit() {
    this.listenToInternetConnection();

    this.translateService.setDefaultLang('en');
    this.translateService.use(this.appStateService.selectedLanguage.key);

    this.getMe();

    // this.getAPIAddress();
    // this.getPaymentPrices();
    // this.getTemplatesToSelect();

    this.listenToUserLogin();
    this.listenToRouterEvents();


    this.confirmAmeriaBankPayment();
    this.getUserLocation();

    setTimeout(() => {
      this.initializeGoogleSignIn();
    }, 5000);
  }

  private getUserLocation() {
    this.paymentService.getUserLocation()
      .pipe(
        finalize(() => this.appStateService.isUserPlansReady = true),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        console.log('user location =>', res);
        this.appStateService.userLocation = res;
        this.appStateService.userLocationInit$.next(res);
        this.analyticsService.track('User location', res);
      });
  }

  private confirmAmeriaBankPayment() {
    if (this.authService.isAuthenticated) {
      const queryParams: AmeriaBankQueryParams = parseQueryParams(window.location.search);

      if (queryParams) {

        const condition = queryParams.hasOwnProperty('paymentID') &&
          ['resposneCode', 'responseCode'].some((item) => queryParams.hasOwnProperty(item));

        if (condition) {
          this.paymentService.confirmAmeriaPayment(queryParams.paymentID)
            .pipe(
              finalize(() => removeQueryParams(this.router)),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((res: AmeriaPaymentConfirmRes) => {
              this.nzMessageService.create(res.status.toLowerCase(), res.description);
              this.appStateService.user!.planIsValid = res.planIsValid;
              this.appStateService.user!.activeUntil = res.activeUntil;
              this.analyticsService.track("Payment Confirmed!!!", res);
              this.getMe();
            });
        }
      }

    }
  }

  private listenToInternetConnection() {
    window.addEventListener('online', () => {
      this.nzMessageService.success('Your internet connection was restored.');
    });

    window.addEventListener('offline', () => {
      this.nzMessageService.warning('You are currently offline.');
    });
  }

  private listenToUserLogin() {
    this.appStateService.user$
      .pipe(
        filter((user) => Boolean(user))
      )
      .subscribe(() => {
        this.getUserTemplates();
      });
  }

  private listenToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.scrollToTop();

        if (environment.env === 'production') {
          this.trackEvent(event as NavigationEnd);
        }

        this.nzModalService.closeAll();
      });
  }

  private scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
    document.body.scrollTop = 0;

    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    }, 100);

    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }

  private trackEvent(event: NavigationEnd) {
    gtag('set', 'page_path', event.urlAfterRedirects);
    gtag('event', 'page_view');
  }

  private getMe() {
    if (this.authService.isAuthenticated || this.appStateService.onPrintPage) {
      this.authService.getMe()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: User) => {
          this.appStateService.user = res;
          this.appStateService.user$.next(res);
          this.analyticsService.identifyMixpanelUser(res);
          this.getUserTemplates();

          console.log('user', res);
        });
    }
  }

  private getUserTemplates() {
    this.templateService.getTemplates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Template[]) => {
        console.log('user Templates', res);
        this.appStateService.userTemplates = res;
        this.appStateService.userTemplates$.next(res);
      });
  }

  private initializeGoogleSignIn() {
    if (!this.authService.isAuthenticated) {
      if (!['sign-in', 'sign-up'].some((route) => this.router.url.includes(route))) {
        google.accounts.id.initialize({
          client_id: environment.google_oauth_client_id,
          callback: this.googleSocialSignIn.bind(this)
        });

        google.accounts.id.prompt();
      }
    }
  }

  private googleSocialSignIn(data: { credential: string; select_by: string; }) {
    const user: MySocialUser = {
      credential: data.credential,
      provider: 'google'
    };

    this.authService.socialSignIn(user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: JWT) => {
          // Adding userId so we can track
          const jwtContent = parseJwt(res.accessToken);
          user.id = jwtContent.userId;
          this.getMe();
          this.router.navigateByUrl(this.returnUrl);
        },
        error: () => {
          this.nzMessageService.error('Social login failed. Try again later, please.');
        }
      });
  }

  /*private getTemplatesToSelect() {
    this.templateService.getTemplatesToSelect()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Template[]) => {
        // this.appStateService.templates = res;
        console.log(res);
      });
  }*/

  private get returnUrl(): string {
    return this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  /*private getAPIAddress() {
    this.paymentService.getAPIAddress()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('APIAddress', res);

        /!*interval(500)
          .pipe(
            takeWhile(() => !Paddle, true),
            filter(() => Boolean(Paddle))
          )
          .subscribe(() => {
            this.setUpJSPaddle(res);
          });*!/

      });
  }*/

  /*private getPaymentPrices() {
    this.paymentService.getPaymentPrices()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('billing prices', res);
        this.appStateService.billingPlans = res.response.products;
      });
  }*/

  /*private setUpJSPaddle(res: string) {
    Paddle.Environment.set("sandbox");

    Paddle.Initialize({
      token: "test_382d3434e3c21706bd450a58a0b",
      eventCallback: (data: any) => {
        console.log("------------data-------------", data);

        if (data.name === 'checkout.completed') {
          this.appStateService.checkoutComplete$.next();
          setTimeout(() => this.getMe(), 3000);
        }


        if (data.name === PaddleEventTypes.LOADED) {
          Paddle.Spinner.hide();
          // set here some variable showCheckoutInfo: true
          // set user email and pass to that form
        }

        if (data.name === PaddleEventTypes.CUSTOMER_CREATED) {
          // Update price based on the price coming with event (total, tax ... see consoled)
          console.log('PaddleEventTypes.CUSTOMER_CREATED', data);
          console.log(data.data);
          console.log(data.data.currency_code);
          console.log(data.data.totals);
        }

        if (data.name === PaddleEventTypes.CUSTOMER_UPDATED) {
          // when user changes the country - this will update the price accordingly
          // because we have some country with different tax and also our custom country prices

          console.log('PaddleEventTypes.CUSTOMER_UPDATED', data);
          console.log(data.data);
          console.log(data.data.currency_code);
          console.log(data.data.totals);
        }

        if (data.name === PaddleEventTypes.CHECKOUT_COUPON_REMOVE) {
          // this must be handled, related coupon
        }

        if (data.name === PaddleEventTypes.PAYMENT_COMPLETE) {
          // Checkout payment completed event
          console.log('PaddleEventTypes.PAYMENT_COMPLETE');
        }

        if (data.name === PaddleEventTypes.COMPLETE) {
          // here we can close checkout form and navigate user to dashboard
          console.log('PaddleEventTypes.COMPLETE', data.eventData); // Data specifics on the event
          this.appStateService.checkoutComplete$.next();
          this.getMe();
        } else if (data.name === PaddleEventTypes.CLOSE) {
          console.log(data.eventData); // Data specifics on the event
        } else if (data.name === PaddleEventTypes.ERROR) {
          // generic errors
          console.log(data.eventData); // Data specifics on the event
        }
      }
    });

    const request: PricePreviewBody = {
      items: pricePreviewBodyItems,
      customerIpAddress: res
    };

    Paddle.PricePreview(request)
      .then((pricePreview: PricePreview) => {
        this.appStateService.pricePreview = pricePreview;
        console.log('pricePreview', pricePreview);
      })
      .catch((error: any) => {
        /!* TODO Armen cover this *!/
        // If not supported country will be passed, there could be errors.
        // It is important to pass supported country only in the paddle payment list.
        // If there is an error, we can pass some default country - for example Germany code (DE).
        console.error('Paddle.PricePreview error', error);
      });
  }*/

}
