<div class="why-use-us-section bg-img-why-us-as-vector">
  <section>
    <div class="top-part">
      <div class="title-top-desc">selfcv</div>
      <h2><strong>Why</strong> use selfcv?</h2>
    </div>

    <div class="why-points">
      <ul>
        <li *ngFor="let item of whyUseUsItems">
          <i class="{{item.icon}}"></i>
          <span [innerHTML]="item.text"></span>
        </li>
      </ul>
    </div>

<!--    <button class="sf-btn-primary">View all services</button>-->
  </section>
</div>

