import {NgIf} from "@angular/common";
import {Subject, takeUntil} from "rxjs";
import {AppStateService} from "@app/shared/services";
import {fixUrlStartPoint} from "@app/shared/helpers";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {ChangeDetectorRef, Component, EventEmitter, inject, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sf-tm-editor',
  templateUrl: './tm-editor.component.html',
  styleUrls: ['./tm-editor.component.scss'],
  imports: [
    NzToolTipModule,
    NgIf
  ],
  standalone: true
})
export class TmEditorComponent implements OnInit {
  protected readonly appStateService = inject(AppStateService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  protected isBoldActive = false;
  protected isItalicActive = false;
  protected isUnderlineActive = false;
  protected isStrikethroughActive = false;

  @Output() public openRearrange$ = new EventEmitter<void>();
  private readonly destroy$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.appStateService.editorState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((item) => {
        this.setEditorVisible(item);
      });

  }

  private setEditorVisible(item: any) {
    setTimeout(() => {
      if (window.getSelection()?.toString()?.trim()) {
        if (item?.hasEditor) {
          this.appStateService.editorBannerState = true;
          this.setBannerSelected();

          setTimeout(() => this.changeDetectorRef.markForCheck());
        }
      } else {
        this.appStateService.editorBannerState = false;
      }
    }, 10);
  }

  private setBannerSelected() {
    const selectedText = window.getSelection()?.toString();

    if (selectedText) {
      const isBold = document.queryCommandState('bold');
      const isItalic = document.queryCommandState('italic');
      const isUnderline = document.queryCommandState('underline');
      const isStrikethroughActive = document.queryCommandState('strikethrough');

      // Update the state of the editor buttons based on the selected text formatting
      this.isBoldActive = isBold;
      this.isItalicActive = isItalic;
      this.isUnderlineActive = isUnderline;
      this.isStrikethroughActive = isStrikethroughActive;

    } else {
      // If no text is selected, reset the state of the editor buttons
      this.isBoldActive = false;
      this.isItalicActive = false;
      this.isUnderlineActive = false;
      this.isStrikethroughActive = false;
    }
  }

  protected isLinkSelected() {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const parentElement = range.commonAncestorContainer.parentElement;
      return parentElement?.tagName === 'A';
    }
    return false;
  }

  protected insertLink() {
    const selectedText = window.getSelection();

    if (!selectedText) {
      return;
    }

    if (this.isLinkSelected()) {
      const range = selectedText?.getRangeAt(0);
      const linkEl = range?.commonAncestorContainer.parentElement as HTMLLinkElement;
      const link = linkEl.href;

      const url = prompt('Enter the URL:', link);

      if (url) {
        console.log('url is not empty');
        const formattedLink = `<a title="${url}" href="${fixUrlStartPoint(url)}">${selectedText}</a>`;
        document.execCommand('insertHTML', false, formattedLink);
      }

      this.appStateService.editorBannerState = false;
      return;
    }

    const url = prompt('Enter the URL:');
    if (url) {
      const formattedLink = `<a title="${url}" href="${fixUrlStartPoint(url)}" class="editor-link-el">${selectedText}</a>`;
      document.execCommand('insertHTML', false, formattedLink);
      this.appStateService.editorBannerState = false;
    }
  }

  protected toggleFormatting(format: string) {
    document.execCommand(format, false);

    this.setBannerSelected();

    this.appStateService.saveChanges$.next();
  }

  protected clearContent() {
    const selection = window.getSelection();

    if (selection) {
      document.execCommand('inserttext', false, selection.toString());
    }
  }
}
