import * as sections from '../sections';
import {Component, inject} from '@angular/core';
import {Resource} from '@app/shared/interfaces';
import {AppStateService} from "@app/shared/services";

@Component({
  selector: 'sf-cover-letters-page',
  templateUrl: './cover-letters-page.component.html',
  styleUrls: ['./cover-letters-page.component.scss'],
  imports: [
    sections.MoreThenSectionComponent,
    sections.ResumeExamplesSectionComponent,
    sections.WhyUseUsSectionComponent,
    sections.ResourcesSectionComponent,
    sections.FAQSectionComponent,
    sections.TemplatesSectionComponent
  ],
  standalone: true
})
export class CoverLettersPageComponent {
  public readonly appStateService = inject(AppStateService);
  public readonly resources: Readonly<Resource[]> = [
    {
      title: 'What is a Cover Letter?',
      content: `
            A cover letter is used to describe your experience and skills in depth. Cover letters are usually three or
            four paragraphs long and, apart from giving an overview of your background, are also supposed to explain
            your interested in the position that is being applied for and how you meet the employer’s expectations.
          `,
      image: 'assets/img/illustrations/cover-letter.svg'
    },
    {
      title: 'What should a cover letter include?',
      content: `
             Employers usually ask to accompany your resume with a cover letter. It’s best if the cover letter is
             tailored to the given employer, as recruiters like personalized cover letters.
             <br><br>
             Additionally, you should provide an overview of your professional background, mention a few outstanding
             achievements, as well as explain why the job announcement caught your attention.
          `,
      image: 'assets/img/illustrations/cover-letter-content.svg'
    },
    {
      title: 'What should a cover letter include?',
      content: `
                 A cover letter should include detailed information about your past experience and how it is relevant
                 for the given position as well as demonstrate your motivation and excitement for undertaking the job.
                 <br><br>
                 A cover letter should start with an introduction, explaining who you are and why you’ve decided to
                 apply for the position. The main body of the text needs to explain why you are a good fit for the job.
                 Lastly, you should finalize it with a brief summary and call to action, asking the employer to reach
                 out to you.
             `,
      image: 'assets/img/illustrations/cover-letter-include.svg',
    },
    {
      title: 'How long should a cover letter be?',
      content: `
             You shouldn’t make it too long and should aim at keeping it within one page. The goal is to include the
             most important aspects only and not to waste the recruiter’s time.
          `,
      image: 'assets/img/illustrations/cover-letter-length.svg'
    },
    {
      title: 'What is the purpose of a cover letter?',
      content: `
           The main purpose of a cover letter is to provide more details on the achievements that you’ve summarized in
           your resume. It should be a well-structured document that, apart from presenting a more in-depth overview of
           your professional background, demonstrates how well you are able to articulate your thoughts and motivation.
          `,
      image: 'assets/img/illustrations/resume-versions.svg',
    },
    {
      title: 'Are cover letters becoming obsolete?',
      content: `
             It is true that many companies are shifting their focus toward candidate’s portfolio and social media
             presence. Nevertheless, a cover letter is still an important component of many job application processes.
             Therefore, you should always be ready to provide one if asked by the recruiter, and a well-written cover
             letter will certainly increase your chances of being hired.
          `,
      image: 'assets/img/illustrations/cover-letter-need.svg',
    }
  ];
}


