import {DatePipe} from "@angular/common";
import {Component, inject} from '@angular/core';
import {AppStateService} from "@app/shared/services";

@Component({
  selector: 'sf-payment-banner',
  templateUrl: './payment-banner.component.html',
  styleUrl: './payment-banner.component.scss',
  imports: [
    DatePipe
  ],
  standalone: true
})
export class PaymentBannerComponent {
  protected readonly appStateService = inject(AppStateService);
}
