import {tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {httpContextDefaults} from "@app/shared/constants";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  private cache = new Map<string, any>();

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const params = req.context.get(httpContextDefaults);

    if (params.cache) {
      if (req.method !== 'GET') {
        return next.handle(req);
      }

      const cachedResponse = this.cache.get(req.url);

      if (cachedResponse) {
        return of(cachedResponse);
      }

      return next.handle(req)
        .pipe(
          tap((event: HttpEvent<unknown>) => {
            if (event instanceof HttpResponse) {
              this.cache.set(req.url, event);
            }
          })
        );
    }

    return next.handle(req);
  }
}

