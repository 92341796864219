import introJs from "intro.js";
import {FormsModule} from "@angular/forms";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {CdkScrollable} from "@angular/cdk/overlay";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {GetSectionHeightPipe} from "@app/shared/pipes";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {NgStyle, NgTemplateOutlet} from "@angular/common";
import {SectionNames, TemplateTypes} from "@app/shared/enums";
import {Component, inject, Input, OnInit} from '@angular/core';
import {AppStateService, TemplateLogicService} from "@app/shared/services";
import {TmLayoutsComponent} from "@app/templates/componenets/tm-layouts/tm-layouts.component";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {getOneRowSectionsByType, getSidebarSectionsByType, getTwoRowsSectionsByType} from "@app/shared/helpers";
import {
  CoverLetterSettingsThemeColor,
  Template,
  TemplateConfigs,
  TemplatePage,
  TemplateSection,
  TemplateSettingsThemeColor
} from "@app/shared/interfaces";


enum AllDragSectionIDS {
  SIDEBAR = 'all-dragged-sidebar-section-list',
  ONR_ROW = 'all-dragged-section-list',
  TWO_ROWS = 'all-dragged-two-row-section-list'
}

@Component({
  selector: 'sf-rearrange-sections',
  templateUrl: './rearrange-sections.component.html',
  styleUrls: ['./rearrange-sections.component.scss'],
  standalone: true,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CdkDrag,
    CdkDropList,
    NzDividerModule,
    FormsModule,
    NzPopoverModule,
    NgStyle,
    GetSectionHeightPipe,
    TmLayoutsComponent,
    NgTemplateOutlet,
    NzTooltipDirective,
    NzIconDirective,
    CdkScrollable
  ]
})
export class RearrangeSectionsComponent implements OnInit {
  private readonly appStateService = inject(AppStateService);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: Template<TemplateConfigs>;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor | CoverLetterSettingsThemeColor;

  protected readonly TemplateTypes = TemplateTypes;
  protected readonly SectionNames = SectionNames;

  protected readonly AllDragSectionIDS = AllDragSectionIDS;

  constructor() {
  }

  ngOnInit() {
  }

  protected onDropSection(event: CdkDragDrop<TemplateSection[]>) {
    console.log('drop', event);

    if (event.container === event.previousContainer && event.currentIndex === event.previousIndex) {
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.handleDragIntoSectionsMiddle(event);
    this.handleSectionDrop(event);

    setTimeout(() => this.templateLogicService.splitToPages(this.template.settings));
    this.saveChanges();
  }

  /* Drag into sections middle start */

  private handleDragIntoSectionsMiddle(event: CdkDragDrop<TemplateSection[]>) {
    if (this.template.settings.pages.items.length > 1) {
      switch (this.template.settings.type) {
        case TemplateTypes.SIDEBAR: {
          if (event.container.id !== AllDragSectionIDS.SIDEBAR) {
            const isSidebar = event.container.id.includes('drag-sidebar-section-list-');
            const sectionsName = isSidebar ? SectionNames.SIDEBAR_SECTION : SectionNames.SIDEBAR_RIGHT_SECTION;
            this.detectDragToMiddle(event, sectionsName);
          }
          break;
        }
        case TemplateTypes.ONE_ROW: {
          if (event.container.id !== AllDragSectionIDS.ONR_ROW) {
            this.detectDragToMiddle(event, SectionNames.SECTION);
          }
          break;
        }
        case TemplateTypes.TWO_ROWS: {
          if (event.container.id !== AllDragSectionIDS.TWO_ROWS) {
            const isLefSide = event.container.id.includes('drag-two-row-left-section-list-');
            const sectionsName = isLefSide ? SectionNames.TWO_ROW_LEFT_SIDE_SECTION : SectionNames.TWO_ROW_RIGHT_SIDE_SECTION;
            this.detectDragToMiddle(event, sectionsName);
          }
          break;
        }
      }
    }
  }

  private detectDragToMiddle(event: CdkDragDrop<TemplateSection[]>, sectionName: SectionNames) {
    const draggedSection = event.item.data || event.container.data[event.currentIndex];

    if (!draggedSection) {
      return;
    }

    const items = event.container.id.split('-');
    const pageIndex = Number(items[items.length - 1]);

    const prevPage = this.template.settings.pages.items[pageIndex - 1];
    const page = this.template.settings.pages.items[pageIndex];
    const nextPage = this.template.settings.pages.items[pageIndex + 1];

    const draggedSecIndex = page[sectionName].items.findIndex((section) => section.type === draggedSection.type);

    const first = draggedSecIndex === 0;
    const second = draggedSecIndex === page[sectionName].items.length - 1;

    if (first || second) {

      if (second && nextPage) {
        const upperSection = event.container.data[event.currentIndex - 1];
        const nextPageFirstSection = nextPage[sectionName].items[0];

        if (nextPageFirstSection?.type === upperSection?.type) {
          page[sectionName].items.splice(draggedSecIndex, 1);
          nextPage[sectionName].items.splice(1, 0, draggedSection);
        }
      }

      if (first && prevPage) {
        const downerSection = event.container.data[event.currentIndex + 1];
        const prevPageLastSection = prevPage[sectionName].items[prevPage[sectionName].items.length - 1];

        if (prevPageLastSection?.type === downerSection?.type) {
          page[sectionName].items.splice(draggedSecIndex, 1);
          prevPage[sectionName].items.splice(prevPage[sectionName].items.length - 1, 0, draggedSection);
        }
      }

    }
  }

  /* Drag into sections middle end */


  /* Drop Seperated Sections Start */

  private handleSectionDrop(event: CdkDragDrop<TemplateSection[]>) {
    if (this.template.settings.pages.items.length > 1) {
      const draggedSection = event.item.data || event.container.data[event.currentIndex];
      const isWorkExpSection = this.appStateService.workExpList.includes(draggedSection?.type);

      if (isWorkExpSection) {
        switch (this.template.settings.type) {
          case TemplateTypes.ONE_ROW: {
            this.dropSeperatedSection_OneRow(event);
            break;
          }
          case TemplateTypes.SIDEBAR: {
            this.dropSeperatedSection_Sidebar(event);
            break;
          }
          case TemplateTypes.TWO_ROWS: {
            this.dropSeperatedSection_TwoRows(event);
            break;
          }
        }
      }
    }
  }

  private dropSeperatedSection_OneRow(event: CdkDragDrop<TemplateSection[]>) {
    const draggedSection = event.item.data || event.container.data[event.currentIndex];

    if (event.container.id.includes(AllDragSectionIDS.ONR_ROW)) {
      const allDraggedSections = getOneRowSectionsByType(this.template.settings, draggedSection);
      const sc = this.getSc(draggedSection);

      if (sc) {
        allDraggedSections.push(sc); // TODO later fix order
      }

      if (allDraggedSections.length > 1) {
        draggedSection.items = allDraggedSections.map((item) => item.items).flat();

        this.template.settings.pages.items.forEach((page) => {
          page.section.items = page.section.items.filter((item) => item.type !== draggedSection.type);
        });
      }

    } else {

      const allDraggedSections = getOneRowSectionsByType(this.template.settings, draggedSection);
      draggedSection.items = allDraggedSections.map((item) => item.items).flat();

      if (allDraggedSections.length > 1) {

        this.template.settings.pages.items.forEach((page) => {
          page.section.items = page.section.items.filter((item) => {
            if (draggedSection === item) {
              return true;
            } else {
              return item.type !== draggedSection.type;
            }
          });
        });

      }
    }
  }

  private dropSeperatedSection_Sidebar(event: CdkDragDrop<TemplateSection[]>) {
    const draggedSection = event.item.data || event.container.data[event.currentIndex];

    if (event.container.id.includes(AllDragSectionIDS.SIDEBAR)) {

      const allDraggedSections = getSidebarSectionsByType(this.template.settings, draggedSection);
      const sc = this.getSc(draggedSection);

      if (sc) {
        allDraggedSections.push(sc); // TODO later fix order
      }

      if (allDraggedSections.length > 1) {
        draggedSection.items = allDraggedSections.map((item) => item.items).flat();

        this.template.settings.pages.items.forEach((page) => {
          page.sidebarSection.items = page.sidebarSection.items.filter((item) => item.type !== draggedSection.type);
        });

        this.template.settings.pages.items.forEach((page) => {
          page.sidebarRightSection.items = page.sidebarRightSection.items.filter((item) => item.type !== draggedSection.type);
        });
      }

    } else {
      const allDraggedSections = getSidebarSectionsByType(this.template.settings, draggedSection);

      if (allDraggedSections.length > 1) {
        draggedSection.items = allDraggedSections.map((item) => item.items).flat();

        this.template.settings.pages.items.forEach((page) => {
          page.sidebarSection.items = page.sidebarSection.items.filter((item) => {
            if (draggedSection === item) {
              return true;
            } else {
              return item.type !== draggedSection.type;
            }
          });
        });

        this.template.settings.pages.items.forEach((page) => {
          page.sidebarRightSection.items = page.sidebarRightSection.items.filter((item) => {
            if (draggedSection === item) {
              return true;
            } else {
              return item.type !== draggedSection.type;
            }
          });
        });
      }

    }
  }

  private dropSeperatedSection_TwoRows(event: CdkDragDrop<TemplateSection[]>) {
    const draggedSection = event.item.data || event.container.data[event.currentIndex];

    if (event.container.id.includes(AllDragSectionIDS.TWO_ROWS)) {
      const allDraggedSections = getTwoRowsSectionsByType(this.template.settings, draggedSection);

      const sc = this.getSc(draggedSection);

      if (sc) {
        allDraggedSections.push(sc); // TODO later fix order
      }

      draggedSection.items = allDraggedSections.map((item) => item.items).flat();

      this.template.settings.pages.items.forEach((page) => {
        page.twoRowLeftSideSection.items = page.twoRowLeftSideSection.items.filter((item) => item.type !== draggedSection.type);
      });

      this.template.settings.pages.items.forEach((page) => {
        page.twoRowRightSideSection.items = page.twoRowRightSideSection.items.filter((item) => item.type !== draggedSection.type);
      });

    } else {
      const allDraggedSections = getTwoRowsSectionsByType(this.template.settings, draggedSection);

      if (allDraggedSections.length > 1) {
        draggedSection.items = allDraggedSections.map((item) => item.items).flat();

        this.template.settings.pages.items.forEach((page) => {
          page.twoRowLeftSideSection.items = page.twoRowLeftSideSection.items.filter((item) => {
            if (draggedSection === item) {
              return true;
            } else {
              return item.type !== draggedSection.type;
            }
          });
        });

        this.template.settings.pages.items.forEach((page) => {
          page.twoRowRightSideSection.items = page.twoRowRightSideSection.items.filter((item) => {
            if (draggedSection === item) {
              return true;
            } else {
              return item.type !== draggedSection.type;
            }
          });
        });
      }

    }
  }

  private getSc(draggedSection: any) {
    return this.template.settings.allSections.items.find((section) => section.type === draggedSection.type)
  }

  /* Drop Seperated Sections End */


  protected onDeleteSection(page: TemplatePage, section: TemplateSection, sectionIndex: number, sectionName: SectionNames) {

    if (this.template.settings.pages.items.length > 1) {
      let allDeletedSections: TemplateSection[] = [];

      switch (this.template.settings.type) {
        case TemplateTypes.SIDEBAR: {
          allDeletedSections = getSidebarSectionsByType(this.template.settings, section);
          break;
        }
        case TemplateTypes.ONE_ROW: {
          allDeletedSections = getOneRowSectionsByType(this.template.settings, section);
          break;
        }
        case TemplateTypes.TWO_ROWS: {
          allDeletedSections = getTwoRowsSectionsByType(this.template.settings, section);
          break;
        }
      }

      if (allDeletedSections.length > 1) {
        section.items = allDeletedSections.map((item) => item.items).flat();

        page[sectionName].items.splice(sectionIndex, 1);
        this.template.settings.allSections.items.push(section);

        this.template.settings.pages.items.forEach((page) => {
          page[sectionName].items = page[sectionName].items.filter((item) => item.type !== section.type);
        });
      }

      if (allDeletedSections.length === 1) {
        page[sectionName].items.splice(sectionIndex, 1);
        this.template.settings.allSections.items.push(section);
      }

    } else {
      page[sectionName].items.splice(sectionIndex, 1);
      this.template.settings.allSections.items.push(section);
    }

    setTimeout(() => this.templateLogicService.splitToPages(this.template.settings));
    this.saveChanges();
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }


  /* Section IDS into Template Start */

  protected getDragSectionsListIds() {
    return this.template.settings.pages.items.map((item, index) => `drag-section-list-${index}`);
  }

  protected getDragSidebarSectionsListIds() {
    return this.template.settings.pages.items.map((item, index) => `drag-sidebar-section-list-${index}`);
  }

  protected getDragSidebarRightSectionsListIds() {
    return this.template.settings.pages.items.map((item, index) => `drag-sidebar-right-section-list-${index}`);
  }

  protected getDragTwoRowLeftSectionsListIds() {
    return this.template.settings.pages.items.map((item, index) => `drag-two-row-left-section-list-${index}`);
  }

  protected getDragTwoRowRightSectionsListIds() {
    return this.template.settings.pages.items.map((item, index) => `drag-two-row-right-section-list-${index}`);
  }

  /* Section IDS into Template End */


  /* Section Heights Start */

  protected getHeaderHeight() {
    const containerEl = document.querySelector('.template-main-container');
    const headerEl = containerEl?.querySelector('.tm-top-wrapper');

    if (headerEl) {
      const height = Math.round((headerEl.clientHeight / 1346) * 100);
      return `${height}%`;
    }

    return null;
  }

  protected getSidebarHeaderHeight() {
    const containerEl = document.querySelector('.template-main-container');
    const headerEl = containerEl?.querySelector('.img-part-wrapper');

    if (headerEl) {
      const height = Math.round((headerEl.clientHeight / 1346) * 100);
      return `${height - 1}%`;
    }

    return null;
  }

  protected getSidebar_SocialInfo_Height() {
    const containerEl = document.querySelector('.template-main-container');
    const headerEl = containerEl?.querySelector('.social-info-sidebar');

    if (headerEl) {
      const height = Math.round((headerEl.clientHeight / 1346) * 100);
      return `${height - 1}%`;
    }

    return null;
  }

  protected getSectionHeight(pageIndex: number, section: TemplateSection) {
    const containerEl = document.querySelector(`.template-main-container .template-page-parent.page-${pageIndex + 1}`);
    const sectionEl = containerEl?.querySelector(`.section-container.${section.className}`);

    if (sectionEl) {
      const height = Math.round((sectionEl.clientHeight / 1346) * 100);
      return `${height}%`;
    }

    return null;
  }

  /* Section Heights End */

  protected onStartHowToUse() {
    const intro = introJs()
      .setOptions({
        tooltipClass: 'custom-intojs-tooltip',
        scrollToElement: false,
        steps: [
          {
            element: document.querySelector('#introjs_add-section-photo'),
            intro: 'Here you can enable / disable template subtitle, summary or photo.',
            scrollTo: 'off',
          },
          {
            element: document.querySelector('#introjs_section-tm-layouts'),
            intro: `<div class="fs-14">
                    <p class="mb-10">Each Template Has 3 layouts.</p>

                      <ul class="pl-20" style="list-style: initial">
                        <li class="mb-4">One Row Template</li>
                        <li class="mb-4">Two Rows Template</li>
                       <li>Template with Sidebar</li>
                      </ul>

                     <p class="mt-10">You can switch between them at any time.</p>
                  </div>`,
            title: 'Change template layout',
            scrollTo: 'off',
          },
          {
            element: document.querySelector('#introjs_all-sections'),
            intro: `<div class="fs-14">
                      <p class="mb-16">Here are all section types that could be in your template.</p>

                      <ul class="pl-20" style="list-style: initial">
                        <li class="mb-10">
                          To add new section into template just select and drag in appropriate page.
                         </li>
                        <li>
                          To remove sections from your template select section from appropriate page and drag to "All Sections".
                        </li>
                      </ul>
                   </div>`,
            title: 'All Sections',
          },
          {
            element: document.querySelector('#intojs_tm-page'),
            intro: `<div class="fs-14">
                      <p class="mb-16">Here you can see template page item with all sections that it has.</p>

                      <ul class="pl-20" style="list-style: initial">
                        <li class="mb-10">
                          To change a section's position, simply select it and drag it to the desired location.
                          You can move the section within the same page or transfer it to a different page.
                        </li>
                        <li>To remove sections from your template page select section and drag to "All Sections".</li>
                      </ul>
                   </div>`,
            title: 'Template Page',
          },
        ]
      });

    intro.start();

    intro.onChange(() => {
      const step = intro.getCurrentStep();

      if (step > 1) {
        intro.setOptions({
          scrollToElement: true,
        })
      }
    });
  }

}
