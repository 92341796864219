import {NgIf} from "@angular/common";
import {TemplateTypes} from "@app/shared/enums";
import {AppStateService} from "@app/shared/services";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {Component, inject, Input} from '@angular/core';
import {TemplateConfigs} from "@app/shared/interfaces";
import {NzResizableModule, NzResizeEvent} from "ng-zorro-antd/resizable";

@Component({
  selector: 'sf-tm-resize',
  templateUrl: './tm-resize.component.html',
  styleUrls: ['./tm-resize.component.scss'],
  imports: [
    NzToolTipModule,
    NzResizableModule,
    NgIf
  ],
  standalone: true
})
export class TmResizeComponent {
  private readonly appStateService = inject(AppStateService);

  private sidebarId = -1;
  private twoRowId = -1;
  protected readonly TemplateTypes = TemplateTypes

  @Input({required: true}) public template!: TemplateConfigs;

  constructor() {
  }

  protected onResizeSidebar({width}: NzResizeEvent): void {
    cancelAnimationFrame(this.sidebarId);
    this.sidebarId = requestAnimationFrame(() => this.template.settings.sidebar.with = Math.round(width!));
    this.saveChanges();
  }

  protected onResizeTwoRows({width}: NzResizeEvent) {
    cancelAnimationFrame(this.twoRowId);
    this.twoRowId = requestAnimationFrame(() => this.template.settings.twoRows.with = Math.round(width!));
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}

