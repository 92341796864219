<div class="social-icons {{className}}">
  <a href="https://www.linkedin.com/company/selfcv/" aria-label="See more in selfcv linkedin page" target="_blank">
    <i class="ph ph-linkedin-logo"></i>
  </a>

  <a
    href="https://www.facebook.com/profile.php?id=100090349483918"
    aria-label="See more in selfcv facebook page"
    target="_blank">
    <i class="ph ph-facebook-logo"></i>
  </a>

  <a href="https://www.instagram.com/selfcvbuilder/" aria-label="See more in selfcv instagram page" target="_blank">
    <i class="ph ph-instagram-logo"></i>
  </a>

  <a href="https://x.com/selfcvbuilder" aria-label="See more in selfcv x page" target="_blank">
    <i class="ph ph-x-logo"></i>
  </a>

  <a href="https://www.youtube.com/@selfcvbuilder" aria-label="See more in selfcv youtube page" target="_blank">
    <i class="ph ph-youtube-logo"></i>
  </a>
</div>

