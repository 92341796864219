<div class="template-design-sidebar-container">

  <div class="tm-fonts">
    <div class="tm-ds-title">Font Family</div>

    <div class="sf-dropdown-wrapper">
      <sf-dropdown
        [additionalClassName]="'selected-item-full-length'"
        [unSelectable]="false"
        [items]="templateFonts"
        [(selectedItem)]="template.settings.settings.font.type"
        (select$)="onFontFamilyChanged()"
      />
    </div>
  </div>

  <nz-divider></nz-divider>

  <div class="tm-font-size">
    <div class="tm-ds-title">Font Size</div>
    <div class="font-size-items">
      @for (size of fontSizes; track size.name) {
        <div
          class="{{size.className}}"
          [class.active]="size.name === template.settings.settings.font.size.name"
          (click)="onChangeFontSizes(size)">
          {{ size.name }}
        </div>
      }
    </div>
  </div>

  @if (!template.settings.pureTemplate) {
    <nz-divider></nz-divider>

    <div class="tm-color">
      <div class="tm-ds-title">Colors</div>
      <div class="tm-colors-list">
        @for (color of templateColors; track color.id) {
          <div
            class="tm-color-item theme-id-{{color.id}}"
            [class.active]="color.id === template.settings.settings.theme.colorId"
            (click)="onSelectTheme(color)">
            <div [style.background-color]="color.demoColor1"></div>
            <div [style.background-color]="color.demoColor2"></div>

            @if (color.paid) {
              <i class="ph-fill ph-star" nz-tooltip="Paid" [nzTooltipMouseEnterDelay]="1"></i>
            }
          </div>
        }

      </div>
    </div>

    <!--<nz-divider></nz-divider>

    <div class="tm-background">
      <div class="tm-ds-title">Background</div>
      <div class="tm-backgrounds-list">
        <img [ngSrc]="bg.src" width="60" height="60" alt="selfcv" *ngFor="let bg of templateBackgrounds">
      </div>
    </div>-->
  }

  @if (template.settings.pureTemplate) {
    <div class="sf-info-banner info ph ph-info mt-36">
      <div>
        <p [innerHTML]="projectMessages.pureTmActions"></p>
      </div>
    </div>
  }

</div>
