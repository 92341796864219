import {finalize} from "rxjs/operators";
import {User} from "@app/shared/interfaces";
import {ActivatedRoute, Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {projectMessages} from "@app/shared/constants/project-messages";
import {AppStateService, AuthService, UserService} from "@app/shared/services";

@Component({
  selector: 'sf-confirm-email',
  standalone: true,
  imports: [],
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss'
})
export class ConfirmEmailComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly appStateService = inject(AppStateService);
  private readonly nzMessageService = inject(NzMessageService);

  constructor() {
  }

  ngOnInit() {
    this.confirmEmail();
  }

  private confirmEmail() {
    const token = this.activatedRoute.snapshot.queryParams['token'];

    if (!token) {
      this.nzMessageService.error(projectMessages.tokenNotExist);
      this.router.navigate(['/']);
    } else {
      this.userService.confirmEmail(token)
        .pipe(
          finalize(() => this.router.navigate(['/'])),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe({
          next: () => {
            this.nzMessageService.success(projectMessages.emailVerified);
            this.appStateService.user!.emailVerified = true;

            this.getMe();
          }
        });
    }
  }

  private getMe() {
    this.authService.getMe()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: User) => {
        this.appStateService.user = res;
        this.appStateService.user$.next(res);
      });
  }

}
