import {finalize} from "rxjs/operators";
import {RouterLink} from "@angular/router";
import {NzTableModule} from "ng-zorro-antd/table";
import {copyToClipboard} from "@app/shared/helpers";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {ALL_COUNTRY_PRICES} from "@app/shared/constants";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzModalModule, NzModalService} from "ng-zorro-antd/modal";
import {AppStateService, PaymentService} from "@app/shared/services";
import {NzMessageModule, NzMessageService} from "ng-zorro-antd/message";
import {DaysRemainingPipe, GetPlanPipe, SafePipe} from "@app/shared/pipes";
import {AmeriaPaymentCancel, AmeriaTransaction} from "@app/shared/interfaces";
import {DatePipe, DecimalPipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';

interface TableColumn<T = any> {
  label: string;
  keyName: string;
  renderKey?: (data: { column: TableColumn, data: T }) => void;
  hasCopyClipBoard?: boolean;
  hasCopyClipBoardClicked?: (data: { column: TableColumn, data: T }) => void;
}

@Component({
  selector: 'sf-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.scss'],
  imports: [
    NgForOf,
    NzTableModule,
    NzDividerModule,
    NzModalModule,
    NzMessageModule,
    NgIf,
    RouterLink,
    SafePipe,
    NzToolTipModule,
    NgOptimizedImage,
    DecimalPipe,
    DatePipe,
    DaysRemainingPipe,
    GetPlanPipe
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingPageComponent implements OnInit {
  private readonly datePipe = inject(DatePipe);
  private readonly destroyRef = inject(DestroyRef);
  private readonly nzModalService = inject(NzModalService);
  private readonly paymentService = inject(PaymentService);
  private readonly nzMessageService = inject(NzMessageService);
  protected readonly appStateService = inject(AppStateService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  // @ViewChild('UpdatePaymentRef') private readonly updatePaymentRef: TemplateRef<any> | null = null;

  // protected readonly billingPlans: Readonly<Billing[]> = this.appStateService.billingPlans;
  protected tableLoadingState = false;
  protected transactions: AmeriaTransaction[] = [];

  protected tableJson: TableColumn<AmeriaTransaction>[] = [
    {
      label: 'Payment ID',
      keyName: 'paymentId',
      hasCopyClipBoard: true,
      renderKey: (res) => {
        return `<div><span title="${res.data.paymentId}">${res.data.paymentId?.slice(0, 8)}...</span></div>`
      },
      hasCopyClipBoardClicked: (res) => {
        copyToClipboard(res.data.paymentId);
      }
    },
    {
      label: 'Payment Type',
      keyName: 'plan',
      renderKey: (res) => {
        const prices = ALL_COUNTRY_PRICES
          .find((item) => item.currency === res.data.currency);

        const plan = prices?.prices.find((item) => item.id === res.data.plan);

        return plan?.description || '';
      }
    },
    {
      label: 'Date',
      keyName: 'paymentTime',
      renderKey: (res) => this.datePipe.transform(res.data.paymentTime, 'dd MMM yyyy hh:mm')
    },
    {
      label: 'Amount',
      keyName: 'balanceGross',
      renderKey: (res) => `${res.data.amount}  ${res.data.currency}`
    },
    {
      label: 'Card Number',
      keyName: 'cardNumber',
    },
    {
      label: 'Status',
      keyName: 'status',
      renderKey: (res) => {

        let status = `<i class="ph ph-check-circle sf-success" title="Success"></i>`;

        switch (res.data.status) {
          case 'Success': {
            status = `<i class="ph ph-check-circle sf-success" title="Success"></i>`;
            break;
          }
          case 'Warning': {
            status = `<i class="ph ph-warning sf-warning" title="Warning"></i>`;
            break;
          }
          case 'Error': {
            status = `<i class="ph ph-warning sf-error" title="Error"></i>`;
            break;
          }
          case 'Info': {
            status = `<i class="ph ph-info sf-info" title="Info"></i>`;
            break;
          }
        }

        return `<div class="d-flex ai-c">${status}  <span class="ml-6">${res.data.description}</span></div>`;
      }
    },
  ];

  ngOnInit() {
    // this.getBillingTransactions();
    this.getAmeriaTransactions();
  }

  private getAmeriaTransactions() {
    this.tableLoadingState = true;
    this.paymentService.getAmeriaTransactions()
      .pipe(
        finalize(() => this.tableLoadingState = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res: AmeriaTransaction[]) => {
        console.log(res);
        console.log('transactions', res);
        this.transactions = res;
        this.changeDetectorRef.markForCheck();
      });
  }

  protected onCancelPayment(data: AmeriaTransaction) {
    this.nzModalService.confirm({
      nzTitle: 'Are you sure cancel this payment?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzIconType: '',
      nzFooter: null,
      nzOnOk: () => {
        this.ameriaPaymentCancel(data);
      },
      nzMaskClosable: true,
      nzCancelText: 'Cancel'
    });
  }

  private ameriaPaymentCancel(data: AmeriaTransaction) {
    this.paymentService.ameriaPaymentCancel(data.paymentId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: AmeriaPaymentCancel) => {
        console.log('ameriaPaymentCancel', res);
        this.appStateService.user!.planIsValid = res.planIsValid;
        this.appStateService.user!.activeUntil = res.activeUntil;

        this.nzMessageService.create(res.status.toLowerCase(), res.description);

        this.getAmeriaTransactions();
      });
  }

  private getBillingTransactions() {
    // this.appStateService.userSubscription$
    //   .pipe(
    //     filter((res) => !!res),
    //     filter((res) => !!res!.plan_id),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((res) => {


    /*this.tableLoadingState = true;
    this.paymentService.getBillingTransactions()
      .pipe(
        finalize(() => this.tableLoadingState = false),
        takeUntil(this.destroy$)
      )
      .subscribe((res: BillingTransaction[]) => {
        console.log('transactions', res);
        this.transactions = res;
        this.changeDetectorRef.markForCheck();
      });*/


    // });
  }

  /*protected cancelSubscription(): void {
    const modalRef = this.nzModalService.create({
      nzFooter: null,
      nzContent: CancelSubscriptionComponent
    })

    modalRef.componentInstance?.close$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        modalRef.close();
      });

    modalRef.componentInstance?.save$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reason: DeleteUserReason) => {
        modalRef.componentInstance!.loaderState = true;
        this.paymentService.cancelSubscription(this.appStateService.userSubscription!.subscription_id)
          .pipe(
            finalize(() => modalRef.componentInstance!.loaderState = false),
            takeUntil(this.destroy$)
          )
          .subscribe((res) => {
            this.appStateService.userSubscription = res;
            modalRef.close();
            this.nzMessageService.success('You successfully canceled your subscription.');
          });
      });
  }

  protected onUpdatePaymentDetails() {
    const drawerRef = this.nzDrawerService.create({
      nzClosable: true,
      nzTitle: 'Update Payment Details',
      nzContent: this.updatePaymentRef!,
    });

    drawerRef.afterOpen
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        drawerRef.getContentComponent()?.close$
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            drawerRef.close();
          });
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        drawerRef.close();
      });
  }

  protected onUpgradePlan(billing: Billing) {
    const drawerRef = this.nzDrawerService.create({
      nzClosable: false,
      nzWidth: 760,
      nzWrapClassName: 'payment-drawer',
      nzBodyStyle: {
        padding: 0
      },
      nzContent: PaymentComponent,
      nzContentParams: {
        billing
      }
    });

    drawerRef.afterOpen
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        drawerRef.getContentComponent()?.close$
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            drawerRef.close();
          });
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        drawerRef.close();
      });
  }

  protected onChangePlan(plan: Billing) {
    const modalRef = this.nzModalService.create({
      nzBodyStyle: {padding: '0'},
      nzStyle: {width: 'fit-content'},
      nzClosable: false,
      nzFooter: null,
      nzContent: ConfirmModalComponent,
    });

    const component = modalRef.getContentComponent();

    component.confirmModalData = {
      title: 'Change Plan',
      body: 'You are about to change your subscription plan. Are you sure?'
    };

    component.rightBtn.name = 'Confirm';
    component.leftBtn.name = 'Close';

    race(component.close$, component.leftBtn$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => modalRef.close());

    component.rightBtn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        component.rightBtnLoaderState = true;
        this.paymentService.changePlan(plan, this.appStateService.userSubscription!)
          .pipe(
            finalize(() => component.rightBtnLoaderState = false),
            takeUntil(this.destroy$)
          )
          .subscribe((res) => {
            this.nzMessageService.success('your subscription plan has been successfully changed.');
            modalRef.close();
            this.getBillingTransactions();
            this.getSubscriptions();
          });
      });
  }

  protected reSubscribe() {

    const modalRef = this.nzModalService.create({
      nzBodyStyle: {padding: '0'},
      nzStyle: {width: 'fit-content'},
      nzClosable: false,
      nzFooter: null,
      nzContent: ConfirmModalComponent,
    });

    const component = modalRef.getContentComponent();

    component.confirmModalData = {
      title: 'Resubscribe',
      body: 'You are about to resubscribe your plan. Are you sure?'
    };

    component.rightBtn.name = 'Confirm';
    component.leftBtn.name = 'Close';

    race(component.close$, component.leftBtn$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => modalRef.close());

    component.rightBtn$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        component.rightBtnLoaderState = true;
        this.paymentService.reSubscribe(this.appStateService.userSubscription!)
          .pipe(
            finalize(() => component.rightBtnLoaderState = false),
            takeUntil(this.destroy$)
          )
          .subscribe((res) => {
            modalRef.close();
            this.appStateService.userSubscription = res;
            this.appStateService.userSubscription$.next(res);
            this.nzMessageService.success('You successfully subscribed back');
          });
      });
  }*/

  /*private getSubscriptions() {
    this.paymentService.getSubscriptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.appStateService.userSubscription = res;
        this.appStateService.userSubscription$.next(res);
      });
  }*/

}
