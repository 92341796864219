@if (template.branding.enabled) {
  @if (forPrint) {
    <a
      [href]="template.branding.link"
      class="branding"
      [ngStyle]="{
        '--brandingColor': $any(template).type === TemplateTypes.SIDEBAR ?
                           $any(template).sidebarPosition === 'right' ? tmTheme.branding.sidebarColor : tmTheme.branding.color
                           : tmTheme.branding.color,

        '--brandingHoverColor': $any(template).type === TemplateTypes.SIDEBAR ?
                                $any(template).sidebarPosition === 'right' ? tmTheme.branding.sidebarHoverColor : tmTheme.branding.hoverColor
                                : tmTheme.branding.hoverColor,
      }">
      <span>Created by</span>
      <div>selfcv</div>
    </a>
  } @else {
    <ng-container [ngTemplateOutlet]="BrandingRef"/>
  }
}

<ng-template #BrandingRef>
  <div
    class="branding"
    [ngStyle]="{
    '--brandingColor': $any(template).type === TemplateTypes.SIDEBAR ?
                       $any(template).sidebarPosition === 'right' ?  tmTheme.branding.sidebarColor : tmTheme.branding.color
                       : tmTheme.branding.color,

    '--brandingHoverColor': $any(template).type === TemplateTypes.SIDEBAR ?
                            $any(template).sidebarPosition === 'right' ? tmTheme.branding.sidebarHoverColor : tmTheme.branding.hoverColor
                            : tmTheme.branding.hoverColor,
  }">

    <div class="remove-branding" (click)="onRemoveBranding()">
      <i class="ph ph-trash-simple"></i>
      Remove
    </div>

    <span>Created by</span>
    <div>
      <!-- <img src="assets/img/logo-gray.png" alt="selfcv">-->
      selfcv
    </div>
  </div>
</ng-template>
