import {TmFontSizesTypes, TmSocialInfos} from "@app/shared/enums";
import {CoverLetter} from "@app/shared/interfaces";

export const coverLetter2: CoverLetter = {
  templateId: 2,
  templateType: 'cover-letter',
  forTemplate: 2,
  templateTopPartType: 2,
  version: "1.0.0",
  title: {
    text: "",
    pl: "Name Surname",
    state: true
  },
  subTitle: {
    text: "",
    pl: "Your Profession",
    state: true
  },
  img: {
    src: '',
    state: false,
    settings: {
      selectedShape: {
        name: 'img',
        value: 'initial',
        premium: false
      }
    }
  },
  socialInfo: {
    title: {
      icon: {
        state: false,
        name: ''
      },
      text: 'CONTACTS',
      pl: 'CONTACTS',
      state: true
    },
    className: 'social-info-sidebar',
    onSidebar: false,
    items: [
      {
        type: TmSocialInfos.EMAIL,
        icon: 'ph ph-envelope',
        text: '',
        pl: 'john.doe@gmail.com',
        state: true,
      },
      {
        type: TmSocialInfos.PHONE,
        icon: 'ph ph-phone',
        text: '',
        pl: '202-555-0166',
        state: true
      },
      {
        type: TmSocialInfos.CITY,
        icon: 'ph ph-globe-hemisphere-east',
        text: '',
        pl: 'New York, USA',
        state: true
      },
      {
        type: TmSocialInfos.ADDRESS,
        icon: 'ph ph-map-pin',
        text: '',
        pl: 'Broadway 10012'
      },
      {
        type: TmSocialInfos.LINKEDIN,
        icon: 'ph ph-linkedin-logo',
        text: '',
        pl: 'John.linkedin.com',
        state: true,
        link: true,
        settings: {
          shorten: false,
          shortenName: 'linkedin.com'
        }
      },
      {
        type: TmSocialInfos.BIRTHDAY,
        icon: 'ph ph-calendar-blank',
        text: '',
        pl: '01/02/2020'
      },
      {
        type: TmSocialInfos.LINK,
        icon: 'ph ph-link',
        text: '',
        pl: 'John.blog.com',
        link: true,
        settings: {
          shorten: false,
          shortenName: 'Custom'
        }
      },
      {
        type: TmSocialInfos.FACEBOOK,
        icon: 'ph ph-facebook-logo',
        text: '',
        pl: 'John.facebook.com',
        link: true,
        settings: {
          shorten: false,
          shortenName: 'facebook.com'
        }
      },
      {
        type: TmSocialInfos.STACKOVERFLOW,
        icon: 'ph ph-stack-overflow-logo',
        text: '',
        pl: 'John.Stackoverflow.com',
        link: true,
        settings: {
          shorten: false,
          shortenName: 'stackoverflow.com'
        }
      },
      {
        type: TmSocialInfos.SKYPE,
        icon: 'ri-skype-line',
        text: '',
        pl: 'John.skype.com',
      },
      {
        type: TmSocialInfos.INSTAGRAM,
        icon: 'ph ph-instagram-logo',
        text: '',
        pl: 'John.instagram.com',
        link: true,
        settings: {
          shorten: false,
          shortenName: 'instagram.com'
        }
      },
      // {
      //   icon: 'quora',
      //   text: '',
      //   pl: 'John.quora.com',
      //   settings: {
      //     shorten: false,
      //     shortenName: 'quora.com'
      //   }
      // },
      {
        type: TmSocialInfos.GITHUB,
        icon: 'ph ph-github-logo',
        text: '',
        pl: 'John.github.com',
        link: true,
        settings: {
          shorten: false,
          shortenName: 'github.com'
        }
      },
      {
        type: TmSocialInfos.MEDIUM,
        icon: 'ph ph-medium-logo',
        text: '',
        pl: 'John.medium.com',
        link: true,
        settings: {
          shorten: false,
          shortenName: 'medium.com'
        }
      },
    ]
  },
  companyDetails: {
    state: true,
    employer: {
      text: "",
      pl: "Employer's Name",
      state: true
    },
    name: {
      text: "",
      pl: "Company Name",
      state: true
    },
    address: {
      text: "",
      pl: "Company Address",
      state: true
    },
    zip: {
      text: "",
      pl: "City, State, ZIP Code",
      state: true
    },
    date: {
      text: new Date().toString(),
      pl: '',
      state: true
    },
  },
  content: {
    recipient: {
      text: "",
      pl: "Dear Mr. Smith,",
      state: true
    },
    sender: {
      text: 'Sincerely,',
      pl: 'Sincerely,',
      state: true
    },
    content: {
      hasEditor: true,
      text: "",
      pl: "I am writing to express my keen interest in the position of Marketing Executive at Charlton Marketing Firm. With my strong background in marketing strategy, brand management, and digital marketing, I am confident in my ability to contribute to the continued growth and success of your organization.",
      state: true
    }
  },
  branding: {
    link: 'https://selfcv.com/',
    enabled: true,
    paid: true
  },
  settings: {
    font: {
      type: {
        name: "Poppins",
        fontFamily: "Poppins",
        paid: false
      },
      size: {
        name: TmFontSizesTypes.MEDIUM
      }
    },
    theme: {
      colorId: 1
    }
  }
};

