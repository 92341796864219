<div class="{{mainClassName}} {{additionalClassName}}" [class.disabled]="disabled">
  <div class="sw-dropdown-label" *ngIf="label">{{label}}</div>

  <div
    class="dropdown-container"
    [sfElPositionDirective]="null"
    #myAction="sfElPositionDirective"
    [distance]="300"
    [class.active]="dropdownState"
    (sfClickOutside)="onClickOutside()">

    <div class="selected-item" (click)="onToggleDropdownState(); myAction.calculate()">

    <span
      class="inner-text s-ellipsis {{selectedItem['classname'] || ''}}"
      [style.font-family]="selectedItem['fontFamily'] ?? 'inherit'"
      *ngIf="selectedItem && selectedItem[keyName]; else placeholderRef"
      [innerHTML]="selectedItem[keyName]"
    ></span>

      <i class="chevron-down ph ph-caret-down"></i>
    </div>

    <div class="dropdown-items-list" *ngIf="dropdownState">

      <div class="sw-search-container" *ngIf="hasSearch && items.length > 7">
        <input
          #SearchRef
          class="sw-input-search"
          type="text"
          [placeholder]="searchPlaceHolder"
          [(ngModel)]="searchQuery">
      </div>

      <div class="items-container">

        <div class="all-dropdown-items">
          <div *ngIf="unSelectable" class="dropdown-item-el unset" (click)="onUnset()">{{placeHolder}}</div>
          <ng-container *ngFor="let item of items | sfFilter : searchQuery : keyName">
            <div
              class="dropdown-item-el {{item['classname'] || ''}}"
              [style.font-family]="item['fontFamily'] ?? 'inherit'"
              *ngIf="!item[hideByKeyName]"
              [title]="showTitle ? (item[keyName].length > 35 ? item[keyName] : '') : ''"
              [class.active]="item === selectedItem"
              [innerHTML]="item[keyName]"
              (click)="onSelectItem(item)"
            ></div>
          </ng-container>
        </div>

      </div>

    </div>

  </div>
</div>


<ng-template #placeholderRef>
  <span class="inner-text s-ellipsis sw-placeholder">{{placeHolder}}</span>
</ng-template>

