<sf-header/>

<div class="about-us-container">
  <h1>About Us</h1>

  <div class="about-us-content">
    <p class="mb-36">
      Welcome to <a href="https://selfcv.com/">selfcv.com</a>, where we understand the challenges job seekers face in
      today’s competitive market. Our mission is to empower you with the tools and guidance needed to stand out from the
      crowd and secure your dream job.
    </p>

    <div class="mb-36">
      <h2 class="mb-10">Who We Are</h2>
      <div>
        <h4 class="d-flex ai-c">
          <a routerLink="/" class="d-flex ai-c mr-10">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv">
            <span class="fs-19 ml-6">selfcv</span>
          </a>
          resumes get people hired at top companies
        </h4>
      </div>
    </div>

    <div class="mb-36">
      <h4>Our Mission</h4>
      <div>
        At <a href="https://selfcv.com/">selfcv.com</a>, we envision a world where every job seeker has the confidence
        and capability to present their skills and achievements in the best possible light. We strive to eliminate the
        barriers to employment by providing a seamless, user-friendly resume-building experience that caters to all
        industries and career stages.
      </div>
    </div>


    <div class="mb-36">
      <h4>Our Values</h4>
      <div>
        <ul>
          <li>
            <strong>Empowerment:</strong> We provide the tools and guidance to help you take control of your career
            journey.
          </li>
          <li>
            <strong>Innovation:</strong> We use advanced technology to enhance the resume-building process.
          </li>
          <li>
            <strong>Excellence:</strong> We strive for the highest standards in everything we do, from our templates to
            our
            customer support.
          </li>
          <li>
            <strong>Personalization:</strong> We recognize that every candidate is unique, and we offer tailored
            solutions
            to meet your specific needs.
          </li>
        </ul>
      </div>
    </div>

    <div class="mb-36">
      <h4 class="what-we-offer">What We Offer</h4>
      <div>
        <ul>
          <li>
            <strong>Customizable Templates:</strong> Choose from a wide range of professionally designed templates that
            cater to various industries and job roles. Whether you're a creative professional, a tech expert, or a
            seasoned executive, we have the perfect template to highlight your expertise.
          </li>

          <li>
            <strong> Easy-to-Use Builder:</strong> Our intuitive resume builder allows you to effortlessly create, edit,
            and customize your resume. With drag-and-drop functionality, real-time previews, and guided tips, you can
            ensure that your resume is polished and professional.
          </li>

          <li>
            <strong>ATS-Friendly Resumes:</strong> In today’s job market, applicant tracking systems (ATS) play a
            crucial role in the hiring process. Our templates are designed to be ATS-friendly, ensuring that your resume
            passes through these systems and reaches the hands of hiring managers.
          </li>

          <li>
            <strong>Cover Letters & More:</strong> Complement your resume with a compelling cover letter using our range
            of templates.
          </li>

          <li>
            <strong>Data Security:</strong> Your privacy is our priority. We use top-notch security measures to protect
            your personal information and ensure that your data remains confidential.
          </li>
        </ul>
      </div>
    </div>

  </div>

</div>

<sf-footer/>
