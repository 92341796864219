import {Component} from '@angular/core';
import {FooterComponent, HeaderComponent} from "@app/shared/ui-kits";
import {NgOptimizedImage} from "@angular/common";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'sf-about-us',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    NgOptimizedImage,
    RouterLink,
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {

}
