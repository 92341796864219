<footer>
  <div class="footer-container">
    <div class="top-part">
      <div class="left-side">
        <div class="logo-wrapper">
          <a routerLink="/">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <p class="mt-12">More than a resume builder</p>
      </div>

      <div class="right-side">
        <div class="list-items-container">
          <!--<div class="list-item">
            <div>Learn</div>
            <ul>
              <li><a routerLink="/">How to write a Resume</a></li>
              <li><a routerLink="/">How to write a Cover Letter</a></li>
            </ul>
          </div>-->

          <div class="list-item">
            <div>Get Started</div>
            <ul>
              <li><a routerLink="/cv-templates">Resume Examples</a></li>
              <li><a routerLink="/cover-letters">Cover Letter Examples</a></li>
              <li><a routerLink="/pricing">Pricing</a></li>
            </ul>
          </div>

          <div class="list-item">
            <div>Contact</div>
            <ul>
              <li><a routerLink="/about-us" routerLinkActive="active">About Us</a></li>
              <li><a routerLink="/contact-us" routerLinkActive="active">Contact Us</a></li>
              <li><a routerLink="/terms-of-use" routerLinkActive="active">Terms of Service</a></li>
              <li><a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></li>
              <li><a routerLink="/refund-policy" routerLinkActive="active">Refund Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="languages-part">
      <div class="sf-dropdown-wrapper w-150">
        <sf-dropdown
          [unSelectable]="false"
          [hasSearch]="false"
          [additionalClassName]="'lng-dropdown'"
          [selectedItem]="appStateService.selectedLanguage"
          [items]="appStateService.languages"
          (select$)="changeLanguage($event)"
        />
      </div>
    </div>-->

    <div class="bottom-part">
      <div>{{ projectMessages.allRights }}</div>

      <sf-project-social-icons/>
    </div>

  </div>
</footer>
